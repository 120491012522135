/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import {API_SERVER_URL, API_SERVER_URL2} from './constants';

const default_token = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjU3MzQ4OTAiLCJuYW1lIjoia2Fta2FheiIsImlhdCI6MTUxNjIzOTAyMn0.ePuB-Mbw5FravMWVjR_LzgrtNTvMAPAjUREULAAn03o"
console.log(window.location.href)

export default {
	superAdmin: {
		changePassword: async (data) => {
			let res = await axios.post(API_SERVER_URL2 + "/changePasswordSuperAdmin", data, {
				headers: {
					"Content-Type": "application/json",
				}
			})
			console.log("API call ===> Super Admin PasswordUpdate API Called", res)
			return res.data
		}
	},
	user: {
		changePassword: async (auth_token, data) => {
			let res = await axios.put(API_SERVER_URL + "/api/v1/master/employerChangePassword", data, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": auth_token
				}
			})
			console.log("API call ===> User Change Password API Called", res)
			return res.data
		},
		sendOTP: async (data) => {
			let res = await axios.post(API_SERVER_URL + "/api/v1/login/sendOTP", data, {
			})
			console.log("API call ===> OTP send", res)
			return res.data
		},
		verifyOTP: async (data) => {
			let res = await axios.post(API_SERVER_URL + "/api/v1/login/verify_otp", data, {
			})
			console.log("API call ===> OTP verified", res)
			return res.data
		},
		forgotPassword: async (data) => {
			let res = await axios.post(API_SERVER_URL + "/api/v1/login/forgot_password", data, {
			})
			console.log("API call ===> Password updated", res)
			return res.data
		},
	},
	masters: {

		getSearchedRecords: async (auth_token,path,body) => {
			let res = await axios.post(API_SERVER_URL + path,body,{
				headers: {
					"Content-Type": "application/json",
					"Authorization": auth_token
				}
			})
			console.log("API call ===> get all records", res)
			return res.data
		},
		getPublicSearchedRecords: async (path,body) => {
			let res = await axios.post(API_SERVER_URL + path,body,{
				headers: {
					"Content-Type": "application/json",
					"Authorization": default_token
				}
			})
			console.log("API call ===> get all records", res)
			return res.data
		},

		getAllRecords: async (auth_token, path) => {
			let res = await axios.get(API_SERVER_URL + path, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": auth_token
				}
			})
			console.log("API call ===> get all records", res)
			return res.data
		},
		// Add this API for display Job List in "Applied job list page"
		getJobListDirectly: async () => {
			let res = await axios.get(API_SERVER_URL2 + "/getJobList", {
				headers: {
					"Content-Type": "application/json",
				}
			})
			console.log("API call ===> getJobList Mob", res)
			return res
		},


		getPublicAllRecords: async (path) => {
			let res = await axios.get(API_SERVER_URL + path, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": default_token
				}
			})
			console.log("API call ===> get all records", res)
			return res.data
		},
		getSingleRecord: async (auth_token, path, id) => {
			let res = await axios.get(API_SERVER_URL + path + "/" + id, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": auth_token
				}
			})
			console.log("API call ===> get single Record", res)
			return res.data
		},

		getAllEmployer: async (auth_token, path) => {
			console.log("AUTH_TOKEN :", auth_token)
			let res = await axios.get(API_SERVER_URL + path, {
				headers: {
					"Content-Type": "application/json",
					"authorization": auth_token
				}
			})
			console.log("API call ===> get Page-wise Employer", res)
			return res.data
		},

		getSingleEmployer: async (auth_token, id) => {
			console.log("AUTH_TOKEN :", auth_token)
			let res = await axios.get(API_SERVER_URL + `/api/V1/master/employer/${id}`, {
				headers: {
					"Content-Type": "application/json",
					"authorization": auth_token
				}
			})
			console.log("API call ===> get single Employer", res)
			return res.data
		},

		createRecordRequest: async (auth_token, path, data) => {
			console.log(auth_token)
			console.log(API_SERVER_URL + path)
			let res = await axios.post(API_SERVER_URL + path, data, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": auth_token
				}
			})
			console.log("API Call ====> Create Record", res)
			return res.data
		},
		updateRecordRequest: async (auth_token, path, id, data) => {
			console.log(API_SERVER_URL + path + "/" + id)
			console.log(data)
			let res = await axios.put(API_SERVER_URL + path + '/' + id, data, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": auth_token
				}
			})
			console.log("API Call ====> Update Record", res)
			return res.data
		},
		updateStatus: async (auth_token, path, id, status, data) => {
			console.log(API_SERVER_URL + path + "/" + id)
			console.log(data)
			let res = await axios.put(API_SERVER_URL + path + '/' + id + '/' + status, data, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": auth_token
				}
			})
			console.log("API Call ====> Update Status", res)
			return res.data
		},





		deleteRecordRequest: async (auth_token, path, id) => {
			console.log(API_SERVER_URL + path + "/" + id)
			let res = await axios.delete(API_SERVER_URL + path + "/" + id, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": auth_token
				}
			})
			console.log("API Call ====> Delete Record", res)
			return res.data
		},
		// Get assistance number
		getAssistanceNumber: async (path) => {
			let res = await axios.get(API_SERVER_URL2 + path, {
				headers: {
					"Content-Type": "application/json",
					
				}
			})
			// console.log("API call ===> get assistance number", res)
			return res.data
		},
		// update assistance number
		updateAssistanceNumber: async (auth_token, path, data) => {
			// console.log(API_SERVER_URL + path + "/" )
			console.log(data)
			let res = await axios.put(API_SERVER_URL + path + '/' , data, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": auth_token
				}
			})
			// console.log("API Call ====> Update Record", res)
			return res
		},

	},

	authentication: {
		loginRequest: async (data) => {
			let res = await axios.post(API_SERVER_URL + "/api/V1/login", data, {
				headers: {
					"Content-Type": "application/json",
				}
			})
			console.log("API Call ===> Login request", res)
			return res.data
		}
	},

	location: {
		getEmployerLocation: async (auth_token) => {
			let res = await axios.get(API_SERVER_URL + "/api/v1/master/postJob/locations", {
				headers: {
					"Content-Type": "application/json",
					"Authorization": auth_token
				}
			})

			console.log("API Call ===> Get locations", res)
			return res.data
		}
	},

	candidates: {
		getAllCandidates: async () => {
			console.log("API_SERVER_URL=======>", API_SERVER_URL);
			let res = await axios.get(`${API_SERVER_URL2}/getCandidateList`, {
				headers: {
					"Content-Type": "application/json"
				}
			})
			console.log("API Call ===> Get All Candidates", res)
			return res.data
		},

	
		getCandidatesWithProcessStatus: async (auth_token, job_id, process_status) => {
			let res = await axios.get(API_SERVER_URL + "/api/v1/master/candidate_by_job_status/" + job_id + "/" + process_status, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": auth_token
				}
			})
			return res.data
		},

		selectOrRejectCandidateApplication: async (auth_token, body) => {
			let res = await axios.put(API_SERVER_URL + "/api/v1/master/selectOrRejectCandidateApplication", body, {
				headers: {
					"Content-Type": "application/json",
					"Authorization": auth_token
				}
			})
			return res.data
		},
		getStatusCandidates: async (jobid, status) => {
			console.log("jobid", jobid, "status", status)
			let res = await axios.get(API_SERVER_URL2 + `"/getJobStatus/${jobid}/${status}"`, {
				headers: {
					"Content-Type": "application/json",
				}
			})
			return res.data
		},

		addCandidates: async (body) => {
			let res = await axios.post(API_SERVER_URL2 + "/user_registration", body, {
				headers: {
					"Content-Type": "application/json"
				}
			})
			console.log("Create CANDIDATE CALLED", res)
			return res.data
		},
		updateCandidate: async (body) => {
			let res = await axios.post(API_SERVER_URL2 + "/updateCandidate", body, {
				headers: {
					"Content-Type": "application/json"
				}
			})
			console.log("UPDATE CANDIDATE CALLED")
			return res.data
		},

		deleteCandidate: async (id) => {
			let res = await axios.get(API_SERVER_URL2 + "/deleteCandidate/" + id, {
				headers: {
					"Content-Type": "application/json"
				}
			})
			console.log("delete CANDIDATE CALLED")
			return res.data
		},
		documentUpload: async (path, body) => {
			let res = await axios.post(API_SERVER_URL2 + path, body, {
				headers: {
					"Content-Type": "multipart/form-data"
				}
			})
			console.log("upload CV Called")
			return res.data
		}
	},

	counts: {
		count: async (path) => {
			let res = await axios.get(API_SERVER_URL2 + path, {
				headers: {
					'Content-Type': 'application/json'
				}
			})
			console.log("count api called")
			return res.data
		},

		JobCount: async (auth_token, path) => {
			let res = await axios.get(API_SERVER_URL + path, {
				headers: {
					'Content-Type': 'application/json',
					'Authorization': auth_token
				}
			})
			console.log("JOB COUNT API CALLED ====>", res)
			return res.data
		}
	}
}