import React, { useEffect, useState } from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import { useHistory } from "react-router";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";
import './kk-sidebar-styles.scss'
import MainLogo from '../../assets/img/Logo.png'

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Media,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Progress,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";
import { logout } from "redux/login/login.action";
import { connect } from "react-redux";

var ps;

const Sidebar = (props) => {
  const history = useHistory()
  const {logout,roleId,user_name} = props
  const [collapseStatus, setCollapseStatus] = useState(false)
  const toggleCollapseStatus = () => {
    setCollapseStatus(!collapseStatus)
  }
  const [collapseOpen, setCollapseOpen] = useState();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  useEffect(()=>{
    console.log(collapseStatus)
  },[collapseStatus])
  // creates the links that appear in the left menu / Sidebar
  const createLinks = (routes) => {

    return routes.map((prop, key) => {
   
      return (
        <NavItem  key={key}>
          <span style={{
            display:"flex",
            position:"relative",
            alignItems:"center",
            // backgroundColor:"white"
            // background: "linear-gradient(to right, #e0eafc, #cfdef3)" /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

          }}>

          <NavLink
          className={prop.name!=="Master"?"navitems":""}
            style={{
              position: 'relative',
             
            }}
            to={
              prop.name!=="Master"?
              prop.layout + prop.path:"#"
            }
            tag={NavLinkRRD}
            onClick={prop.name!=="Master"?closeCollapse:toggleCollapseStatus}
            // activeClassName="active"
          >
            <i className={prop.icon} />
            {prop.name}
          </NavLink>
          {
              prop.childrens && <span
                onClick={toggleCollapseStatus}
                style={{
                  position: 'absolute',
                  right: 20,
                  color: "rgb(179, 136, 255)",
                  cursor:"pointer"
                }}
              >
                {
                  collapseStatus?
                  <i className="fas fa-chevron-down"></i>
                  :
                  <i className="fas fa-chevron-right"></i>
                }
              </span>
            }
          </span>
          {
            prop.childrens && prop.childrens.map(child => {
              return (
                <Collapse isOpen={collapseStatus}>
                  <NavItem className='' style={{
                      // backgroundImage: "linear-gradient(to right, white,#d1c4e9)"
                      
                  }}>
                    <NavLink
                    className="navitems"
                      style={{
                        position: 'relative',
                      }}
                      to={child.layout + child.path}
                      tag={NavLinkRRD}
                      onClick={closeCollapse}
                      activeClassName="active"
                    >
                      <i className={child.icon} />
                      {child.name}
                     
                    </NavLink>
                  </NavItem>
                </Collapse>
              )
            })

          }

        </NavItem>
      );
    });
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light"
      expand="md"
      id="sidenav-main"
    >

      <Container fluid>
        {/* Toggler */}
        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>
        {/* Brand */}
        {/* {logo ? (
          <NavbarBrand style={{height:"100px"}} className="pt-0 bg-red" {...navbarBrandProps}>
           
          </NavbarBrand>
        ) : null} */}
        {/* User */}
        <div className="d-flex justify-content-center" style={{height:"fit-content"}}>
        <img src={MainLogo}  style={{width:"100px", height:"110px",}}/>
        </div>
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            {/* <DropdownToggle nav className="nav-link-icon">
              <i className="ni ni-bell-55" />
            </DropdownToggle> */}
            <DropdownMenu
              aria-labelledby="navbar-default_dropdown_1"
              className="dropdown-menu-arrow"
              right
            >
              <DropdownItem>Action</DropdownItem>
              <DropdownItem>Another action</DropdownItem>
              <DropdownItem divider />
              <DropdownItem>Something else here</DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle"  style={{background: "linear-gradient(to right, #5B86E5, #36D1DC)"}}>
                  {/* <img
                    alt="..."
                    src={
                      require("../../assets/img/KTSPLLogo.png")
                        .default
                    }
                  /> */}
                    <i className="fas fa-user"></i>
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-single-02" />
                <span>My profile</span>
              </DropdownItem> */}
              {/* <DropdownItem to="/admin/user-profile" tag={Link}>
                <i className="ni ni-settings-gear-65" />
                <span>Settings</span>
              </DropdownItem> */}
              <DropdownItem divider />
              <DropdownItem  onClick={logout}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
              <DropdownItem  onClick={()=>{
                if(roleId==1){
                  history.push('/admin/changepassword')
                }else{
                  logout()
                  setTimeout(()=>{
                    window.location.href=`http://35.224.211.184:4000/change_password.html?email=${user_name}`
                  },500)
                  
                }
              }}>
              <i className="fas fa-key"></i>
                <span>Change Password</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>

        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt="Logo" src={MainLogo} />
                    </Link>
                  ) : (
                      <a href={logo.outterLink}>
                        <img alt="Logo" src={MainLogo} />
                      </a>
                    )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          {/* <Form className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search"
                type="search"
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fa fa-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form> */}
          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
          {/* Divider */}
          {/* <hr className="my-3" /> */}
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

const mapDispatchToProps = dispatch =>{
  return{
    logout: ()=>{dispatch(logout())}
  }
}

const mapStateToProps = state =>{
  return {
    roleId : state.login.data.role_id,
    user_name: state.login.data.user_name
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Sidebar);
