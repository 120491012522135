import PaginationActionType from './pagination.action.type'


export const incrementPageNo = (item) =>{
    return{
        type: PaginationActionType.INCREMENT_PAGE_NO,
        payload:item
    }
}

export const decrementPageNo = (item) =>{
    return{
        type: PaginationActionType.DECREMENT_PAGE_NO,
        payload:item
    }
}