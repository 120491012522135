import axios from "axios";
import React from "react";
import { useEffect, useState } from "react";
import moment from "moment";
import { useHistory } from "react-router";
import SearchBox from "components/SearchBox/SearchBox";
// import { ConvertToExcel } from "../../../components/excel/CovertToExcel";
import { ConvertToExcel } from "components/excel/CovertToExcel";
import Pagination2 from "components/pagination/Pagination2";
import EmptyTablePlaceHolder from "components/EmptyTableReplacement.js/EmptyTablePlaceHolder";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Button,
  UncontrolledTooltip,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";
import { API_SERVER_URL, API_SERVER_URL2 } from "../../../constants";
import { useSelector } from "react-redux";

const IncompleteCandidateList = () => {
  const recordsPerPage = 10;
  const [data, setData] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [exportAllProfile, setExportAllProfile] = useState([]);
  const [searchedText, setSearchedText] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageOffset, setPageOffset] = useState({
    start: 0,
    end: recordsPerPage - 1,
  });
  const [totalPages, setTotalPages] = useState(0);

  const onChangeNext = () => {
    setPageOffset({
      start: pageOffset.start + recordsPerPage,
      end: pageOffset.end + recordsPerPage,
    });
    console.log("Length", totalPages);

    setPageNumber(pageNumber + 1);
  };

  const onChangePrev = () => {
    setPageOffset({
      start: pageOffset.start - recordsPerPage,
      end: pageOffset.end - recordsPerPage,
    });

    setPageNumber(pageNumber - 1);
  };

  const auth_token = useSelector((state) => state.login.data.auth_token);

  //   const [tableData,setTableData]=useEffect()
  const history = useHistory();
  const getAllRecords = async () => {
    setLoader(true);
    try {
      let res = await axios(
        API_SERVER_URL + "/api/v1/master/incompleteCandidateList",
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: auth_token,
          },
        }
      )
        .then((res) => res)
        .catch((error) => error);
      console.log(res);
      if (res.status === 200) {
        console.log("Response Incomplete", res.data.data);
        setLoader(false);
        let remainder = res.data.data.length % recordsPerPage;
        let limit = parseInt(res.data.data.length / recordsPerPage);
        if (remainder !== 0) {
          limit = limit + 1;
        }
        setTotalPages(limit);
        setData(res.data.data);
        setRawData(res.data.data);
        console.log("tableData => ", res.data.data);
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  };
  useEffect(() => {
    // setLoader(true);
    // axios(API_SERVER_URL + "/api/v1/master/incompleteCandidateList", {
    //   headers: {
    //     "Content-Type": "application/json",
    //     Authorization: default_token,
    //   },
    // })
    //   .then((res) => {
    //     setLoader(false);
    //     setData(res.data.data);
    //   })
    //   .catch((error) => {
    //     setLoader(false);
    //     console.error(error);
    //   });
    getAllRecords();
  }, []);
  useEffect(() => {
    console.log("TOTAL PAGES", totalPages);
    console.log("page number", pageNumber);
    console.log("Page Offset", pageOffset);
  }, []);

  const filterRecord = (e) => {
    console.log("Filtered Data - ", e.target.value);
    let find = e.target.value;
    console.log(e.target.value.length);
    if (e.target.value.length > 0) {
      const filter = data.filter((element) => {
        return element.mobile_no.toString().includes(find);
      });
      console.log("Filter - ",filter);
      setPageNumber(1);
      setPageOffset({
        start: 0,
        end: recordsPerPage - 1,
      });
      let remainder = filter.length % recordsPerPage;
      let limit = parseInt(filter.length / recordsPerPage);
      if (remainder !== 0) {
        limit = limit + 1;
      }
      setTotalPages(limit);
      setData(filter);
    } else {
      setPageNumber(1);
      setPageOffset({
        start: 0,
        end: recordsPerPage - 1,
      });
      let remainder = rawData.length % recordsPerPage;
      let limit = parseInt(rawData.length / recordsPerPage);
      if (remainder !== 0) {
        limit = limit + 1;
      }
      setTotalPages(limit);
      setData(rawData);
      console.log(rawData);
    }
  };

  // To Export all profile data
  const exportAllData = () => {
    let resData = [];
    data &&
      data.map((item) => (
        <>
          {resData.push({
            "Candidate ID": item.id,
            "Mobile Number": item.mobile_no,
            "Created Date": moment(item.created_date).format("MMMM Do YYYY"),
          })}
        </>
      ));
    console.log("Export Data - ", resData);
    setExportAllProfile(resData);
  };
  useEffect(() => {
    exportAllData();
  }, [data]);

  return (
    <>
      <div className="pr-2 pl-2 container-fluid">
        <Row className="d-flex justify-content-end mt-7 mr-0">
          <Col xs="12 mt-3" lg="4" className="d-flex justify-content-end mb-2">
            <Button
              className="ripple"
              style={{
                backgroundColor: "transparent",
                color: "white",
                marginRight: "0px",
              }}
              onClick={() => {
                history.goBack();
              }}
            >
              Candidate List
            </Button>
          </Col>
        </Row>
        <Row className=" w-100 p-0 m-0">
          <Col className=" p-0 m-0">
            <input
              type="search"
              placeholder="Search"
              style={{ borderRadius: "10px", padding: "8px", border: "none" }}
              onChange={(e) => filterRecord(e)}
            />
          </Col>
          <Col className=" p-0 m-0 d-flex justify-content-end align-items-center">
            <ConvertToExcel
              style={{}}
              dataToCovert={exportAllProfile}
              filename="incompleteCandidateList.csv"
              btnName="Download Profile List"
            />
          </Col>
        </Row>

        {loader ? (
          <Spinner
            style={{
              width: "3rem",
              height: "3rem",
              position: "absolute",
              left: "50%",
              color: "whitesmoke",
            }}
          />
        ) : data && data.length > 0 ? (
          <>
            <Table
              className="align-items-center table-condensed table-striped  w3-animate-bottom"
              style={{
                width: "50%",
                marginTop: "30px",
                padding: "100px",
                marginLeft: "auto",
                marginRight: "auto",
                backgroundColor: "white",
              }}
              responsive
            >
              <thead className="thead-dark">
                <th className="text-white" scope="col">
                  ID
                </th>
                <th className="text-white" scope="col">
                  MOBILE NUMBER
                </th>
                <th className="text-white" scope="col">
                  CREATED DATE
                </th>
              </thead>
              <tbody>
                {data
                  .filter(
                    (i, idx) => idx >= pageOffset.start && idx <= pageOffset.end
                  )
                  .map((item, index) => (
                    <tr key={index}>
                      <td>{item.id}</td>
                      <td>{item.mobile_no}</td>
                      <td>{moment(item.created_date).format("DD-MM-YYYY")}</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Row className="pagination mt-2 mb-5 w3-animate-bottom d-flex justify-content-center">
              <Pagination2
                style={{ marginLeft: "auto", marginRight: "auto" }}
                pageNumber={pageNumber}
                pageOffset={pageOffset}
                length={totalPages}
                onChangeNext={onChangeNext}
                onChangePrev={onChangePrev}
                recordsPerPage={recordsPerPage}
              />
            </Row>
          </>
        ) : (
          <EmptyTablePlaceHolder>No Candidate Found</EmptyTablePlaceHolder>
        )}
      </div>
    </>
  );
};

export default IncompleteCandidateList;
