/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { connect, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

// reactstrap components


import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  FormGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  Navbar,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { logout } from "redux/login/login.action";
import SweetAlert from "react-bootstrap-sweetalert";


const AdminNavbar = (props) => {
  const [alert, setAlert] = React.useState(null);


  const {logout,roleId,user_name} = props
  let history= useHistory()
  const user = useSelector(state=>state.login.data.company_name)
  
  const warningAlert=()=>{
      console.log("alert gherer")
       setAlert(
        <SweetAlert
          warning
          style={{ display: "block", marginTop: "100px" }}
          title={"Do you really want to log out?"}
          confirmBtnText="Yes"
          onConfirm={() => {
            logout(); hideAlert()
            history.push("/auth?status=logoff");
          }}
          onCancel={()=>hideAlert()}
          cancelBtnText="No"
          confirmBtnBsStyle="success"
          showCancel
          cancelBtnBsStyle="danger"
          btnSize=""
        />
       )
     }
  
  const hideAlert = () => {
    setAlert(null);
  };


  return (
    <>
    {alert}
      <Navbar  className="navbar-top navbar-dark" style={{
        height: "64px",
        position: "absolute",
      }} expand="md" id="navbar-main">
        <Container fluid>
          {/* <font
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
            to="/"
          >
            {props.brandText}
          </font> */}
         
          <Nav className="align-items-center d-none d-md-flex ml-auto" navbar>
          <div className="kk-dropdown ml-auto">
            <div className="kk-dropbtn pointer">
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle" style={{background: "linear-gradient(to right, #5B86E5, #36D1DC)",border:"2px solid white"}}>
                  {/* <img
                    alt="..."
                    src={
                      require("../../assets/img/KTSPLLogo.png")
                        .default
                    }
                  /> */}
                  <i className="fas fa-user"></i>
                </span>
                <Media className="ml-2 d-none d-lg-block">
                  <span className="mb-0 text-md font-weight-bold">

                    {
                      roleId===1?"Super Admin":user
                    }
                    </span>
                </Media>
              </Media>
            </div>
            <div className="kk-dropdown-content">
              {/* <a href="#"
              onClick={()=>{
                history.push('/admin/profile')
              }}
              ><i class="fas fa-user-alt mr-3"></i>My profile</a>
              <a href="#"><i class="fas fa-cog mr-3"></i>Settings</a> */}
              <a style={{cursor:"pointer", color:"#000"}} 
              onClick={()=>{
                // history.push('/admin/changepassword')
                if(roleId==1 || roleId==2){
                  history.push('/admin/changepassword')
                }else{
                  logout()
                  setTimeout(()=>{
                    window.location.href=`http://35.224.211.184:4000/change_password.html?email=${user_name}`
                  },500)
                  
                }
              }}
              ><i className="fas fa-sign-out-alt mr-3"></i>Change Password</a>
            <a style={{cursor:"pointer", color:"#000"}} 
              // onClick={logout}
              onClick={() =>warningAlert()}
              type="submit"
              ><i className="fas fa-sign-out-alt mr-3"></i>Log Out</a>
              
                           {/* <button
                              onClick={() =>warningAlert()
                                // onClickDelete(props.row.original.id)
                              }
                              color="danger"
                              size="sm"
                              title="delete EM Client Details"
                              className="btn-icon btn-link remove"
                            >
                              <i
                                className="fa fa-times"
                                style={{ fontSize: "20px" }}
                              />
                            </button> */}
            </div>
          </div>
          </Nav>
          {/* <Nav className="align-items-center d-none d-md-flex ml-auto" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={
                        require("../../assets/img/KTSPLLogo.png")
                          .default
                      }
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      Super Admin
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>My profile</span>
                </DropdownItem>
                <DropdownItem to="/admin/user-profile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
                <DropdownItem divider />
                <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav> */}
        </Container>
      </Navbar>
    </>
  );
};

const mapDispatchToProps = dispatch =>{
  return{
    logout: ()=>{dispatch(logout())}
  }
}

const mapStateToProps = state =>{
  return {
    roleId : state.login.data.role_id,
    user_name: state.login.data.user_name

  }

}
 

export default connect(mapStateToProps,mapDispatchToProps)(AdminNavbar);
