/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import KK_Toast from "components/Toast/KK_Toast";
import React, { useEffect, useState } from "react";
import AddEmployerForm from "views/AddEmployerForm/AddEmployerForm";
import { scrollToTop } from "../../../components/_helpers/utils";
import {
  Button,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
} from "reactstrap";
import Form from "react-bootstrap/Form";
import apis from "apis";
import { useHistory, useParams } from "react-router";
import { validation } from "components/_helpers/validation";
import { connect } from "react-redux";
import { Multiselect } from "multiselect-react-dropdown";
import { logout } from "redux/login/login.action";
import { parse } from "commander";

const JobPost = ({
  setToastConfig,
  workFlow,
  jobData,
  setTab,
  setWorkFlow,
  auth_token,
  logout,
}) => {
  const [jobCategoryList, setCategoryList] = useState([]);
  const [jobExperienceList, setJobExperienceList] = useState([]);
  const [jobSkillList, setJobSkillList] = useState([]);
  const [jobTypesList, setJobTypesList] = useState([]);
  const [errorMsgs, setErrorMsgs] = useState(null);
  const [enteredValue, setEnteredValue] = useState(null);
  const [prevData, setPrevData] = useState();
  const [locationList, setLocationList] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [industry_id, setIndustryId] = useState(null);
  const [skillList, setSkillList] = useState([]);
  const [skillDisabled, setSkillDisable] = useState(true);

  let history = useHistory();
  const [formData, setFormData] = useState({
    jobtitle: null,
    industry_type: null,
    jobdescription: null,
    jobtype: null,
    jobexperience: null,
    skills: null,
    location: null,
    gender: null,
  });

  useEffect(() => {
    if (formData.industry_type) {
      let industryId = jobCategoryList.filter(
        (i) => parseInt(i.id) === parseInt(formData.industry_type)
      );

      setIndustryId(industryId[0]);
    }
    console.log("formData", formData)
  }, [formData]);

  
  const [errStyle, setErrStyle] = useState({});
  
  const getSkillList = async (industry_id) => {
    // This Function returns a list of Skills based on Industry type
    try {
      let res = await apis.masters.getPublicAllRecords(
        `/api/v1/master/jobSkillByIndustryId/${industry_id}`
        );
        
        return res.data;
      } catch (err) {
        console.error(err);
      }
    };

    useEffect(()=>{
      setErrStyle({...errStyle, skillsstyle: selectedSkills ? "" : "jobskills_error"})
    },[selectedSkills])
    
  useEffect(async () => {
    console.log("IndustryId", industry_id);
    if (industry_id) {
      try {
        let skills = await getSkillList(industry_id.id);
        console.log("skills",skills);
        setJobSkillList(skills);
        setSkillDisable(false);
      } catch (err) {
        console.log(err);
      }
    }
  }, [industry_id]);

  //component did mount
  useEffect(async () => {
    console.log(workFlow);
    console.log("AUTH_TOKEN", auth_token);
    try {
      let categoryRes = await apis.masters.getAllRecords(
        auth_token,
        "/api/v1/master/jobCategory"
      );
      let jobTypeRes = await apis.masters.getAllRecords(
        auth_token,
        "/api/v1/master/jobTypes"
      );
      let jobExpRes = await apis.masters.getAllRecords(
        auth_token,
        "/api/v1/master/jobExperience"
      );
      let jobSkillRes = await apis.masters.getAllRecords(
        auth_token,
        "/api/v1/master/jobSkill"
      );
      let locationListRes = await apis.location.getEmployerLocation(
        auth_token,
        "/api/v1/master/postJob/locations"
      );

      if (workFlow === "EDIT") {
        setPrevData(jobData);
        let arraySkills = jobData.job_skills.split(",");
        console.log("JOB SKILLS Array", arraySkills);
        let newArray = [];
        console.log("current DATA", jobSkillRes.data);
        if (jobSkillRes.data.length > 0) {
          for (let i = 0; i < arraySkills.length; i++) {
            let d = jobSkillRes.data.filter(
              (item) => item.name === arraySkills[i]
            );
            console.log(d);
            newArray.push(d[0]);
          }
        }
        setSelectedSkills(newArray);
        console.log("NEW Array==>", newArray);

      }
      console.log("categoryList", categoryRes.data);
      setCategoryList(categoryRes.data);
      setJobTypesList(jobTypeRes.data);
      setJobExperienceList(jobExpRes.data);
      setJobSkillList(jobSkillRes.data);
      setLocationList(locationListRes.data);
      console.log("Location", locationListRes.data);
    } catch (err) {
      console.error(err);
      if (err.response && err.response.status === 401) {
        setToastConfig({
          toastStatus: true,
          message: "Session Expired!",
          severity: "error",
        });
        setTimeout(() => {
          logout();
        }, 2000);
      }
    }
  }, []);

  useEffect(() => {
    console.log("PREVIOUS DATA",prevData)
    if (workFlow === "EDIT" && prevData) {
      const {
        job_title,
        job_type,
        industry_type,
        job_description,
        job_skills,
        location_id,
        gender_preference,
        experience_needed,
      } = prevData;
      console.log("JOB SKILLS", job_skills);

      setFormData({
        
        jobtitle: job_title,
        jobtype: job_type,
        jobdescription: job_description,
        skills: job_skills,
        gender: gender_preference,
        industry_type: industry_type,
        jobexperience: experience_needed,
        location: location_id,
      });
    }
    console.log("PrevData");
  }, [prevData]);

  useEffect(() => {
    console.log("FormData", formData);
  }, [formData]);
  const handleSubmit = () => {
    console.log("My FormDATA", formData);

    let resjobtitle = validation.checkForEmpty(formData.jobtitle, "job title");
    let resindustry_type = validation.checkForEmpty(formData.industry_type, "Job category");
    let resskills = validation.checkForEmpty(formData.skills, "skills");
    let resgender = validation.checkForEmpty(formData.gender, "gender");
    let resjobdescription = validation.checkForEmpty(
      formData.jobdescription,
      "Job description"
    );
    let resjobtype = validation.checkForEmpty(formData.jobtype, "Job type");
    let resjobexperience = validation.checkForEmpty(
      formData.jobexperience,
      "experience"
    );
    let reslocation = validation.checkForEmpty(formData.location, "location");
    console.log("Location TYPE",formData.location)
    console.log("location TYPE",reslocation)
    setErrorMsgs({
      ...errorMsgs,
      jobdescription: { ...resjobdescription },
      jobtype: { ...resjobtype },
      jobtitle: { ...resjobtitle },
      skills: { ...resskills },
      gender: { ...resgender },
      industry_type: { ...resindustry_type },
      location: { ...reslocation },
      jobexperience: { ...resjobexperience },
    });

    setErrStyle({
      ...errStyle,
      jobtitlestyle: resjobtitle.validation === false ? "error" : "success",
      // industry_typestyle: resindustry_type.validation === false ? 'error' : 'success',
      jobtypestyle: resjobtype.validation === false ? "error" : "success",
      jobdescriptionstyle:resjobdescription.validation === false ? "error" : "success",
      skillsstyle: resskills.validation === false ? "jobskills_error" : "jobskills_success",
      genderstyle: resgender.validation === false ? "error" : "success",
      industry_typestyle: resindustry_type.validation === false ? "error" : "success",
      location: reslocation.validation === false ? "error" : "success",
      jobexperiencestyle: resjobexperience.validation === false ? "error" : "success",
    });

    const {
      address2,
      jobtitle,
      jobtype,
      jobdescription,
      skills,
      gender,
      industry_type,
      location,
      jobexperience,
    } = formData;

    // If skills is not added for job category then this block will execute
      if(!skills){
      setToastConfig({
        toastStatus: true,
        message: "Skill is required",
        severity: "error",
      });
    }
    console.log("formData",formData);

    if (
      !!(
        jobtitle &&
        jobtype &&
        jobdescription &&
        skills &&
        gender &&
        industry_type &&
        location &&
        jobexperience
      )
    ) {
      let body = {
        job_title: jobtitle.trim(),
        industry_type: industry_type,
        job_type: jobtype,
        job_description: jobdescription,
        gender_preference: gender,
        experience_needed: jobexperience,
        job_skills: skills,
        location_id: location,
      };
    console.log("industry_type",industry_type);
      if (address2) body.address_line2 = address2.trim();
      console.log("body", body);
      if (workFlow === "EDIT") {
        updateJob(body);
      } else {
        createJob(body);
      }
      clearHandler();
    } else {
      console.log("kindly fill the fields");
    }
    // setTab("LIST");
    // setWorkFlow("ADD");
  };

  useEffect(()=>{
    console.log("ERROR TRACKING",errorMsgs,errStyle)
    
  },[errorMsgs,errStyle])

  const updateJob = async (body) => {
    try {
      let res = await apis.masters.updateRecordRequest(
        auth_token,
        `/api/v1/master/postJob`,
        jobData.id,
        body
      );
      if (res.success) {
        scrollToTop();
        setWorkFlow("ADD");
        setTab("LIST");
        return setToastConfig({
          toastStatus: true,
          message: res.message,
          severity: "success",
        });
      } else {
        scrollToTop();
        return setToastConfig({
          toastStatus: true,
          message: res.message,
          severity: "error",
        });
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setToastConfig({
          toastStatus: true,
          message: "Session Expired!",
          severity: "error",
        });
        setTimeout(() => {
          logout();
        }, 2000);
      }
      scrollToTop();
      setToastConfig({
        toastStatus: true,
        message: err.message,
        severity: "error",
      });
      return console.error(err);
    }
  };

  const createJob = async (body) => {
    try {
      let res = await apis.masters.createRecordRequest(
        auth_token,
        "/api/v1/master/postJob",
        body
      );
      if (res.success) {
        scrollToTop();
        setToastConfig({
          toastStatus: true,
          message: res.message,
          severity: "success",
        });

        setWorkFlow("ADD");
        return setTab("LIST");
      } else {
        scrollToTop();
        return setToastConfig({
          toastStatus: true,
          message: res.message,
          severity: "error",
        });
      }
    } catch (err) {
      scrollToTop();
      if (err.response && err.response.status === 401) {
        setToastConfig({
          toastStatus: true,
          message: "Session Expired!",
          severity: "error",
        });
        setTimeout(() => {
          logout();
        }, 2000);
      }
      setToastConfig({
        toastStatus: true,
        message: err.message,
        severity: "error",
      });
      return console.error(err);
    }
  };

  const clearHandler = () => {
    setErrStyle({});
    setErrorMsgs({});
    setFormData({
      jobtitle: null,
      industry_type: null,
      jobdescription: null,
      jobtype: null,
      jobexperience: null,
      skills: null,
      location: null,
      gender: null,

    });
    document.getElementById("myform").reset();
  };
  

  const onSelect = (e) => {
    setErrorMsgs({
      ...errorMsgs,
      skills: null,
    });

    setErrStyle({
      ...errStyle,
      skillsstyle: null
    });
    console.log("E FROM MULTI SELECT", e);
    setSelectedSkills(e);

    
    let ls = e.map((i) => i.name).toString();

    console.log("ls======>",selectedSkills);
    setFormData({
      ...formData,
      skills: ls,
    });
  };

  const onRemove = (e) => {
    setSelectedSkills(e);
    setErrorMsgs({
      ...errorMsgs,
      skills: null,
    });
    console.log("E FROM MULTI SELECT", e);
    let ls = e.map((i) => i.name).toString();

    console.log("ls==>",ls);
    setFormData({
      ...formData,
      skills: ls,
    });
  };

  const onChangeHandler = (e) => {
    switch (e.target.name) {
      case "jobtitle": {
        setErrStyle({
          ...errStyle,
          [e.target.name + "style"]: null,
        });
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: null,
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
        // const res = validation.onlyLettersCheck(e.target.value, e.target.name)
        // console.log("Response", res)
        // if (res.validation === true) {
        // 	setErrStyle({
        // 		...errStyle,
        // 		[e.target.name + "style"]: "success"
        // 	})
        // 	setErrorMsgs({
        // 		...errorMsgs,
        // 		[e.target.name]: {
        // 			...res
        // 		}
        // 	})
        // 	return setFormData({
        // 		...formData,
        // 		[e.target.name]: e.target.value
        // 	});
        // } else {
        // 	setErrStyle({
        // 		...errStyle,
        // 		[e.target.name + "style"]: "error"
        // 	})
        // 	return setErrorMsgs({
        // 		...errorMsgs,
        // 		[e.target.name]: {
        // 			...res
        // 		}
        // 	})
        // }
      }

      case "industry_type": {

        setErrStyle({
          ...errStyle,
          [e.target.name + "style"]: null,
        });
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: null,
        });
        return setFormData({
          ...formData,
          "skills":null,
          [e.target.name]: e.target.value,
        });
      }

      case "jobdescription": {

        setErrStyle({
          ...errStyle,
          [e.target.name + "style"]: null,
        });
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: null,
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
      case "jobtype": {
        setErrStyle({
          ...errStyle,
          [e.target.name + "style"]: null,
        });
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: null,
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
      case "gender": {
        setErrStyle({
          ...errStyle,
          [e.target.name + "style"]: null,
        });
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: null,
        });
        console.log("Gender Value",e.target.value)
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
      case "jobexperience": {
        setErrStyle({
          ...errStyle,
          [e.target.name + "style"]: null,
        });
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: null,
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
      case "skills": {
        setErrStyle({
          ...errStyle,
          [e.target.name + "style"]: null,
        });
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: null,
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }

      case "location": {
        setErrStyle({
          ...errStyle,
          [e.target.name ]: null,
        });
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: null,
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  useEffect(() => {
    console.log("errors==>", errorMsgs);
  }, [errorMsgs]);

  // const errormsggenerator=(name) =>{
  // 	return (
  // 		!(errorMsgs && errorMsgs[name] && errorMsgs[name].validation) && <small className="errText">{errorMsgs && errorMsgs[name] && errorMsgs[name].errMsg}</small>
  // 	)
  // }

  useEffect(() => {
    setSelectedSkills(null);
  }, [formData.industry_type]);

  useEffect(() => {
    console.log("errMsg", errorMsgs);
  }, [errorMsgs]);

  useEffect(() => {
    console.log("errStyle", errStyle);
  }, [errStyle]);

  return (
    <Card
      className="addEmployer w3-animate-bottom"
      style={{
        width: "100%",
      }}
    >
      {/* <CardHeader>
						
					</CardHeader> */}
      <CardBody>
        <Form id="myform">
          <Row>
            <Col className="custombrdrright" md="12" lg="6" xl="6  p-4">
              <Form.Group controlId="jobtitle">
                <Form.Label className="mandatory">Job Title</Form.Label>
                <Form.Control
                  className={errStyle.jobtitlestyle}
                  value={formData.jobtitle}
                  autoComplete="off"
                  onChange={onChangeHandler}
                  name="jobtitle"
                  type="text"
                  placeholder="Enter Job Title"
                  maxLength={50}
                />
                {!(
                  errorMsgs &&
                  errorMsgs.jobtitle &&
                  errorMsgs.jobtitle.validation
                ) && (
                  <small className="errText">
                    {errorMsgs &&
                      errorMsgs.jobtitle &&
                      errorMsgs.jobtitle.errMsg}
                  </small>
                )}
              </Form.Group>
              <Form.Group controlId="industry_type">
                <Form.Label className="mandatory">Job Category</Form.Label>
                <Form.Control
                  className={errStyle.industry_typestyle}
                  name="industry_type"
                  value={formData.industry_type}
                  onChange={onChangeHandler}
                  as="select">
                  <option value='' >Select Job Category</option>
                  {jobCategoryList &&
                    jobCategoryList.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                </Form.Control>
                {/* <SelectComponent
                    name='job_category'
                    value={formData.job_category}
                    placeholder='Select Industry'
                    style={{ width: '100%' }}
                    onChange={onChangeHandler}
                  >
                    <option value='' selected>
                      Select Industry Type
                    </option>
                    {industryList &&
                      industryList.map((item) => {
                        return <option value={item.name}>{item.name}</option>
                      })}
                  </SelectComponent> */}

                {!(
                  errorMsgs &&
                  errorMsgs.industry_type &&
                  errorMsgs.industry_type.validation
                ) && (
                  <small className="errText">
                    {errorMsgs &&
                      errorMsgs.industry_type &&
                      errorMsgs.industry_type.errMsg}
                  </small>
                )}
              </Form.Group>
              <Form.Group controlId="addprimarycontactno">
                <Form.Label className="mandatory">Job Description</Form.Label>
                <textarea
                style={{color:'#8898aa'}}
                  value={formData.jobdescription}
                  className={errStyle.jobdescriptionstyle}
                  name="jobdescription"
                  onChange={onChangeHandler}
                  autoComplete="off"
                  placeholder="Enter Job Description"
                  maxLength={300}
                />
                {!(
                  errorMsgs &&
                  errorMsgs.jobdescription &&
                  errorMsgs.jobdescription.validation
                ) && (
                  <small className="errText">
                    {errorMsgs &&
                      errorMsgs.jobdescription &&
                      errorMsgs.jobdescription.errMsg}
                  </small>
                )}
              </Form.Group>
              <Form.Group controlId="addsecondarycontactno">
                <Form.Label className="mandatory">Job Type</Form.Label>
                <Form.Control
                  className={errStyle.jobtypestyle}
                  name="jobtype"
                  value={formData.jobtype}
                  onChange={onChangeHandler}
                  as="select"
                >
                  <option  value='' >Select Job Type</option>
                  {jobTypesList &&
                    jobTypesList.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                </Form.Control>
                {!(
                  errorMsgs &&
                  errorMsgs.jobtype &&
                  errorMsgs.jobtype.validation
                ) && (
                  <small className="errText">
                    {errorMsgs && errorMsgs.jobtype && errorMsgs.jobtype.errMsg}
                  </small>
                )}
              </Form.Group>
            </Col>
            <Col xl="6 p-4" lg="6" md="12">
              <Form.Group controlId="addgender">
                <FormGroup tag="fieldset" className="mb-0">
                  <legend className="mandatory" style={{fontSize:"14px"}}>Gender Preference</legend>
                  <FormGroup check>
                    <Input
                      checked={formData.gender === "1" ? true : ""}
                      onChange={onChangeHandler}
                      id="radio1-option1"
                      value={1}
                      type="radio"
                      name="gender"
                    />
                    <Label check for="radio1-option1">
                      Male
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      checked={formData.gender === "2" ? true : ""}
                      onChange={onChangeHandler}
                      type="radio"
                      value={2}
                      name="gender"
                      id="radio1-option2"
                    />
                    <Label check for="radio1-option2">
                      Female
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Input
                      checked={formData.gender === "3" ? true : ""}
                      onChange={onChangeHandler}
                      type="radio"
                      value={3}
                      name="gender"
                    />
                    <Label check for="radio1-option3">
                      Other
                    </Label>
                  </FormGroup>
                </FormGroup>
                {!(
                  errorMsgs &&
                  errorMsgs.gender &&
                  errorMsgs.gender.validation
                ) && (
                  <small className="errText">
                    {errorMsgs && errorMsgs.gender && errorMsgs.gender.errMsg}
                  </small>
                )}
              </Form.Group>
              <Form.Group controlId="jobexperience">
                <Form.Label className="mandatory">
                  Experience Required
                </Form.Label>
                <Form.Control
                  className={errStyle.jobexperiencestyle}
                  name="jobexperience"
                  value={formData.jobexperience}
                  onChange={onChangeHandler}
                  as="select"
                >
                  <option value='' >Select Experience</option>
                  {jobExperienceList &&
                    jobExperienceList.map((item) => {
                      return (
                        <option value={item.id}>
                          {item.min_exp}-{item.max_exp}
                        </option>
                      );
                    })}
                </Form.Control>
                {!(
                  errorMsgs &&
                  errorMsgs.jobexperience &&
                  errorMsgs.jobexperience.validation
                ) && (
                  <small className="errText">
                    {errorMsgs &&
                      errorMsgs.jobexperience &&
                      errorMsgs.jobexperience.errMsg}
                  </small>
                )}
              </Form.Group>
              {!skillDisabled && jobSkillList &&(
                <Form.Group controlId="skills" className={errStyle.skillsstyle}>
                  <Form.Label className="mandatory">Skills Required</Form.Label>
                  <Multiselect 
                    onSelect={onSelect}
                    onRemove={onRemove}
                    value={formData.skills}
                    selectedValues={selectedSkills}
                    options={jobSkillList}
                    displayValue="name"
                    name="skills"
                   
                    placeholder="select skills"
                    className={errStyle.skillsstyle}
                  />
                  {!(
                    errorMsgs &&
                    errorMsgs.skills &&
                    errorMsgs.skills.validation
                  ) && (
                    <small className="errText">
                      {errorMsgs && errorMsgs.skills && errorMsgs.skills.errMsg}
                    </small>
                  )}
                </Form.Group>
              )}

              <Form.Group controlId="location">
                <Form.Label className="mandatory">
                  Location for this job
                </Form.Label>
                <Form.Control
                  className={errStyle.location}
                  name="location"
                  value={formData.location}
                  onChange={onChangeHandler}
                  as="select"
                >
                  <option  value='' style={{ overflowX: "hidden" }}>
                    Select Location
                  </option>
                  {locationList.length > 0 &&
                    locationList.map((item) => {
                      return (
                        <option value={item.location_id}>
                          {item.location}
                        </option>
                      );
                    })}
                </Form.Control>
                {!(
                  errorMsgs &&
                  errorMsgs.location &&
                  errorMsgs.location.validation
                ) && (
                  <small className="errText">
                    {errorMsgs &&
                      errorMsgs.location &&
                      errorMsgs.location.errMsg}
                  </small>
                )}
              </Form.Group>
              <Form.Group className="mt-5 pt-1">
                <Row>
                  <Col
                    xl="4  ml-auto"
                    lg="6 d-flex justify-content-center"
                    md="6"
                    sm="6"
                    xs="12 mt-1"
                  >
                    <Button
                      // onClick={handleNext}
                      className="btnres ripple"
                      // style={{
                      //   background:
                      //     "linear-gradient(45deg, #7c4dff 30%, #b388ff  90%)",
                      //   color: "white",
                      // }}
                      style={{
                        background: "#7c4dff",
                        color: "white",
                      }}
                      onClick={handleSubmit}
                    >
                      {workFlow === "EDIT" ? "Update" : "Post"}
                    </Button>
                  </Col>
                  <Col
                    xl="4 "
                    lg="6 d-flex justify-content-center"
                    md="6"
                    sm="6"
                    xs="12 mt-1"
                  >
                    <Button
                      onClick={() => {
                        setTab("LIST");
                        setWorkFlow("ADD");
                      }}
                      className="btnres ripple text-white"
                      style={{
                      background: "#f5365c",
                      }}>
                      Cancel
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

const mapStateToProps = (state) => {
  return {
    auth_token: state.login.data.auth_token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobPost);
