import React from "react";
import ReactDOM from "react-dom";


import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";

import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import {Provider} from 'react-redux'
import {store,persistor} from "redux/store";
import App from "App";
import {PersistGate} from 'redux-persist/integration/react';

let states= store.getState()
console.log("STATES",states.login)
if (process.env.NODE_ENV !== "development")
    console.log = () => {};
    
ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
    <App/>
    </PersistGate>
  </Provider>
  ,
  document.getElementById("root")
);
