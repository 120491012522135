import React, { useState, useEffect } from 'react';
import {
	Card,
	CardBody,
	CardHeader,
	Col, Row,
	Badge,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Media,
	Progress,
	Table,
	Button,
	UncontrolledTooltip
} from 'reactstrap';
import KK_Toast from 'components/Toast/KK_Toast';
import { useHistory } from 'react-router';
import JobList from './JobList';
import JobPost from './Add_Job_Posting';
import { useSelector } from 'react-redux';



const JobModule = () => {
	const [toastConfig, setToastConfig] = useState({
		toastStatus: false,
		message: "",
		severity: null,
	})
	const [tab, setTab] = useState("LIST")
	const [workFlow, setWorkFlow] = useState("ADD")
	const [jobData, setJobData] = useState(null)
	const [jobListStatus, setJobListStatus] =useState(false)
	let history = useHistory()
	const role_id = useSelector(state => state.login.data.role_id);

	useEffect(()=>{
		if(workFlow === 'EDIT' ){
			setJobListStatus(true)
		}
		else {
			setJobListStatus(false)
		}
	},[workFlow])


	return (
		<div className="pr-2 pl-2 container-fluid">
			<Row className="mt-7 text-center w3-animate-top">
				<Col></Col>
				<Col xs="12" lg="4">
					<KK_Toast setToastConfig={setToastConfig} toastConfig={toastConfig} />
					<h2 className="listbrand">{
						tab === "LIST" ? "JOB LIST" : workFlow === "EDIT" ? "EDIT JOB" : "ADD JOB"
					}</h2>
				</Col>
				<Col xs="12 mt-3" lg="4" className="d-flex justify-content-end">
					{
						role_id === 1 ? "" : <Button className="ripple" style={{ backgroundColor: "transparent", color: "white" }}
							onClick={() => {
								tab === "LIST" ? setTab("ADD") : setTab("LIST")
							}}
							// disabled = {jobListStatus}
						>
							<i className="fas fa-user-plus mr-2" style={{
								color: "white"
							}}></i>
							{
								tab === "LIST" ? "Add New Job" : "Job List"
							}
						</Button>
					}
				</Col>
			</Row>
			<div className="body mt-2" style={{
				// backgroundColor:"#b388ff",
				// backgroundColor:"white",
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",
			}}>
				{
					tab === "LIST" ?
						<JobList setJobData={setJobData} setToastConfig={setToastConfig} setWorkFlow={setWorkFlow} setTab={setTab} />
						:
						<JobPost jobData={jobData} setJobData={setJobData} setWorkFlow={setWorkFlow} setToastConfig={setToastConfig} setTab={setTab} workFlow={workFlow} />
				}
			</div>
		</div>
	);
};

export default JobModule;