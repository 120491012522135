export const validation = {
  onlyLettersCheck(testValue, testFieldName) {
    if (testValue?.match("^[a-zA-Z]*$") != null) {
      return { validation: true };
    } else {
      return {
        validation: false,
        errMsg: "Only letters are allowed",
      };
    }
  },

  // accept letters with few symbols only (for job type)
  checkLettersWithSymbol(testValue) {
    if (testValue?.match("^[a-zA-z_,.& ]*$") != null) {
      return { validation: true };
    } else {
      return {
        validation: false,
        errMsg: "Only letters with specific special symbols are allowed",
      };
    }
  },
  // accept letters with few symbols and UNICODE (for job type)
  checkLettersSymbolsUnicode(testValue) {
    if (testValue?.match("[^\u0000-\u007F]|^[a-zA-z_,-.& ]*$") != null) {
      return { validation: true };
    } else {
      return {
        validation: false,
        errMsg: "Only letters with specific special symbols are allowed",
      };
    }
  },

  // Checking Max Length 
  checkMaxLength(testValue){
    if(testValue?.length > 30){
      return {
        validation : false,
        errMsg :"Up to 30 characters are allowed"
      }
    }
    else{
      return{
        validation:true
      }
    }
  },
  // accept letters with few symbols only (for job type)

  onlyLettersWithSpaceCheck(testValue, testFieldName) {
    if (testValue?.match("^[a-zA-Z0-9_. ]*$") != null) {
      return { validation: true };
    } else {
      return {
        validation: false,
        errMsg: "Only letters and numbers are allowed",
      };
    }
  },


  // validation for skills
  checkForSkills(testValue) {
    if (testValue?.match("^[a-zA-Z0-9_#.,+ ]*$") != null) {
      return { validation: true };
    } else {
      return {
        validation: false,
        errMsg: "Only letters with specific special symbols are allowed",
      };
    }
  },

  onlyNumberCheck(testValue) {
    if (testValue?.match("^[0-9]{10,10}$") != null) {
      return { validation: true };
    } else {
      return {
        validation: false,
        errMsg: "Enter valid contact number",
      };
    }
  },
  // ^[a-zA-Z]+[0-9_]{10}$
  // validation for accept underscore and alphanumeric
 underscore(testValue) {
    if (testValue?.match("^[a-zA-Z0-9_]*$")) {
      // if (testValue.match("^[0-9_]{10}$") !=null){  
        return { validation: true };
      // }
    } else {
      return {
        validation: false,
        errMsg: "Enter valid user number",
      };
    }
  },


  ageCheck(testValue, testFieldName) {
    // console.log(Number.isInteger(Number(testValue)));
    if(testValue?.length===0){
      return {
        validation:true,
        // errMsg:"Enter Number"
      }
    }
    else if(!Number.isInteger(Number(testValue))){
      return {
        validation:false,
        errMsg:"Only numbers are allowed"
      }
    }
    else if(testValue <= 0 || testValue > 100){
      return {
        validation:false,
        errMsg:"Invalid Age"
      }
    }
    else{
      return { validation: true };
    }
    
    // if (testValue.match('^[1-9][0-9]*$'))
    // if (testValue.match("^0*[1-9][0-9]?$|^100$")) {
    //   return { validation: true };
    // } else {
    //   return {
    //     validation: false,
    //     errMsg: "Only numbers are allowed",
    //   };
    // }
  },

  spacesCheck(testValue, testFieldName) {
    if (testValue && testValue?.includes(" ")) {
      return { validation: false, errMsg: `${testFieldName} is required` };
    } else {
      return {
        validation: true,
      };
    }
  },

  spaceCheck(testValue, testFieldName) {
   
      if ( testValue === null || testValue?.match(/^ * $/) !== null) {
        return {
          validation: false,
          errMsg: "Only blankspaces are not allowed",
        };
      } else {
        return { validation: true };
        
      }
    },

  checkForEmpty(testValue, testFieldName) {
    console.log("TEST FIELD NAME", testFieldName);
    console.log("TEST FIELD NAME", testValue);
    if (testValue && testValue?.length !== "") {
      return { validation: true };
    } else {
      return {
        validation: false,
        errMsg: `${
          testFieldName[0]?.toUpperCase() + testFieldName?.substring(1)
        } is required`,
      };
    }
  },

  checkPhoneDigit(testValue,testFieldName){
    console.log("TEST FIELD NAME", testFieldName);
    console.log("TEST FIELD NAME", testValue);
    if (testValue && testValue?.length === 10) {
      return { validation: true };
    } else {
      return {
        validation: false,
        errMsg: `${
          testFieldName[0]?.toUpperCase() + testFieldName?.substring(1)
        } is required`,
      };
    }
  },

  fileSizeCheck(fileSize) {
    if (fileSize > 1000000) {
      return {
        validation: false,
        errMsg: "File size can't be more than 1MB",
      };
    }
    return { validation: true };
  },

  emailCheck(testValue) {
    if (
      testValue?.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) != null
    ) {
      return { validation: true };
    } else {
      return {
        validation: false,
        errMsg: "Enter valid e-mail",
      };
    }
  },
  fullnameCheck(testValue) {
    if (testValue?.match(/^([a-zA-Z'-.]+(?: [a-zA-Z'-.]+)+(?: [a-zA-Z'-.]+)?)$/) != null) {
      return { validation: true };
    } else {
      return {
        validation: false,
        errMsg: "Enter valid full name",
      };
    }
  },
};
