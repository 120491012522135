/* eslint-disable react-hooks/exhaustive-deps */
import CountTag from "components/count_tag/CountTag";
import React, { useEffect, useState } from "react";
import { Row, Col, Card, CardBody, CardFooter, Navbar } from "reactstrap";
import Chart from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import {
  chartOptions,
  parseOptions,
  chartExample2,
  chartExample1,
} from "variables/charts.js";
import "./dashboard.scss";
import { connect, useSelector } from "react-redux";
import { logout } from "redux/login/login.action";
import apis from "apis";
import { useParams } from "react-router";
import KK_Toast from "components/Toast/KK_Toast";

const DashBoard = ({ logout }) => {
  const [toastConfig, setToastConfig] = useState({
    toastStatus: false,
    message: "",
    severity: null,
  });
  let { loggedIn } = useParams();

  const auth_token = useSelector((state) => state.login.data.auth_token);
  const [empCount, setEmpCount] = useState(null);
  const [jobCount, setJobCount] = useState(null);
  const [candidateCount, setCandidateCount] = useState(0);
  const [incompleteProfileCount, setIncompleteProfileCount] = useState(0);
  const [appliedCount, setAppliedCount] = useState(null);
  const [monthWiseEmpCount, setMonthWiseEmpCount] = useState([]);
  const [monthWiseAppliedCount, setMonthWiseAppliedCount] = useState(null);
  const [monthWiseAppliedCountX, setMonthWiseAppliedCountX] = useState([]);
  // const [jobCountYear, setJobCountYear] = useState(null);
  const [monthWiseJobCountArray, setMonthWiseJobCountArray] = useState([]);
  const role_Id = useSelector((state) => state.login.data.role_id);

  // To display toast message when logged in
  useEffect(() => {
    if (loggedIn) {
      setToastConfig({
        toastStatus: true,
        message: "Login Successfully",
        severity: "success",
      });
    }
  }, [loggedIn]);

  useEffect(() => {
    console.log("LATEST JOB COUNT", jobCount);
  }, [jobCount]);

  let stateD = {
    labels:
      monthWiseAppliedCount &&
      monthWiseAppliedCount.map((item) => item.month && item.month.slice(0, 3)),
    datasets: [
      {
        label: "Applied Candidates",

        backgroundColor: "#ff5722",
        hoverBackgroundColor: ["#4B5000", "#175000", "#003350"],
        fill: false,
        data: monthWiseAppliedCountX,
        barThickness: 20,
      },
    ],
  };
  let stateL = {
    labels:
      monthWiseJobCountArray &&
      monthWiseJobCountArray.map((item) => item.month.slice(0, 3)),
    datasets: [
      {
        label: "Jobs",
        backgroundColor: [
          "#ff1744",
          "#ffff8d",
          "#69f0ae",
          "#0091ea",
          "#7c4dff",
        ],
        hoverBackgroundColor: [
          "#501800",
          "#4B5000",
          "#175000",
          "#003350",
          "#35014F",
        ],
        fill: true,
        data:
          monthWiseJobCountArray &&
          monthWiseJobCountArray.map((item) => item.jobCount),
        borderColor: "#3f51b5",
        pointBackgroundColor: "yellow",
      },
    ],
  };

  useEffect(async () => {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
    let res1;
    let res3;
    let jobPostedCountRes;
    let appliedCandidateCountRes;
    let monthWiseJobCountRes;
    let monthWiseAppliedCandidateCount;
    let candidateCountRes;
    try {
      monthWiseAppliedCandidateCount = await apis.masters.getAllRecords(
        auth_token,
        "/api/v1/master/postJob_monthwise_appliedcount"
      );
      if (monthWiseAppliedCandidateCount.success) {
        let newArray = monthWiseAppliedCandidateCount.data.map((i) => ({
          appliedCandidateCount: i.appliedCandidateCount,
          month: i.month,
        }));
        console.log("MonthWise Applied Count", newArray);
        setMonthWiseAppliedCount(newArray);
        let xAxis = newArray.map((item) =>
          parseInt(item.appliedCandidateCount)
        );
        setMonthWiseAppliedCountX(xAxis);
      }
    } catch (err) {
      // console.log('err', err.response)
      if (err.response && err.response.status === 401) {
        setToastConfig({
          toastStatus: true,
          message: "Session Expired!",
          severity: "error",
        });
        setTimeout(() => {
          logout();
        }, 2000);
      }
    }

    try {
      monthWiseJobCountRes = await apis.masters.getAllRecords(
        auth_token,
        "/api/v1/master/postJob_monthwise_count"
      );
      if (monthWiseJobCountRes.success) {
        console.log(monthWiseJobCountRes.data);
        let newArray = monthWiseJobCountRes.data.map((i) => ({
          jobCount: i.jobCount,
          month: i.month,
        }));
        // setJobCountYear(monthWiseJobCountRes.data[0].Year);
        setMonthWiseJobCountArray(newArray);
      }
    } catch (err) {
      console.log(err.response);
    }

    try {
      if (role_Id === 1) {
        res1 = await apis.masters.getAllRecords(
          auth_token,
          "/api/v1/dashboard/employer_count"
        );
        if (res1.success) {
          setEmpCount(res1.data.employer_count);
        }
      }
    } catch (err) {
      console.error(err);
    }

    try {
      jobPostedCountRes = await apis.masters.getAllRecords(
        auth_token,
        "/api/v1/master/postJob_count"
      );
      if (jobPostedCountRes.success) {
        console.log("CONTROL IS IN JOB POST SETTING");
        setJobCount(jobPostedCountRes.data[0].JobCount);
      }
    } catch (err) {
      console.error(err);
    }

    try {
      appliedCandidateCountRes = await apis.masters.getAllRecords(
        auth_token,
        "/api/v1/master/applied_candidate_count"
      );

      if (appliedCandidateCountRes.success) {
        setAppliedCount(appliedCandidateCountRes.data[0].appliedCandidateCount);
        setIncompleteProfileCount(
          appliedCandidateCountRes.data[0].incompletedCandidate
        );
      }
    } catch (err) {
      console.error(err);
    }

    try {
      candidateCountRes = await apis.counts.count("/candidateCount");
      if (candidateCountRes.resultCode === 1) {
        setCandidateCount(candidateCountRes.resultData[0].candidateCount);
      }
    } catch (err) {
      console.error(err);
    }
    try {
      if (role_Id === 1) {
        res3 = await apis.masters.getAllRecords(
          auth_token,
          "/api/v1/dashboard/month_wise_employer_count"
        );
      }

      if (res3.success) {
        setMonthWiseEmpCount(res3.data);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  // useEffect(()=>{
  //  console.log(a);
  // },[a])

  return (
    <div className="dashBoardClass container-fluid">
      <KK_Toast setToastConfig={setToastConfig} toastConfig={toastConfig} />
      <div style={{ height: "84px", width: "100%" }}></div>
      <Row className="text-center w3-animate-top">
        <Row
          className="tags container-fluid "
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
            flexWrap: "wrap",
            position: "relative",
          }}
        >
          {
            role_Id === 1 && (
              // <Col lg="3" sm="6" md="6" className="d-flex justify-content-center">
              <CountTag
                className="mt-2"
                dataCount={empCount}
                header="Employers"
                iconLink={"fas fa-user-tie fa-2x"}
                color="#f5365c"
              />
            )
            // </Col>
          }
          {/* <Col lg={role_Id===1?"3":"4"} sm="6" md="6" className="d-flex justify-content-center"> */}
          <CountTag
            className="mt-2"
            dataCount={candidateCount}
            header="Candidates"
            iconLink={"fas fa-users fa-2x"}
            color="#673ab7"
          />
          {/* </Col> */}
          {/* <Col lg={role_Id===1?"3":"4"} sm="6" md="6" className="d-flex justify-content-center"> */}
          <CountTag
            className="mt-2"
            dataCount={appliedCount}
            header="Jobs Applied"
            iconLink={"fas fa-user-tie fa-2x"}
            color="#009688"
          />
          {/* </Col> */}
          {/* <Col lg={role_Id===1?"3":"4"} sm="6" md="6" className="d-flex justify-content-center"> */}
          <CountTag
            className="mt-2"
            dataCount={jobCount}
            header="Jobs Posted"
            iconLink={"fas fa-briefcase fa-2x"}
            color="#d500f9"
          />
          {role_Id === 1 && (
            <CountTag
              className="mt-2"
              dataCount={incompleteProfileCount}
              header="Incomplete Profiles"
              iconLink={"fas fa-users-slash fa-2x"}
              color="#81544B"
            />
          )}

          {/* </Col> */}
        </Row>
        <Row
          className="d-flex justify-content-around mt-5  container-fluid"
          style={{
            width: "100%",
            height: "fit-content",
          }}
        >
          <Col lg="6 " className="d-flex justify-content-center mt-2 ">
            <Card className="box-shadow chart-card" style={{ width: "100%" }}>
              <CardBody>
                <div className="chart">
                  <Bar
                    data={stateD}
                    options={{
                      layout: {
                        padding: {
                          left: -5,
                          right: 0,
                          top: 0,
                          bottom: -8,
                        },
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                      title: {
                        display: true,
                        text: `Applied Jobs`,
                        fontSize: 15,
                      },
                      scales: {
                        xAxes: [
                          {
                            gridLines: {
                              display: false,
                              color: "rgba(255, 0, 0, .2)", // can still set the colour.
                            },
                          },
                        ],
                        yAxes: [
                          {
                            gridLines: {
                              display: false,
                            },
                            // scaleLabel: {
                            //   display: true,
                            //   labelString: 'Revenue'
                            // },
                            ticks: {
                              suggestedMin: 5000000,
                              lineTension: 0,
                            },
                          },
                        ],
                      },
                    }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6" className="d-flex justify-content-center mt-2 mb-3">
            <Card
              className="bg-default box-shadow chart-card"
              style={{ width: "100%" }}
            >
              <CardBody>
                <div className="chart">
                  {/* Chart wrapper */}
                  {/* <Line
									data={chartExample1.data1}
									options={chartExample1.options}
									getDatasetAtEvent={e => console.log(e)}
								/> */}
                  <Line
                    data={stateL}
                    options={{
                      layout: {
                        padding: {
                          left: -5,
                          right: 0,
                          top: 0,
                          bottom: -8,
                        },
                      },
                      responsive: true,
                      maintainAspectRatio: false,
                      title: {
                        display: true,
                        text: `Total Jobs`,
                        fontSize: 15,
                      },
                      scales: {
                        xAxes: [
                          {
                            gridLines: {
                              display: false,
                              color: "rgba(255, 0, 0, .2)", // can still set the colour.
                            },
                          },
                        ],
                        yAxes: [
                          {
                            gridLines: {
                              display: false,
                              color: "#84ffff77",
                            },

                            ticks: {
                              suggestedMin: 5000000,
                              lineTension: 0,
                            },
                          },
                        ],
                      },
                      legend: {
                        display: false,
                      },
                    }}
                  />
                </div>
              </CardBody>
            </Card>
            <br />
            <br />
            <br />
            <br />
            <br />
          </Col>
        </Row>
      </Row>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(null, mapDispatchToProps)(DashBoard);
