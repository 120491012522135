import {combineReducers} from 'redux';
import employerReducer from './employer/employer.reducer'
import { loginReducer } from './login/login.reducer';
import paginationReducer from './pagination/pagination.reducer';

const rootReducer= combineReducers({
    employer: employerReducer,
    pagination:paginationReducer,
    login:loginReducer
}); 

export default rootReducer

