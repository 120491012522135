import React from "react";
import { Row, Button } from "reactstrap";

const Pagination2 = ({
    pageNumber,
  onChangeNext,
  onChangePrev,
  pageOffset,
  length,
  recordsPerPage,
}) => {
  return (
    <Row  className="pagination mt-2 mb-5 w3-animate-bottom d-flex justify-content-center">
      <Button
        className="mr-3 "
        disabled={pageNumber===1}
        onClick={onChangePrev}
      >
        &lt;
      </Button>
      <Button
        disabled={pageNumber>=length}
        onClick={onChangeNext}
      >
        &gt;
      </Button>
    </Row>
  );
};

export default Pagination2;
