/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable default-case */
import KK_Toast from 'components/Toast/KK_Toast'
import React, { useEffect, useState } from 'react'
import AddEmployerForm from 'views/AddEmployerForm/AddEmployerForm'
import {
	Button,
	FormGroup,
	Input,
	InputGroupAddon,
	InputGroupText,
	InputGroup,
	Row,
	Col,
	Card,
	CardHeader,
	CardBody
} from "reactstrap";
import Form from 'react-bootstrap/Form'
import apis from 'apis';
import { useHistory, useParams } from 'react-router';
import { connect } from 'react-redux';
import { setWorkFlow } from 'redux/employer/employer.action';
import { validation } from 'components/_helpers/validation'
import Map from 'components/MapModel/Map';
import Map2 from 'components/MapModel/Map2';
import { Multiselect } from 'multiselect-react-dropdown';


const AddEmployer = ({ address, setToastConfig, workFlow, employerId, setTab, setWorkFlow, setAddress, auth_token }) => {
	console.log(workFlow,employerId)
	const [errorMsgs, setErrorMsgs] = useState()
	const [enteredValue, setEnteredValue] = useState(null)
	const [errorCheckresult, setErrorCheckResult] = useState(null)
	console.log("Workflow check", workFlow === "EDIT")
	const [mapVisibility, setMapVisibility] = useState(false)
	const [flow,setFlow] = useState("ADD")
	const [prevData, setPrevData] = useState()
	let history = useHistory()
	// creating new state for location field compulsory
	const [locationErrMsg, setLocationErrMsg] = useState("1");
	const [formData, setFormData] = useState({
		firstname: null,
		lastname: null,
		companyname: null,
		contact1: null,
		contact2: null,
		industry_type_id: null,
		email: null,
		website: null,
		core_work: null,
		account_status:null

	});

	const [errStyle, setErrStyle] = useState({})

	const [fieldStyle, setFieldStyle] = useState(null)
	const [categoryList, setCategoryList] = useState([])
	

	// const onIndustrySelect = (e) =>{
	// 	let idArr=[]
	// 	e.map(i=>{
	// 		 idArr.push(i.id)
	// 	})
		
	// 	setFormData({...formData,industry_type_id:idArr})
	// }
	// const onIndustryRemove = (e) =>{
	// 	let idArr=[]
	// 	e.map(i=>{
	// 		 idArr.push(i.id)
	// 	})
	// 	setFormData({...formData,industry_type_id:idArr})
	// }

	useEffect(async () => {
		try {
			let res = await apis.masters.getAllRecords(auth_token, '/api/v1/master/jobCategory')
			
			if (res.success) {
				setCategoryList(res.data)
			}
			if (workFlow === "EDIT") {
				console.log(employerId)
				let response = await apis.masters.getSingleEmployer(auth_token,employerId)
				console.log("RESPONSE OF OLD DATA",response)
				let employerData= response.data.find(item=>item)
				setPrevData(employerData)
			}	
		}
		catch (err) {
			console.error(err.response)
		}
	}, [])

	useEffect(() => {
		console.log("PREV DATA IS THIS",prevData)
		if(prevData){
			setAddress([
				...prevData.locations
			])
		}
	}, [prevData])


	useEffect(()=>{
		console.log("CATEGORYLIST",categoryList)
	},[categoryList])

	useEffect(() => {
		if (workFlow === "EDIT" && prevData) {
			const {
				first_name,
				last_name,
				contact_number1,
				contact_number2,
				company_name,
				website,
				core_work,
				account_status,
				email,
				industry_type_id,
			} = prevData
			setFormData({
				industry_type_id: industry_type_id,
				companyname: company_name,
				contact1: contact_number1,
				contact2: contact_number2,
				website:website,
				core_work:core_work,
				account_status:account_status,
				email: email,
				firstname: first_name,
				lastname: last_name,
			})
		}
	}, [prevData])

	// add this for validation on "Add Company Location" field
	useEffect(()=>{
		if(address.length === 1){
			setLocationErrMsg("1")
		}
	},[address])

	const handleSubmit = () => {
		scrollToTop()
		console.log("address ===>", address)
		let resfirstname = validation.checkForEmpty(formData.firstname, "first name")
		let reslastname = validation.checkForEmpty(formData.lastname, "last name")
		let rescompanyname = validation.checkForEmpty(formData.companyname, "company name")
		let resindustry_type_id = validation.checkForEmpty(formData.industry_type_id, "Job category")
		let resemail = validation.checkForEmpty(formData.email, "email")
		resemail = validation.emailCheck(formData.email,"email")	
		let reswebsite = validation.checkForEmpty(formData.website,"Website url")
		let rescore_work = validation.checkForEmpty(formData.core_work,"core work description")
		let resaccount_status = validation.checkForEmpty(formData.account_status,"account status")
		let rescontact1 = validation.checkForEmpty(formData.contact1,"Primary contact number")
		rescontact1 = validation.checkPhoneDigit(formData.contact1,"Primary contact number")
		rescontact1 = validation.onlyNumberCheck(formData.contact1,"Primary contact number")
		// let rescontact2;
		// if(formData.contact2?.length > 0){
		// 	rescontact2 = validation.onlyNumberCheck(formData.contact2,"contact number2")
		// }
		

		setErrorMsgs({
			...errorMsgs,
			firstname: { ...resfirstname },
			lastname: { ...reslastname },
			email: { ...resemail },
			companyname: { ...rescompanyname },
			industry_type_id: { ...resindustry_type_id },
			website:{...reswebsite},
			core_work:{...rescore_work},
			account_status:{...resaccount_status},
			contact1:{...rescontact1},
			// contact2:{...rescontact2}
		})

		setErrStyle({
			...errStyle,
			firstnamestyle: (resfirstname.validation === false) ? "error" : "success",
			lastnamestyle: (reslastname.validation === false) ? "error" : "success",
			emailstyle: (resemail.validation === false) ? "error" : "success",
			companynamestyle: (rescompanyname.validation === false) ? "error" : "success",
			industry_type_idstyle: (resindustry_type_id.validation === false) ? "error" : "success",
			contact1style:(rescontact1.validation===false)?"error":"success",
			// contact2style:(rescontact2?.validation===false)?"error":"success",
			websitestyle:(reswebsite.validation===false)? "error" : "success",
			core_workstyle:(rescore_work.validation===false)?"error":"success",
			account_statusstyle:(resaccount_status.validation===false)?"error":"success"
		})

		if(address.length === 0){
			setLocationErrMsg("0")
		}else if(address.length === 1){
			setLocationErrMsg("1")
		}
	
		
		const { firstname, lastname, companyname, contact1, contact2, email, industry_type_id,core_work,account_status,website} = formData
		if ((!!firstname && lastname && companyname && email && industry_type_id && website)) {
			let body = {
				first_name: firstname,
				last_name: lastname,
				company_name: companyname,
				email: email,
				contact_number1: contact1,
				industry_type_id: industry_type_id,
				locations: address,
				account_status: parseInt(account_status),
				core_work:core_work,
				website:website
			}
			// contact no 2 is has not error then it will accepted 
			if (contact2 && errStyle.contact2style !== "error") body.contact_number2 = contact2
			console.log("BODY BEFORE SUBMIT",body)
			console.log( "Error Style - ",errStyle.contact1style)
			if (workFlow === "EDIT" && errStyle.contact1style !== "error" &&  errStyle.emailstyle !== "error") {
				body.employer_id= employerId
				UpdateEmployer(body)
			} else if(workFlow === "ADD" && errStyle.contact1style !== "error"  &&  errStyle.emailstyle !== "error") {
				createEmployer(body)
			}

			// clearHandler()
		}

	}

	const scrollToTop = () => {
		window.scrollTo(0, 0);
	}

	const createEmployer = async (body) => {
		console.log("createEmployer Body",body)
		// add this if for applying validation on Add Location Field"
		if(body.locations.length === 0){
			setLocationErrMsg("0")
		} else{
			setLocationErrMsg("1")

			try {
				console.log(auth_token)
				let res = await apis.masters.createRecordRequest(auth_token, '/api/v1/master/employer', body)
				console.log(res.data)
				if(res.data.code==="ER_DUP_ENTRY"){
					setToastConfig({
						toastStatus: true,
						message: "Employer Already Exists",
						severity: "error"
					})
					return
				}
				if (res.success) {
					scrollToTop()
					setToastConfig({
						toastStatus: true,
						message: res.message,
						severity: "success"
					})
					setTab("LIST");
					setAddress([])
					return setWorkFlow("ADD")
				} else {
					scrollToTop()
					setToastConfig({
						toastStatus: true,
						message: res.message,
						severity: "error"
					})
				}
			}
			catch (err) {
				scrollToTop()
				setToastConfig({
					toastStatus: true,
					message: err.message,
					severity: "error"
				})
				console.error(err.response)
			}
		}
	}

	const UpdateEmployer = async (body) => {
		// adding if block for handling error if loaction is not selected
		if(body.locations.length){
			address[address.length-1].employer_id = employerId;
			console.log("body.locations.length",body.locations.length)
		}
		 console.log("Omkar Logged", employerId)
		console.log("UPDATEBody----------------------------++++++++++++++++", body)
		try {
			let res = await apis.masters.updateRecordRequest(auth_token, "/api/v1/master/employer", employerId, body)
			if (res.success) {
				scrollToTop()
				setToastConfig({
					toastStatus: true,
					message: res.message,
					severity: "success"
				})
				setTab("LIST");
				return setWorkFlow("ADD")
			} else {
				scrollToTop()
				setToastConfig({
					toastStatus: true,
					message: res.message,
					severity: "error"
				})
			}
		}
		catch (err) {
			scrollToTop()
			setToastConfig({
				toastStatus: true,
				message: err.message,
				severity: "error"
			})
			console.error(err.response)
		}
	}

	const onChangeHandler = (e) => {

		switch (e.target.name) {
			case "firstname": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				let res = validation.onlyLettersCheck(e.target.value, e.target.name)
			
				console.log("Response", res)
				if (res.validation === true) {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: null
					})
					setErrorMsgs({
						...errorMsgs,
						[e.target.name]: {
							...res
						}
					})
					return setFormData({
						...formData,
						[e.target.name]: e.target.value
					});
				} else {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: "error"
					})
					return setErrorMsgs({
						...errorMsgs,
						[e.target.name]: {
							...res
						}
					})
				}
			}
			case "lastname": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				let res = validation.onlyLettersCheck(e.target.value, e.target.name)
				
				console.log("Response", res)
				if (res.validation === true) {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: null
					})

					setErrorMsgs({
						...errorMsgs,
						[e.target.name]: {
							...res
						}
					})
					return setFormData({
						...formData,
						[e.target.name]: e.target.value
					});
				} else {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: "error"
					})
					return setErrorMsgs({
						...errorMsgs,
						[e.target.name]: {
							...res
						}
					})
				}

			}

			// case "companyname": {
			// 	setErrStyle({
			// 		...errStyle,
			// 		[e.target.name + "style"]: null
			// 	})
			// 	setErrorMsgs({
			// 		...errorMsgs,
			// 		[e.target.name]: null
			// 	})
			// 	return setFormData({
			// 		...formData,
			// 		[e.target.name]: e.target.value
			// 	});
			// }


			case "companyname": {
			
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})


				let res = validation.spaceCheck(e.target.value, e.target.name)
				
				console.log("Response", res)
				if (res.validation === true) {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: null
					})

					setErrorMsgs({
						...errorMsgs,
						[e.target.name]: {
							...res
						}
					})
					return setFormData({
						...formData,
						[e.target.name]: e.target.value
					});
				} else {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: "error"
					})
					return setErrorMsgs({
						...errorMsgs,
						[e.target.name]: {
							...res
						}
					})
				}

			}

			case "contact1": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				let res = validation.onlyNumberCheck(e.target.value)
				console.log("Number Res", res)
				if (res.validation === true) {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: null
					})

					return setFormData({
						...formData,
						[e.target.name]: e.target.value
					});
				} else {
					setErrorMsgs({
						...errorMsgs,
						[e.target.name]: { ...res }
					})
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: "error"
					})
					return setFormData({
						...formData,
						[e.target.name]: e.target.value
					});
				}
			}
			case "contact2": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				console.log("e.target.value.length", e.target.value.length)
				console.log("formData.contact2?.length",formData.contact2?.length)
				if(e.target.value.length > 0 ){
					let res = validation.onlyNumberCheck(e.target.value)
					console.log("Number Res", res)
					if (res.validation === true) {
						setErrStyle({
							...errStyle,
							[e.target.name + "style"]: null
						})

						return setFormData({
							...formData,
							[e.target.name]: e.target.value
						});
						} else {
						setErrorMsgs({
							...errorMsgs,
							[e.target.name]: { ...res }
						})
						setErrStyle({
							...errStyle,
							[e.target.name + "style"]: "error"
						})
						return setFormData({
							...formData,
							[e.target.name]: e.target.value
						});
					}
				}
				// if(formData.contact2?.length === 0){
				// 	setErrorMsgs({...errorMsgs,contact2:""})
				// 	setErrStyle({...errStyle, contact2style:"success"})
				// 	}
				return setFormData({
					...formData,
					[e.target.name]: e.target.value
				});
			}
			case "email": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				let res = validation.emailCheck(e.target.value)
				console.log("Email Res", res)
				if (res.validation === true) {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: null
					})
					return setFormData({
						...formData,
						[e.target.name]: e.target.value
					});
				} else {
					
					setErrorMsgs({
						...errorMsgs,
						[e.target.name]: {
							...res
						}
					})
					 setErrStyle({
						...errStyle,
						[e.target.name + "style"]: "error"
					})
					return setFormData({
						...formData,
						[e.target.name]: e.target.value
					});
					
				}
			}
			case "industry_type_id": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				return setFormData({
					...formData,
					[e.target.name]: e.target.value
				});
			}
			case "website": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				let res = validation.spacesCheck(e.target.value, e.target.name)
				
				console.log("Response", res)
				if (res.validation === true) {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: null
					})

					setErrorMsgs({
						...errorMsgs,
						[e.target.name]: {
							...res
						}
					})
					return setFormData({
						...formData,
						[e.target.name]: e.target.value
					});
				} else {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: "error"
					})
					return setErrorMsgs({
						...errorMsgs,
						[e.target.name]: {
							...res
						}
					})
				}
					
				} 

			case "core_work": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				let res = validation.spaceCheck(e.target.value, e.target.name)
				
				console.log("Response", res)
				if (res.validation === true) {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: null
					})

					setErrorMsgs({
						...errorMsgs,
						[e.target.name]: {
							...res
						}
					})
					return setFormData({
						...formData,
						[e.target.name]: e.target.value
					});
				} else {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: "error"
					})
					return setErrorMsgs({
						...errorMsgs,
						[e.target.name]: {
							...res
						}
					})
				}

			}
			case "account_status": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				return setFormData({
					...formData,
					[e.target.name]: e.target.value
				});
			}
			// case "address1": {
			// 	setErrStyle({
			// 		...errStyle,
			// 		[e.target.name + "style"]: null
			// 	})
			// 	setErrorMsgs({
			// 		...errorMsgs,
			// 		[e.target.name]: null
			// 	})
			// 	return setFormData({
			// 		...formData,
			// 		[e.target.name]: e.target.value
			// 	});
			// }
			// case "address2": {
			// 	return setFormData({
			// 		...formData,
			// 		[e.target.name]: e.target.value
			// 	});
			// }
			// case "city": {
			// 	setErrStyle({
			// 		...errStyle,
			// 		[e.target.name + "style"]: null
			// 	})
			// 	setErrorMsgs({
			// 		...errorMsgs,
			// 		[e.target.name]: null
			// 	})
			// 	return setFormData({
			// 		...formData,
			// 		[e.target.name]: e.target.value
			// 	});
			// }
			// case "state": {
			// 	setErrStyle({
			// 		...errStyle,
			// 		[e.target.name + "style"]: null
			// 	})
			// 	setErrorMsgs({
			// 		...errorMsgs,
			// 		[e.target.name]: null
			// 	})
			// 	return setFormData({
			// 		...formData,
			// 		[e.target.name]: e.target.value
			// 	});
			// }
			// case "pincode": {
			// 	setErrStyle({
			// 		...errStyle,
			// 		[e.target.name + "style"]: null
			// 	})
			// 	setErrorMsgs({
			// 		...errorMsgs,
			// 		[e.target.name]: null
			// 	})
			// 	return setFormData({
			// 		...formData,
			// 		[e.target.name]: e.target.value
			// 	});
			// }
		}

	}


	useEffect(()=>{
		console.log("FORMDATA",formData)
	},[formData])

	const clearHandler = () => {
		setErrStyle({})
		setErrorMsgs({})
		setFormData({
			firstname: null,
			lastname: null,
			companyname: null,
			contact1: null,
			contact2: null,
			address1: null,
			address2: null,
			email: null,
			city: null,
			state: null,
			pincode: null
		})
		document.getElementById("myform").reset();
	}

	const removeAddressTag = (index) => {
		let newAddress = []
		newAddress = address.filter((item, idx) => {
			return idx !== index
		})
		console.log("New Address", newAddress)
		setAddress(newAddress)
	}

	useEffect(() => {

	}, [errorMsgs])

	// const errormsggenerator=(name) =>{
	// 	return (
	// 		!(errorMsgs && errorMsgs[name] && errorMsgs[name].validation) && <small className="errText">{errorMsgs && errorMsgs[name] && errorMsgs[name].errMsg}</small>
	// 	)
	// }

	const addressHandler = () => {
		setMapVisibility(true)
	}

	useEffect(() => {
		console.log(formData)
	}, [formData])

	useEffect(() => {


	}, [errorMsgs])

	useEffect(() => {
		console.log("errStyle", errStyle)
	}, [errStyle])

	const [fadeout, setFadeOut] = useState({
		id: null,
		status: false
	})
	useEffect(() => {
		console.log("FADE OUT", fadeout)
	}, [fadeout])
	
	const [selectedLocation,setSelectedLocation]= useState({
		address_line1: null,
        address_line2: null,
        landmark: null,
        city: null,
        state: null,
        country: null,
        zip: null,
        geopoint_lat: null,
        geopoint_long: null,
	})

	const [selectedIdx,setSelectedIdx] = useState(null)
	
	return (
		<>
			{
				mapVisibility&&<Map workFlow={flow} address={address} selectedLocation={selectedLocation} selectedIdx={selectedIdx} setAddress={setAddress} setMapVisibility={setMapVisibility} isOpen={mapVisibility} setWorkFlow={setFlow} />
			}
			
			<Card className="addEmployer w3-animate-bottom" style={{
				width: "100%"
			}}>
				<CardHeader className='pl-4 pt-2 pb-0'>
                   {workFlow === "EDIT" ? (<Form.Label>Edit Employer</Form.Label>) : (<Form.Label>
					Add Employer</Form.Label>)} 
                 </CardHeader>
				<CardBody >
					<Form id="myform" autoComplete="none">
						<Row>
							<Col className="custombrdrright" md="12" lg="6" xl="6  p-4">
								<Form.Group controlId="name">
									<Row>
										<Col >
											<Form.Label className="mandatory">First Name</Form.Label>
											<Form.Control maxLength={50} autoComplete="none" className={errStyle.firstnamestyle} value={formData.firstname} onChange={onChangeHandler} name="firstname" type="text" placeholder="Enter First Name" /> 
											{
												!(errorMsgs && errorMsgs.firstname && errorMsgs.firstname.validation) && <small className="errText">{errorMsgs && errorMsgs.firstname && errorMsgs.firstname.errMsg}</small>
											}

										</Col>
										<Col>
											<Form.Label className="mandatory">Last Name</Form.Label>
											<Form.Control maxLength={50} autoComplete="none" className={errStyle.lastnamestyle} type="text" value={formData.lastname} onChange={onChangeHandler} name="lastname" placeholder="Enter Last Name" />
											{
												!(errorMsgs && errorMsgs.lastname && errorMsgs.lastname.validation) && <small className="errText">{errorMsgs && errorMsgs.lastname && errorMsgs.lastname.errMsg}</small>
											}
										</Col>
									</Row>
								</Form.Group>
								<Form.Group controlId="companyName">
									<Form.Label className="mandatory">Company Name</Form.Label>
									<Form.Control maxLength={90} name="companyname" autoComplete="none" className={errStyle.companynamestyle} type="text" value={formData.companyname} onChange={onChangeHandler} placeholder="Enter Company Name" />
									{
										!(errorMsgs && errorMsgs.companyname && errorMsgs.companyname.validation) && <small className="errText">{errorMsgs && errorMsgs.companyname && errorMsgs.companyname.errMsg}</small>
									}
								</Form.Group>
								<Form.Group controlId="addprimarycontactno">
									<Form.Label className='mandatory'>Primary Contact Number</Form.Label>
									<Form.Control  maxLength={10} autoComplete="none" className={errStyle.contact1style} name="contact1" value={formData.contact1} onChange={onChangeHandler} type="text" placeholder="Enter Primary Contact Number" />
									{
										!(errorMsgs && errorMsgs.contact1 && errorMsgs.contact1.validation) && 
										<small className="errText">{errorMsgs && errorMsgs.contact1 && errorMsgs.contact1.errMsg}</small>
									}
								</Form.Group>
								<Form.Group controlId="addsecondarycontactno">
									<Form.Label className="">Secondary Contact Number</Form.Label>
									<Form.Control maxLength={10} autoComplete="none" className={errStyle.contact2style} name="contact2" value={formData.contact2} onChange={onChangeHandler} type="text" placeholder="Enter Secondary Contact Number" />
									{
										!(errorMsgs && errorMsgs.contact2 && errorMsgs.contact2.validation) && <small className="errText">{errorMsgs && errorMsgs.contact2 && errorMsgs.contact2.errMsg}</small>
									}
								</Form.Group>
								<Form.Group controlId="addemail">
									<Form.Label className="mandatory">Email</Form.Label>
									<Form.Control maxLength={90} autoComplete="none" 
									className={errStyle.emailstyle}
									 name="email" value={formData.email} onChange={onChangeHandler} type="text" placeholder="Enter Email" />
									{
										!(errorMsgs && errorMsgs.email && errorMsgs.email.validation) && <small className="errText">{errorMsgs && errorMsgs.email && errorMsgs.email.errMsg}</small>
									}
								</Form.Group>
								<Form.Group controlId="industry_type_id">
									<Form.Label className="mandatory">Job Category</Form.Label>
									{/* <Multiselect
										options={categoryList}
										displayValue="name"
										onSelect={onIndustrySelect}
										onRemove={onIndustryRemove}

									/> */}
									<Form.Control autoComplete="none" className={errStyle.industry_type_idstyle} name="industry_type_id" value={formData.industry_type_id} onChange={onChangeHandler} as="select">
										<option value=''>Select Job Category</option>
										{
											(categoryList!==null) && categoryList.map((category) => {
												return <option key={category.id} value={category.id}>{category.name}</option>
											})
										}
									</Form.Control>
									{
										!(errorMsgs && errorMsgs.industry_type_id && errorMsgs.industry_type_id.validation) && <small className="errText">{errorMsgs && errorMsgs.industry_type_id && errorMsgs.industry_type_id.errMsg}</small>
									}
								</Form.Group>
							</Col>
							<Col xl="6 p-4" lg="6" md="12">
							<Form.Group controlId="website">
									<Form.Label className="mandatory">Company Website</Form.Label>
									<Form.Control autoComplete="none" className={errStyle.websitestyle} name="website" value={formData.website} onChange={onChangeHandler} type="text" placeholder="Enter Website URL" />
									{
										!(errorMsgs && errorMsgs.website && errorMsgs.website.validation) && <small className="errText">{errorMsgs && errorMsgs.website && errorMsgs.website.errMsg}</small>
									}
								</Form.Group>
							<Form.Group controlId="corework">
									<Form.Label className="mandatory">Core Work Description</Form.Label>
									<Form.Control maxLength={300} autoComplete="none" className={errStyle.core_workstyle} name="core_work" value={formData.core_work} onChange={onChangeHandler} type="text" placeholder="Enter Core Work Description" />
									{
										!(errorMsgs && errorMsgs.core_work && errorMsgs.core_work.validation) && <small className="errText">{errorMsgs && errorMsgs.core_work && errorMsgs.core_work.errMsg}</small>
									}
								</Form.Group>
								<Form.Group controlId="account_status">
									<Form.Label className="mandatory">Account Status</Form.Label>
									<Form.Control autoComplete="none" className={errStyle.account_statusstyle} name="account_status" value={formData.account_status} onChange={onChangeHandler} as="select">
										<option value={""}>Select Account Status</option>
										{/* {
											categoryList.map((category) => {
												return <option key={category.id} value={category.id}>{category.name}</option>
											})
										} */}
										<option value={1}>Active</option>
										<option value={0}>Suspend</option>
									</Form.Control>
									{
										!(errorMsgs && errorMsgs.account_status && errorMsgs.account_status.validation) && <small className="errText">{errorMsgs && errorMsgs.account_status && errorMsgs.account_status.errMsg}</small>
									}
								</Form.Group>
								<Form.Group
								//  style={{display:"flex", justifyContent:"flex-end"}}
								>
								 <Button style={{ background: "white" }} className="text-primary" onClick={()=>{setFlow("ADD"); addressHandler();}}>
									<i className="fas fa-map-marked-alt mr-2"></i> ADD COMPANY LOCATION
							     </Button>
							<br />
							{
								<small className="errText">{locationErrMsg === "0"?"Location is required": null}</small>
									}
									{/* <font style={{background:"white"}} className="text-primary" onClick={addressHandler}>
							<i class="fas fa-plus"></i> ADD ADDRESS
							</font> */}
								</Form.Group>
								
								{
									address && address.map((item, idx) => {
										const { address_line1, address_line2, city, state, zip, country,landmark } = item
										return (
											<div key={idx} className={fadeout.id === idx && fadeout.status ? "fadeOut w3-animate-bottom" : "w3-animate-bottom"}>
												<FormGroup className="addressTag">
													<Form.Label style={{ width: "100%", display: "flex", justifyContent: "space-between" }}><strong><i class="fas fa-map-marker-alt mr-2"></i> Location {idx + 1}</strong>
														{/* <font
															onClick={() => {
																setFadeOut({
																	id: idx,
																	status: true
																})
																setTimeout(() => {
																	removeAddressTag(idx)
																	setFadeOut({
																		id: idx,
																		status: false
																	})
																}, 500)
															}}
														>&#10005;</font> */}
														<i className="fas fa-pencil-alt pointer"
														onClick={async ()=>{
															setFlow("EDIT")
															setSelectedLocation(item)
															setSelectedIdx(idx)
															setMapVisibility(true)
															}}
														></i>
													</Form.Label>
													<div>
														{address_line1 + "," + " " + landmark + "," + address_line2 + "," + " " + city + "," + " " + state + "," + " " + zip}
													</div>
												</FormGroup>
											</div>
										)
									})
								}

								<Form.Group className="mt-7 pt-1">
									<Row >
										<Col xl="4  ml-auto" lg="6 d-flex justify-content-center" md="6" sm="6" xs="12 mt-1">
											<Button
												// onClick={handleNext}
												className="btnres ripple "
												style={{
													background: "#7c4dff",
													color: "white",
												}}
												onClick={handleSubmit}
											>
												{
													workFlow === 'EDIT' ? "Update" : "Submit"
												}
											</Button>
										</Col>

										<Col xl="4 " lg="6 d-flex justify-content-center" md="6" sm="6" xs="12 mt-1">
											<Button
												onClick={() => {
													setTab("LIST")
													setWorkFlow("ADD")
													setAddress([])
												}}
												// disabled
												// style={{
												// 	background: "#ffebee",
												// 	color: "#f44336",
												// 	border: "1px solid #ef9a9a"
												// }}
												className="btnres ripple text-white" style={{
													background: "#f5365c",
													color: "white",
												}}>
												Cancel
                          			       </Button>
										</Col>
									</Row>
								</Form.Group>
							</Col>
						</Row>
					</Form>
				</CardBody>
			</Card>
		</>
	)
}

const mapStateToProps = state => {
	return {
		auth_token: state.login.data.auth_token
	}
}

export default connect(mapStateToProps)(AddEmployer)