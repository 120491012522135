import React from 'react'
// import AddEmployer from 'pages/master/add_employers/AddEmployer'
// import EmployerListPage from 'pages/master/EmployerListPage/EmployerListPage'
import Login from 'views/examples/Login'
import DashBoard from 'pages/dashboard/DashBoard'
import ShowCase from 'pages/ShowCase/ShowCase'
import {content} from './components/_helpers/Content'
import Master from 'pages/master/Master'
import Profile from 'views/examples/Profile'
import SkillsMaster from 'pages/master/SkillsMaster'
import Employer from 'pages/master/EmployerModule/Employer'
import JobModule from 'pages/main/job-module/JobModule'
import AppliedJobList from 'pages/main/job-module/AppliedJobList'
import Candidate from 'pages/master/candidates_module/CandidateMaster'
import ChangePassword from 'views/examples/ChangePassword'
import AssistanceNumber from 'pages/master/AssistanceNumber'
import IncompleteCandidateList from 'pages/master/candidates_module/IncompleteCandidateList'

const routes= [
    // {path:'/addEmployer/:id?', layout:'/admin', exact:true, name:'Add Employer', component: AddEmployer},
    // {path:'/employerList', layout:'/admin', exact:true, name:'EmployerList', component: EmployerListPage},
    {path:'/dashboard/:loggedIn', layout:'/admin', exact:true, name:'Dashboard', component:DashBoard},  
    {path:'/dashboard/', layout:'/admin', exact:true, name:'Dashboard', component:DashBoard},  
    {path:'/',layout:'/auth',exact:true, name:'Login', component:Login},
    {path:'/job',layout:'/admin',exact:true, name:'Job_Module', component:JobModule},
    {path:'/jobTypeMaster',layout:'/admin',exact:true, name:'Job_Type_Master', component:Master},
    {path:'/jobExperienceMaster', layout:'/admin', exact:true, name:'Job_Experience_Master', component:Master},
    {path:'/jobCategoryMaster', layout:'/admin', exact:true, name:'Job_Category_Master', component:Master},
    {path:'/skillsMaster', layout:'/admin', exact:true, name:'Skills_Master', component:Master},
    {path:'/jobStatus', layout:'/admin', exact:true, name:'Job_Status', component:Master},
    {path:'/languageMaster', layout:'/admin', exact:true, name:'Language_Master', component:Master},
    {path:'/notificationTypeMaster', layout:'/admin', exact:true, name:'Notification_Master', component:Master},
    {path:'/profile', layout:"/admin", exact:true, name:'profile', component:Profile},
    {path:'/employers/', layout:"/admin", exact:true, name:'JobList', component:Employer},
    {path:'/appliedJobList', layout:"/admin", exact:true, name:'appliedJobList', component:AppliedJobList},
    {path:'/candidateMaster',layout:"/admin", exact:true, name: 'candidates', component: Candidate},
    {path:'/qualificationMaster', layout:'/admin', exact:true, name:'Educational Qualification', component:Master},
    {path:'/changepassword',layout:'/admin', name:"changePassword", exact:true,  component:ChangePassword},
    {path:'/assistanceNumberMaster',layout:'/admin', name:"assistanceNumber", exact:true,  component:AssistanceNumber},
    {path:'/incompleteprofile',layout:'/admin', name:"incompleteProfile", exact:true,  component:IncompleteCandidateList},
    // {path:'/showcase', layout:'/admin', exact:true, name:'ShowCase', component:ShowCase}
]



export default routes