import KK_Toast from "components/Toast/KK_Toast";
import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { logout } from "redux/login/login.action";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
  Button,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import apis from "apis";
import { connect } from "react-redux";
import { validation } from "components/_helpers/validation";

const mapStateToProps = (state) => {
  return {
    pageNo: state.pagination.masterPageNo,
    auth_token: state.login.data.auth_token,
  };
};

const AssistanceNumber = ({ auth_token, logout }) => {
  const [assistanceDetails, setAssistanceDetails] = useState([]);
  const [enteredValue, setEnteredValue] = useState("");
  const [operableData, setOperableData] = useState("");
  const [inputClass, setInputClass] = useState("normal");
  const [toastConfig, setToastConfig] = useState({
    toastStatus: false,
    message: "",
    severity: null,
  });


// Get Assistance no Function for API Call
  const getAssistanceNumber = async (path) => {
    try {
      let res = await apis.masters.getAssistanceNumber(path);
      if (res.resultCode === 1) {
        setAssistanceDetails(res.resultData);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setToastConfig({
          toastStatus: true,
          message: "Session Expired!",
          severity: "error",
        });
        setTimeout(() => {
          logout();
        }, 2000);
      }
      console.error(err);
    }
  };

// Function for when user press update button for update assistance number API call
  const onSubmit = async (e) => {
    e.preventDefault()
    let path = "/api/v1/master/updateAssistanceNumber"
    if (enteredValue === undefined || enteredValue.length === 0) {
      return setInputClass("error");
    }
    // checking the entered value with existing value
    let getVal = assistanceDetails[0]?.assistance_number
      if(getVal === enteredValue){
        // return the error for stop execution of if statement when error occured
        return setToastConfig({
          toastStatus: true,
          message: "Nothing to update",
          severity: "error",
        });
      }
    if (enteredValue?.length !== 10) {
        return setToastConfig({
          toastStatus: true,
          message: "Assistance number must be 10 digit",
          severity: "error",
        });
      }
      let res = validation.onlyNumberCheck(enteredValue, 'enteredValue')
      console.log("validation here", res)
      if(res.validation === false){
        // return the error for stop execution of if statement when error occured
        return setToastConfig({
          toastStatus: true,
          message: "Enter valid assistance number",
          severity: "error",
        });
      }
      if(res.validation === true){
        let assistanceNumber = {"assistance_number":enteredValue}
          try {
            let res = await apis.masters.updateAssistanceNumber(auth_token,path,assistanceNumber);
            if (res.status === 200) {
            // setting entered value null to set empty input field
              setEnteredValue("")
              setOperableData("")
              setToastConfig({
                toastStatus: true,
                message: "Assistance number updated successfully",
                severity: "success",
              });
              getAssistanceNumber("/getAssistanceNumber");
            }
          } catch (err) {
            if (err.response && err.response.status === 401) {
              setToastConfig({
                toastStatus: true,
                message: "Session Expired!",
                severity: "error",
              });
              setTimeout(() => {
                logout();
              }, 2000);
            }
            console.error(err);
          }
      }
    // console.log("<=====END of the onSubmit Function======>")
  };

  useEffect(() => {
    //api call to populate the table as per the req in content object
    getAssistanceNumber("/getAssistanceNumber");
  }, []);

  const onChangeHandler = (e) => {
    setEnteredValue(e.target.value);
    if (e.target.value === undefined || e.target.value?.length === 0) {
      return setInputClass("error");
    }else{
      setInputClass("normal");
    }
  };
  const handleEnterkey = (e) => {
    if (e.key === "Enter") {
      onSubmit(e);
    }
  };
// Creating function to generate table data
  const generateTds = () => {
    let res = assistanceDetails.map((data, idy) => {
      let values = Object.values(data);
      return (
        <tr>
          {values.map((item, idx) => <td scope="col" key={idx}>{item}</td>)}
          <td className="text-center" key={idy}>
          <span style={{marginRight:"15px", cursor: "pointer", fontSize: "14px", color:"#6c63ff"}} name="EDIT" onClick={(e) => {setOperableData(data.assistance_number);setEnteredValue(data.assistance_number);}}> <i className="far fa-edit" aria-hidden="true"></i></span>
          </td>
        </tr>
      );
    });
    return res;
  };

  return (
    <div
      className="container text-center mt-8 mb-4"
      style={{ position: "relative" }}
    >
      <h2 style={{ color: "white" }} className="w3-animate-top">
        Update Assistance Number
      </h2>
      <KK_Toast setToastConfig={setToastConfig} toastConfig={toastConfig} />

      <div className="d-flex justify-content-center flex-column align-items-center pr-5 pl-5 ">
        <Col lg="7  w3-animate-left">
          <Card style={{ width: "100%" }}>
            <CardHeader className="pl-4 pt-2 pb-0">
              <Form.Label>
                <h2>Assistance Number</h2>
              </Form.Label>
            </CardHeader>
            <CardBody className="pt-3 pb-0 ">
              <form id="master-form">
                <Row >
                  <Col xs="9">
                  <Form.Group >
                    <Col xs="12 " md="12 pl-3" lg={"12 pl-3"} className="text-left">
                      <Form.Control 
                        type="text"
                        name="AssistanceNum"
                        placeholder="Assistance Number"
                        onChange={onChangeHandler}
                        onKeyPress={handleEnterkey}
                        value={enteredValue}
                        className={inputClass}
                        autoFocus={true}
                        maxLength={10}
                        disabled={operableData?false:true}
                      />
                       {inputClass === "error" && (
                        <small className="errText">
                          This field cannot be empty
                        </small>
                      )}
                    </Col>
                  </Form.Group>

                  </Col>
                  <Col
                    style={{ left: "10%", transform: "translateX(-50%)" }}
                    sm="3"
                    md="3"
                  >
                    <Button
                      name="UPDATE"
                      className="ripple"
                      onClick={onSubmit}
                      disabled={operableData?false:true}
                      style={{
                        background:
                          "linear-gradient(45deg, #7c4dff 30%, #b388ff  90%)",
                        color: "white",
                        height: "40px",
                        width: "100%",
                        minWidth: "100px",
                      }}
                    >
                      UPDATE
                    </Button>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>

        <>

            <div>
              <Table
                className="align-items-center table-condensed table-striped mt-6 w3-animate-bottom"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  width: "550px",
                  backgroundColor: "white",
                }}
                responsive
              >
                <thead className="thead-light">
                <tr>
                  <th scope="col">ID</th>
                  <th scope="col">Assistance Number</th>
                  <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                {generateTds()}
                </tbody>
              </Table>
              <Row className="pagination mt-2 mb-5 w3-animate-bottom  d-flex justify-content-center"></Row>
            </div>
        </>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
    return {
      logout: () => {
        dispatch(logout());
      },
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)(AssistanceNumber);