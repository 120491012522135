import React from 'react';
import './selectcomp.scss';
import Form from 'react-bootstrap/Form'

const SelectComponent = ({ className, style, children, value, name, onChange, key,placeholder }) => {
        console.log("VALUE ME",value)
    return (
        <>
            <select placeholder={placeholder} autoComplete="none" name={name} key={key} value={value} className={` custom-select + ${className}`} onChange={onChange} style={style} >
                {children}
            </select>
        </>
    );
};

export default SelectComponent;