import apis from 'apis';
import React, { useEffect, useState } from 'react';
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';
import { Row, Col } from 'reactstrap';
import './count-tag.scss'



const CountTag = ({ style, className, color, iconLink, apiLink, backGroundColor, children, imgsrc, header,dataCount }) => {
    
    // const auth_token = useSelector(state => state.login.data.auth_token)
    
    // useEffect(async () => {
    //     try{
    //         let totalEmpCount = await apis.dashboard.getEmployerCount(auth_token)
    //         if(totalEmpCount.success){
    //             setCountValue(totalEmpCount.data.employer_count)
    //         }
    //     }
    //     catch(err){
    //         console.error(err)
    //     }

    // }, [])

    return (
        <div className={"count-tag " + className}
            style={{
                backGroundColor: backGroundColor,
                ...style
            }}
        >
            <Row style={{ height: "100%" }}>
                <Col lg="9">
                    <h3 className="text-muted">{header.toUpperCase()}</h3>
                    <h1>
                        <CountUp style={{ color: color }}
                            start={0}
                            end={dataCount }
                            duration={2.5}
                            seperator=","
                        />
                    </h1>
                </Col>
                <Col className="d-flex align-items-center justify-content-center" lg="3">
                    <i className={iconLink} style={{ background: color, color: "white", borderRadius: "15%", padding: "8px" }}></i>
                </Col>
            </Row>
        </div>
    );
};

export default CountTag;