/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useSelector } from "react-redux";
import "./search-box.css";
const SearchBox = ({
  searchedText,
  setSearchedText,
  onChange,
  id,
  onSearch,
  getAllRecords,
  onKeyPress
}) => {
  const onClear = () => {
    setSearchedText("");
    getAllRecords();
  };


  return (
    <div className="input-main">
      {/* <img src={SearchLogo} className='img-1' /> */}
      <i className="fas fa-search img-1" onClick={onSearch}></i>
      <input
        type="text"
        id={id}
        placeholder="Search"
        value={searchedText}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
      {/* <img src={CancelLogo} className='img-2' /> */}
      <i className="fas fa-times fa-1.5x img-2" onClick={onClear}></i>
    </div>
  );
};
export default SearchBox;
