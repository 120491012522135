import {createStore, applyMiddleware } from 'redux'
import logger from "redux-logger";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import rootReducer from './root-reducer';


const persistConfig = {
    key:'root',
    storage: storage,
    whitelist:['login']
}


const pReducer = persistReducer(persistConfig,rootReducer);

const middleware = applyMiddleware(thunk,logger)
const store = createStore(pReducer,middleware)

const persistor =persistStore(store)

export {persistor, store};

