import EmployerActionType from './employer.action.type'

const INITIAL_STATE = {
    workFlow:"ADD",
    employerId:null
}


const employerReducer = (state= INITIAL_STATE,action)=>{
    switch(action.type){
        case EmployerActionType.SETEMPLOYERID:{
            return{
                ...state,
                workFlow: action.payload
            }
        }
        case EmployerActionType.SETWORKFLOW:{
            return{
                ...state,
                workFlow:action.payload
            }
        }
        default: 
        return state
    }
}

export default employerReducer