import loginActionTypes from "./login.action.types"



export const loginUserRequest = () =>{
    return{
        type: loginActionTypes.LOGIN_USER_REQUEST
    }
}

export const loginUserSuccess = (data) =>{
    return{
        type: loginActionTypes.LOGIN_USER_SUCCESS,
        payload: data
    }
    
}

export const loginUserFailure = (data) =>{
    return{
        type: loginActionTypes.LOGIN_USER_REQUEST,
        payload:data
    }
}

export const logout = (data) =>{
    return{
        type: loginActionTypes.LOGOUT
        
    }
}