/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import apis from 'apis'
import KK_Toast from 'components/Toast/KK_Toast';
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux';
import {
Card,
CardBody,
Form,
Row,
Col,
Button
} from 'reactstrap'
import { logout } from 'redux/login/login.action';

const AddEmployerForm = (logout) => {
	const [errorMsgs, setErrorMsgs] = useState()
	const [enteredValue, setEnteredValue] = useState(null)
	const [errorCheckresult,setErrorCheckResult] = useState(null)
	const auth_token = useSelector(state=>state.login.data.auth_token)

	const [toastConfig, setToastConfig] = useState({
		toastStatus: false,
		message: "",
		severity: null,
	})
	const [formData, setFormData] = useState({
		firstname: null,
		lastname: null,
		companyname: null,
		contact1: null,
		contact2: null,
		businesstype: null,
		address1: null,
		address2: null,
		email: null,
		city: null,
		state: null,
		pincode: null
	});

	const [errStyle, setErrStyle] = useState({
		// firstNameStyle: null,
		// lastNameStyle: null,
		// companyNameStyle: null,
		// contactNumber1Style: null,
		// contactNumber2Style: null,
		// emailStyle: null,
		// selectedBusinessTypeStyle: null,
		// addressLine1Style: null,
		// addressLine2Style: null,
	})

	const [fieldStyle, setFieldStyle] = useState(null)
	const [categoryList,setCategoryList] = useState([])

	useEffect(async()=>{
		try{
			let res = await apis.masters.getAllRecords('/api/v1/master/jobCategory')
			if(res.success){
				setCategoryList(res.data)
			}
		}
		catch(err){
			if(err.response&&err.response.status===401){
				setToastConfig({
					toastStatus:true,
					message: 'Session Expired!',
					severity:'error'
				})
				setTimeout(()=>{
					logout()
				},2000)
			}
			console.error(err)
		}
		console.log("auth_token",auth_token)
	},[])


	const handleSubmit = () => {
		scrollToTop()
		let resfirstname = validation.checkForEmpty(formData.firstname, "firstname")
		let reslastname = validation.checkForEmpty(formData.lastname, "reslastname")
		let rescompanyname = validation.checkForEmpty(formData.companyname, "companyname")
		let resbusinesstype = validation.checkForEmpty(formData.companyname, "business type")
		let resaddress1 = validation.checkForEmpty(formData.address1, "address")
		let resemail = validation.checkForEmpty(formData.email, "email")
		let rescity = validation.checkForEmpty(formData.city, "city")
		let resstate = validation.checkForEmpty(formData.state, "state")
		let respincode = validation.checkForEmpty(formData.pincode, "pincode")


		setErrorMsgs({
			...errorMsgs,
			firstname: { ...resfirstname },
			lastname: { ...reslastname },
			address1: { ...resaddress1 },
			email: { ...resemail },
			companyname: { ...rescompanyname },
			city: { ...rescity },
			state: { ...resstate },
			pincode: { ...respincode },
			businesstype: { ...resbusinesstype }
		})

		setErrStyle({
			...errStyle,
			firstnamestyle: (resfirstname.validation === false) ? "error" : "success",
			lastnamestyle: (reslastname.validation === false) ? "error" : "success",
			address1style: (resaddress1.validation === false) ? "error" : "success",
			emailstyle: (resemail.validation === false) ? "error" : "success",
			companynamestyle: (rescompanyname.validation === false) ? "error" : "success",
			citystyle: (rescity.validation === false) ? "error" : "success",
			statestyle: (resstate.validation === false) ? "error" : "success",
			pincodestyle: (respincode.validation === false) ? "error" : "success",
			businesstypestyle: (resbusinesstype.validation === false) ? "error" : "success"
		})

		const {firstname,lastname,companyname,contact1,contact2,address2,email, businesstype, address1,city,state,pincode} = formData
		console.log(!!(firstname&&lastname&&companyname&&email&&businesstype&&address1&&city&&state&&pincode))
		if((!!firstname&&lastname&&companyname&&email&&businesstype&&address1&&city&&state&&pincode)){
			let body= {
				first_name: firstname,
				last_name: lastname,
				company_name: companyname,
				email: email,
				contact_number1:contact1,
				bussiness_type : businesstype,
				address_line1: address1,
				city: city,
				state: state,
				zip: pincode
			}
			if(contact2) body.contact_number2 = contact2
			if(address2) body.address_line2 = address2

			createEmployer(auth_token,body)
			clearHandler()
		}
		}

		const scrollToTop = () => {
			window.scrollTo(0, 0);
		}
	

	const createEmployer = async (body,auth_token) =>{
		try{
			let res= await apis.masters.createRecordRequest(auth_token,'/api/v1/master/employer',body)
			if(res.success){
				scrollToTop()
				setToastConfig({
					toastStatus: true,
					message: res.message,
					severity: "success"
				})
			}else{
				scrollToTop()
				setToastConfig({
					toastStatus: true,
					message: res.message,
					severity: "error"
				})
			}
		}
		catch(err){
			scrollToTop()
			if(err.response&&err.response.status===401){
				setToastConfig({
					toastStatus:true,
					message: 'Session Expired!',
					severity:'error'
				})
				setTimeout(()=>{
					logout()
				},2000)
			}
			setToastConfig({
				toastStatus: true,
				message: err.message,
				severity: "error"
			})
			console.error(err)
		}
	}


	const onChangeHandler = (e) => {

		switch (e.target.name) {
			case "firstname": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})		
				const res = validation.onlyLettersCheck(e.target.value, e.target.name)
				console.log("Response", res)
				if (res.validation === true) {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: "success"
					})
					setErrorMsgs({
						...errorMsgs,
						[e.target.name]: {
							...res
						}
					})
					return setFormData({
						...formData,
						[e.target.name]: e.target.value
					});
				} else {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: "error"
					})
					return setErrorMsgs({
						...errorMsgs,
						[e.target.name]: {
							...res
						}
					})
				}
			}
			case "lastname": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				const res = validation.onlyLettersCheck(e.target.value, e.target.name)
				console.log("Response", res)
				if (res.validation === true) {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: "success"
					})

					setErrorMsgs({
						...errorMsgs,
						[e.target.name]: {
							...res
						}
					})
					return setFormData({
						...formData,
						[e.target.name]: e.target.value
					});
				} else {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: "error"
					})
					return setErrorMsgs({
						...errorMsgs,
						[e.target.name]: {
							...res
						}
					})
				}

			}

			case "companyname": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				return setFormData({
					...formData,
					[e.target.name]: e.target.value
				});
			}

			case "contact1": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				let res = validation.onlyNumberCheck(e.target.value)
				console.log("Number Res", res)
				if (res.validation === true) {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: "success"
					})

					return setFormData({
						...formData,
						[e.target.name]: e.target.value
					});
				} else {
					setErrorMsgs({
						...errorMsgs,
						[e.target.name]: { ...res }
					})
					 setErrStyle({
						...errStyle,
						[e.target.name + "style"]: "error"
					})
					return setFormData({
						...formData,
						[e.target.name]: e.target.value
					});
				}
			}
			case "contact2": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				let res = validation.onlyNumberCheck(e.target.value)
				console.log("Number Res", res)
				if (res.validation === true) {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: "success"
					})

					return setFormData({
						...formData,
						[e.target.name]: e.target.value
					});
				} else {
					setErrorMsgs({
						...errorMsgs,
						[e.target.name]: { ...res }
					})
					 setErrStyle({
						...errStyle,
						[e.target.name + "style"]: "error"
					})
					return setFormData({
						...formData,
						[e.target.name]: e.target.value
					});
				}
			}
			case "email": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				let res = validation.emailCheck(e.target.value)
				console.log("Email Res", res)
				if (res.validation === true) {
					setErrStyle({
						...errStyle,
						[e.target.name + "style"]: "success"
					})
					return setFormData({
						...formData,
						[e.target.name]: e.target.value
					});
				} else {
					setErrorMsgs({
						...errorMsgs,
						[e.target.name]: {
							...res
						}
					})
					return setErrStyle({
						...errStyle,
						[e.target.name + "style"]: "error"
					})
				}
			}
			case "businesstype": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				return setFormData({
					...formData,
					[e.target.name]: e.target.value
				});
			}
			case "address1": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				return setFormData({
					...formData,
					[e.target.name]: e.target.value
				});
			}
			case "address2": {
				return setFormData({
					...formData,
					[e.target.name]: e.target.value
				});
			}
			case "city": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				return setFormData({
					...formData,
					[e.target.name]: e.target.value
				});
			}
			case "state": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				return setFormData({
					...formData,
					[e.target.name]: e.target.value
				});
			}
			case "pincode": {
				setErrStyle({
					...errStyle,
					[e.target.name + "style"]: null
				})
				setErrorMsgs({
					...errorMsgs,
					[e.target.name]: null
				})
				return setFormData({
					...formData,
					[e.target.name]: e.target.value
				});
			}
		}

	}

	const clearHandler = () => {
		setErrStyle({})
		setErrorMsgs({})
		setFormData({
			firstname: null,
			lastname: null,
			companyname: null,
			contact1: null,
			contact2: null,
			businesstype: null,
			address1: null,
			address2: null,
			email: null,
			city: null,
			state: null,
			pincode: null
		})
		document.getElementById("myform").reset();
	}

	useEffect(() => {
		
	}, [errorMsgs])

	const validation = {
		onlyLettersCheck(testValue, testFieldName) {
			if (testValue.match("^[a-zA-Z ]*$") != null) {
				return { validation: true }

			} else {
				return {
					validation: false,
					errMsg: "Only Letters are allowed"
				}
			}
		},

		onlyNumberCheck(testValue) {
			if (testValue.match("^[0-9]{12}$") != null) {
				return { validation: true }

			} else {
				return {
					validation: false,
					errMsg: "Enter valid phone number"
				}
			}
		},

		checkForEmpty(testValue, testFieldName) {
			if (testValue && testValue !== null && testValue.length > 0) {
				return { validation: true }
			}
			else {
				return {
					validation: false,
					errMsg: `${testFieldName} is Required`
				}
			}
		},

		emailCheck(testValue) {
			if (testValue.match("[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$") != null) {
				return { validation: true }

			} else {
				return {
					validation: false,
					errMsg: "Enter Valid E-mail"
				}
			}
		}
	}

	// const errormsggenerator=(name) =>{
	// 	return (
	// 		!(errorMsgs && errorMsgs[name] && errorMsgs[name].validation) && <small className="errText">{errorMsgs && errorMsgs[name] && errorMsgs[name].errMsg}</small>
	// 	)
	// }

	useEffect(() => {
		console.log(formData)
	}, [formData])

	useEffect(() => {


	}, [errorMsgs])

	useEffect(() => {
		console.log("errStyle", errStyle)
	}, [errStyle])

	
	return (
		<React.Fragment>
		<KK_Toast setToastConfig={setToastConfig} toastConfig={toastConfig} />
		
		<Col xl="5" lg="5" md="7" sm="7" xs="12">
					<Card style={{

					}} className="addEmployer">
						{/* <CardHeader>
						
					</CardHeader> */}
						<CardBody >
							<Form id="myform">
								<Form.Group controlId="name">
									<Row>
										<Col>
											<Form.Label className="mandatory">First Name</Form.Label>
											<Form.Control className={errStyle.firstnamestyle} value={formData.firstname} autoComplete="off" onChange={onChangeHandler} name="firstname" type="text" placeholder="Enter First Name" />
											{
												!(errorMsgs && errorMsgs.firstname && errorMsgs.firstname.validation) && <small className="errText">{errorMsgs && errorMsgs.firstname && errorMsgs.firstname.errMsg}</small>
											}

										</Col>
										<Col>
											<Form.Label className="mandatory">Last Name</Form.Label>
											<Form.Control className={errStyle.lastnamestyle} type="text" value={formData.lastname} autoComplete="off" onChange={onChangeHandler} name="lastname" placeholder="Enter Last Name" />
											{
												!(errorMsgs && errorMsgs.lastname && errorMsgs.lastname.validation) && <small className="errText">{errorMsgs && errorMsgs.lastname && errorMsgs.lastname.errMsg}</small>
											}
										</Col>
									</Row>
								</Form.Group>
								<Form.Group controlId="companyName">
									<Form.Label className="mandatory">Company Name</Form.Label>
									<Form.Control className={errStyle.companynamestyle} type="text" value={formData.companyname} onChange={onChangeHandler} autoComplete="off" name="companyname" placeholder="Enter Company Name" />
									{
										!(errorMsgs && errorMsgs.companyname && errorMsgs.companyname.validation) && <small className="errText">{errorMsgs && errorMsgs.companyname && errorMsgs.companyname.errMsg}</small>
									}
								</Form.Group>
								<Form.Group controlId="addprimarycontactno">
									<Form.Label className='mandatory'>Contact Number 1</Form.Label>
									<Form.Control className={errStyle.contact1style} name="contact1" value={formData.contact1} onChange={onChangeHandler} type="text" autoComplete="off" placeholder="Enter First Contact" />
									{
										!(errorMsgs && errorMsgs.contact1 && errorMsgs.contact1.validation) && <small className="errText">{errorMsgs && errorMsgs.contact1 && errorMsgs.contact1.errMsg}</small>
									}
								</Form.Group>
								<Form.Group controlId="addsecondarycontactno">
									<Form.Label>Contact Number 2</Form.Label>
									<Form.Control className={errStyle.contact2style} name="contact2" value={formData.contact2} onChange={onChangeHandler} type="text" autoComplete="off" placeholder="Enter Second Contact" />
									{
										!(errorMsgs && errorMsgs.contact2 && errorMsgs.contact2.validation) && <small className="errText">{errorMsgs && errorMsgs.contact2 && errorMsgs.contact2.errMsg}</small>
									}
								</Form.Group>
								<Form.Group controlId="addemail">
									<Form.Label className="mandatory">E-mail</Form.Label>
									<Form.Control className={errStyle.emailstyle} name="email" value={formData.email} onChange={onChangeHandler} type="text" autoComplete="off" placeholder="Enter Email" />
									{
										!(errorMsgs && errorMsgs.email && errorMsgs.email.validation) && <small className="errText">{errorMsgs && errorMsgs.email && errorMsgs.email.errMsg}</small>
									}
								</Form.Group>
								<Form.Group controlId="businessType">
									<Form.Label className="mandatory">Business Type</Form.Label>
									<Form.Control className={errStyle.businesstypestyle} name="businesstype" value={formData.businessType} onChange={onChangeHandler} as="select">
										<option value={null}>Select Business Type</option>
										{
											categoryList.map(category=>{
												return <option value={category.value}>{category.name}</option>
											})
										}
									</Form.Control>
									{
										!(errorMsgs && errorMsgs.businesstype && errorMsgs.businesstype.validation) && <small className="errText">{errorMsgs && errorMsgs.businesstype && errorMsgs.businesstype.errMsg}</small>
									}
								</Form.Group>
								<Form.Group controlId="address1">
									<Form.Label className="mandatory">Address Line 1</Form.Label>
									<Form.Control name="address1" className={errStyle.address1style} onChange={onChangeHandler} type="text" autoComplete="off" placeholder="Enter Address Line 1" />
									{
										!(errorMsgs && errorMsgs.address1 && errorMsgs.address1.validation) && <small className="errText">{errorMsgs && errorMsgs.address1 && errorMsgs.address1.errMsg}</small>
									}
								</Form.Group>
								<Form.Group controlId="address2">
									<Form.Label>Address Line 2</Form.Label>
									<Form.Control name="address2" onChange={onChangeHandler} type="text" autoComplete="off" placeholder="Enter Address Line 2" />
									{
										!(errorMsgs && errorMsgs.address2 && errorMsgs.address2.validation) && <small className="errText">{errorMsgs && errorMsgs.address2 && errorMsgs.address2.errMsg}</small>
									}
								</Form.Group>
								<Form.Group controlId="city">
									<Form.Label className="mandatory">City</Form.Label>
									<Form.Control name="city" onChange={onChangeHandler} type="text" className={errStyle.citystyle} autoComplete="off" placeholder="Enter City" />
									{
										!(errorMsgs && errorMsgs.city && errorMsgs.city.validation) && <small className="errText">{errorMsgs && errorMsgs.city && errorMsgs.city.errMsg}</small>
									}
								</Form.Group>
								<Form.Group controlId="state">
									<Form.Label className="mandatory">State</Form.Label>
									<Form.Control name="state" className={errStyle.statestyle} onChange={onChangeHandler} type="text" autoComplete="off" placeholder="Enter State" />
									{
										!(errorMsgs && errorMsgs.state && errorMsgs.state.validation) && <small className="errText">{errorMsgs && errorMsgs.state && errorMsgs.state.errMsg}</small>
									}
								</Form.Group>
								<Form.Group controlId="pincode">
									<Form.Label className="mandatory">Pin Code</Form.Label>
									<Form.Control name="pincode" className={errStyle.pincodestyle} onChange={onChangeHandler} type="text" autoComplete="off" placeholder="Enter Pin Code" />
									{
										!(errorMsgs && errorMsgs.pincode && errorMsgs.pincode.validation) && <small className="errText">{errorMsgs && errorMsgs.pincode && errorMsgs.pincode.errMsg}</small>
									}
								</Form.Group>
							</Form>
							<Row>
								<Col lg="6 d-flex justify-content-center" md="6" sm="6 d-flex justify-content-end" xs="12 mt-1">
									<Button
										onClick={clearHandler}
										// disabled
										className="btnres">
										Clear
                                            </Button>

								</Col>

								<Col lg="6 d-flex justify-content-center" md="6" sm="6 d-flex justify-content-start" xs="12 mt-1" >

									<Button
										// onClick={handleNext}
										className="ripple"
										style={{
											background: 'linear-gradient(45deg, #7c4dff 30%, #b388ff  90%)',
											color: "white",
											height: "40px",
											width: "auto",
											minWidth: "70px"
										}}
										onClick={handleSubmit}
									>
										{'Submit'}
									</Button>

								</Col>
							</Row>
						</CardBody>
					</Card>
				</Col>
				</React.Fragment>
	);
};

const mapDispatchToProps = dispatch =>{
	return {
		logout: ()=>{dispatch(logout())}
	}
}

export default connect(null,mapDispatchToProps)(AddEmployerForm);