import { Button } from 'reactstrap';
import React from 'react';


const CustomFileInput = ({onChange,id,accept}) =>{
    return(<div>
        <Button className="bg-green text-white" onClick={()=>{
            document.getElementById(id).click();
        }}  style={{ fontWeight:400}} >
        <i className="fas fa-paperclip mr-2"></i>
            Attach document
        </Button>
        <input id={id} accept={accept} onChange={onChange} className="invisible" type="file" />
        </div>
    )
}

export default CustomFileInput