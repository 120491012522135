/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import EmployerListTable from "views/EmployerListTable/EmployerListTable";
import Pagination from "../../../../components/pagination/Pagination";
import Pagination2 from "../../../../components/pagination/Pagination2";

import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Button,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import apis from "apis";
import AddEmployerForm from "views/AddEmployerForm/AddEmployerForm";
import KK_Toast from "components/Toast/KK_Toast";
import { connect, useSelector } from "react-redux";
import { incrementPageNo } from "redux/pagination/pagination.action";
import { decrementPageNo } from "redux/pagination/pagination.action";
import EmptyTablePlaceHolder from "components/EmptyTableReplacement.js/EmptyTablePlaceHolder";
import { logout } from "redux/login/login.action";
import SearchBox from "components/SearchBox/SearchBox";
import { ConvertToExcel } from "components/excel/CovertToExcel";

const EmployerListPage = ({
  setEmployerId,
  setToastConfig,
  setTab,
  setWorkFlow,
  pageNo,
  nextPage,
  previousPage,
  auth_token,
  logout,
}) => {
  let history = useHistory();
  const [tableData, setTableData] = useState([]);
  const [deleteId, setDeleteId] = useState();
  const [nextPageStatus, setNextPageStatus] = useState(false);
  const [status, setStatus] = useState(null);
  const [loader, setLoader] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [rowTableData, setRowTableData] = useState([]);
  const [allEmpoyerData, setAllEmpoyerData] = useState([]);
  const [exportEmpData, setExportEmpData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const recordsPerPage = 10;
  const [pageOffset, setPageOffset] = useState({
    start: 0,
    end: recordsPerPage - 1,
  });
  const [totalPages, setTotalPages] = useState(0);

  const onChangeNext = () => {
    setPageOffset({
      start: pageOffset.start + recordsPerPage,
      end: pageOffset.end + recordsPerPage,
    });
    console.log("Length", totalPages);

    setPageNumber(pageNumber + 1);
  };

  const onChangePrev = () => {
    setPageOffset({
      start: pageOffset.start - recordsPerPage,
      end: pageOffset.end - recordsPerPage,
    });

    setPageNumber(pageNumber - 1);
  };

  const onSearch = (e) => {
    setSearchedText(e.target.value);
  };

  const getSearchedRecords = async (searchedText) => {
    let body = {
      searched_text: searchedText,
    };
    let searchedList = await apis.masters.getPublicSearchedRecords(
      "/api/V1/master/employerSearch",
      body
    );
    console.log("SEARCHEDLIST", searchedList);
    setPageNumber(1);
    setPageOffset({
      start: 0,
      end: recordsPerPage - 1,
    });
    let remainder = searchedList.data.length % recordsPerPage;
    let limit = parseInt(searchedList.data.length / recordsPerPage);
    if (remainder !== 0) {
      limit = limit + 1;
    }
    setTotalPages(limit);
    // setPageNumber(0);
    setNextPageStatus(false);
    setRowTableData(searchedList.data);
  };

  // useEffect(() => {
  //   console.log("SearchedText", searchedText);
  // }, [searchedText]);

  // useEffect(async () => {
  //   setLoader(true);
  //   console.log("pageNo IN USEeffect", pageNo);
  //   try {
  //     let res = await apis.masters.getAllEmployer(
  //       auth_token,
  //       `/api/V1/master/employer`
  //     );
  //     if (res.data.length > 0) {
  //       let remainder = res.data.length % recordsPerPage;
  //       let limit = parseInt(res.data.length / recordsPerPage);
  //       if (remainder !== 0) {
  //         limit = limit + 1;
  //       }
  //       setTotalPages(limit);
  //       setLoader(false);
  //       setNextPageStatus(true);
  //     } else {
  //       setLoader(false);
  //       setNextPageStatus(false);
  //     }
  //     console.log(res);
  //   } catch (err) {
  //     setLoader(false);
  //     if (err.response && err.response.status === 401) {
  //       setToastConfig({
  //         toastStatus: true,
  //         message: "Session Expired!",
  //         severity: "error",
  //       });
  //       setTimeout(() => {
  //         logout();
  //       }, 2000);
  //     }
  //   }
  //   getAllRecords();
  // }, []);

  useEffect(() => {
    getAllRecords();
  }, []);

  // const generateThs = () => {
  //   let res = tableData
  //     .filter((data, idx) => idx < 1)
  //     .map((data) => {
  //       let values = Object.getOwnPropertyNames(data);
  //       console.log("TH values", values);
  //       return values.map((item) => {
  //         return (
  //           <th className="text-white" scope="col">
  //             {item}
  //           </th>
  //         );
  //       });
  //     });
  //   return res;
  // };

  const generateTds = () => {
    //    let tdList=[]
    //    let res= []
    let res =
      tableData &&
      tableData
        .filter((i, idx) => idx >= pageOffset.start && idx <= pageOffset.end)
        .map((data) => {
          let values = Object.values(data);
          return (
            <tr>
              {values.map((item) => {
                return (
                  <>
                    <td>
                      {item === "1" || item === "0" || item === "Active"
                        ? item === "1" || item === "Active"
                          ? "Active"
                          : "Inactive"
                        : item}
                    </td>
                  </>
                );
              })}
              <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    // href="#pablo"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    {data.is_active === 0 ||
                    data.is_active === "Inactive" ? null : (
                      <DropdownItem
                        name="EDIT"
                        onClick={(e) => {
                          setEmployerId(data.id);
                          setTab("ADD");
                          setWorkFlow("EDIT");
                        }}
                      >
                        Edit
                      </DropdownItem>
                    )}
                    <DropdownItem
                      name="updateStatus"
                      onClick={() => {
                        changeStatus(data.id, data.is_active);
                      }}
                    >
                      {data.is_active === 0 || data.is_active === "Inactive"
                        ? "Activate"
                        : "Deactivate"}
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          );
        });
    return res;
  };

  const changeStatus = async (deleteId, status) => {
    console.log(deleteId);
    let tempStatus = 0;
    if (deleteId && status) {
      if (status === 0 || status === "Inactive") {
        tempStatus = 1;
      }
      try {
        let res = await apis.masters.updateStatus(
          auth_token,
          "/api/v1/master/employer",
          deleteId,
          tempStatus
        );
        if (res.success) {
          scrollToTop();
          setToastConfig({
            toastStatus: true,
            message: res.message,
            severity: "success",
          });
          return getAllRecords();
        } else {
          scrollToTop();
          return setToastConfig({
            toastStatus: true,
            message: res.message,
            severity: "error",
          });
        }
      } catch (err) {
        scrollToTop();
        setToastConfig({
          toastStatus: true,
          message: err.message,
          severity: "error",
        });
        console.error(err);
      }
    }
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  // Remove industry id from table data and show is_active === 1 ? Active : Inactive
  const modifyTableData = () => {
    // setRowTableData(tableData)
    let arr = [];
    rowTableData &&
      rowTableData.map((data) => (
        <>
          {arr.push({
            id: data.id,
            first_name: data.first_name,
            last_name: data.last_name,
            company_name: data.company_name,
            contact_number1: data.contact_number1,
            contact_number2: data.contact_number2,
            email: data.email,
            job_category: data.industry_name,
            is_active: data.is_active === "1" ? "Active" : "Inactive",
          })}
        </>
      ));
    setTableData(arr);
  };
  useEffect(() => {
    modifyTableData();
  }, [rowTableData]);

  const getAllRecords = async () => {
    setLoader(true);
    setPageNumber(pageNo);
    try {
      let res = await apis.masters.getAllEmployer(
        auth_token,
        `/api/v1/master/employerpage/${pageNo === 0 ? "0" : pageNo}`
      );
      if (res.success) {
        setPageNumber(1);
        setPageOffset({
          start: 0,
          end: recordsPerPage - 1,
        });
        let remainder = res.data.length % recordsPerPage;
        let limit = parseInt(res.data.length / recordsPerPage);
        if (remainder !== 0) {
          limit = limit + 1;
        }
        setTotalPages(limit);
        // setting up data to setRowTableData state for remove industry id fron object array
        // setTableData(res.data);
        setRowTableData(res.data);
        setLoader(false);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setToastConfig({
          toastStatus: true,
          message: "Session Expired!",
          severity: "error",
        });
        setTimeout(() => {
          logout();
        }, 2000);
      }
      setLoader(false);
      console.error(err);
    }
    // For Export all Employer data
    try {
      let res = await apis.masters.getAllEmployer(
        auth_token,
        `/api/v1/master/getAllEmployerList`
      );
      if (res.success) {
        setAllEmpoyerData(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  allEmpoyerData.forEach(function (x) {
    delete x.industry_type_id;
  });
  // This function used when press enter key to Search data in list
  const handleEnterkeySearch = (e, searchedText) => {
    if (e.key === "Enter") {
      getSearchedRecords(searchedText);
    }
  };
  const exportEmployerData = () => {
    let resultData = [];
    console.log("allEmpoyerData",allEmpoyerData)
    allEmpoyerData && allEmpoyerData.map((item) => {
      <>
      {
        resultData.push({
          "Id" : item.id,
          "First Name": item.first_name,
          "Last Name": item.last_name,
          "Company Name": item.company_name,
          "Primary Contact": item.contact_number1,
          "Secondary Contact": item.contact_number2,
          "Email ID": item.email,
          "Job Category": item.industry_name,
          "Status": item.is_active === "1" ? "Active" : "Inactive"
        })
      }
      </>
    })
    setExportEmpData(resultData)
  }
  useEffect(() => {
    exportEmployerData();
  },[allEmpoyerData])

  return (
    <>
      {loader ? (
        <Spinner
          style={{ width: "3rem", height: "3rem", color: "whitesmoke" }}
        />
      ) : (
        <>
          <Row className=" w-100 p-0 m-0">
            <Col className=" p-0 m-0">
              <SearchBox
                getAllRecords={getAllRecords}
                id="employerSearch"
                onSearch={() => {
                  getSearchedRecords(searchedText);
                }}
                onKeyPress={(e) => {
                  handleEnterkeySearch(e,searchedText);
                }}
                searchedText={searchedText}
                setSearchedText={setSearchedText}
                onChange={onSearch}
              />
            </Col>
            <Col className=" p-0 m-0 d-flex justify-content-end align-items-center">
              <ConvertToExcel
                style={{}}
                dataToCovert={exportEmpData}
                filename="employerList.csv"
                btnName="Download Employer List"
              />
            </Col>
          </Row>

          {tableData && tableData.length > 0 ? (
            <>
              <Table
                className="align-items-center table-condensed table-striped  w3-animate-bottom"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  // width: "550px",
                  backgroundColor: "white",
                }}
                responsive
              >
                <thead className="thead-dark">
                  {/* {generateThs()} */}
                  <th className="text-white" scope="col">
                    ID
                  </th>
                  <th className="text-white" scope="col">
                    FIRST NAME
                  </th>
                  <th className="text-white" scope="col">
                    LAST NAME
                  </th>
                  <th className="text-white" scope="col">
                    COMPANY NAME
                  </th>
                  <th className="text-white" scope="col">
                    PRIMARY CONTACT
                  </th>
                  <th className="text-white" scope="col">
                    SECONDARY CONTACT
                  </th>
                  <th className="text-white" scope="col">
                    EMAIL
                  </th>
                  <th className="text-white" scope="col">
                    JOB CATEGORY
                  </th>
                  <th className="text-white" scope="col">
                    STATUS
                  </th>
                  <th className="text-white" scope="col">
                    ACTIONS
                  </th>
                </thead>
                <tbody>{generateTds()}</tbody>
              </Table>
              {/* <Pagination
                nextPageStatus={nextPageStatus}
                length={tableData && tableData.length}
                nextPage={nextPage}
                name="empPageNo"
                getAllRecords={getAllRecords}
                previousPage={previousPage}
                pageNo={pageNumber}
              /> */}
              <Pagination2
                style={{ marginLeft: "auto", marginRight: "auto" }}
                pageNumber={pageNumber}
                pageOffset={pageOffset}
                length={totalPages}
                onChangeNext={onChangeNext}
                onChangePrev={onChangePrev}
                recordsPerPage={recordsPerPage}
              />
            </>
          ) : (
            <EmptyTablePlaceHolder>No Employer Found</EmptyTablePlaceHolder>
          )}
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pageNo: state.pagination.empPageNo,
    auth_token: state.login.data.auth_token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
    nextPage: (item) => {
      dispatch(incrementPageNo(item));
    },
    previousPage: (item) => {
      dispatch(decrementPageNo(item));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmployerListPage);
