// Local Environment
//  //========== WEB API ============>
// export const API_SERVER_URL = "http://127.0.0.1:4000"; // http://ktspldemo.co.in:4000
// ========== Mobile API ============>
// export const API_SERVER_URL2 = "http://127.0.0.1:5050"; // http://ktspldemo.co.in:5050


// Development Environment
 //========== WEB API ============>
// export const API_SERVER_URL = "http://35.184.67.30:4000"; // http://ktspldemo.co.in:4000
// // // // // // // ========== Mobile API ============>
// export const API_SERVER_URL2 = "http://35.184.67.30:5050"; // http://ktspldemo.co.in:5050



// Production Environment (LIVE ON GCP)
// ========== WEB API ============> 
export const API_SERVER_URL = "https://apiweb.kaamkaz.com"; // "http://35.224.211.184:4000"
// ========== Mobile API ============>
export const API_SERVER_URL2 = "https://apimob.kaamkaz.com"; // "http://35.224.211.184:5050"
