import React, { useEffect, useRef, useState, useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Form from "react-bootstrap/Form";
// reactstrap components
import {
  // Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Row,
  Col,
} from "reactstrap";
import { useHistory } from "react-router";
import KK_Toast from "components/Toast/KK_Toast";
import apis from "apis";
import { loginUserRequest } from "redux/login/login.action";
import { loginUserSuccess } from "redux/login/login.action";
import { loginUserFailure } from "redux/login/login.action";
import { connect, useSelector } from "react-redux";
import { InputGroup } from "react-bootstrap";
import { validation } from "components/_helpers/validation";
// import { logout } from "redux/login/login.action";
import { logout } from "redux/login/login.action";

const ChangePassword = ({
  loginRequest,
  loginSuccess,
  loginFailure,
  loading,
  user_id,
  logout,
}) => {
  const [toastConfig, setToastConfig] = useState({
    toastStatus: false,
    message: "",
    severity: null,
  });
  const authToken = useSelector((state) => state.login.data.auth_token);

  const [formInput, setFormInput] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [oldPasswordError, setOldPasswordError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [confirmPasswordError, setConfirmPasswordError] = useState(null)

  const handleUserInput = (name, value) => {
    if (name === "oldPassword" && value.length <= 0) {
      setOldPasswordError("Old Password should not be empty")
      // return;
    }else{
      setFormInput({
        ...formInput,
        [name]: value,
      });
      setOldPasswordError(null)
    }

    if(name === "password" && value.includes(" ") === true){
      // console.log(value);
     setPasswordError("Spaces are not allowed")
     return
    }else{
      setFormInput({
        ...formInput,
        [name]: value,
      });
      setPasswordError(null)
    }

    if (name === "password" && value.length < 8) {
      setPasswordError("New password must be minimum 8 characters")
      return;
    }else{
      setFormInput({
        ...formInput,
        [name]: value,
      });
      setPasswordError(null)
    }

    if (name === "password" && value.length <= 0) {
      setPasswordError("Password should not be empty")
      return;
    }else{
      setFormInput({
        ...formInput,
        [name]: value,
      });
      setPasswordError(null)
    }

    if (name === "confirmPassword" && value !== formInput.password) {
      setConfirmPasswordError("New password and confirm new password should be same")
      return;
    }else{
      setFormInput({
        ...formInput,
        [name]: value,
      });
      setConfirmPasswordError(null)
    }
  };

  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");
  const [passwordType3, setPasswordType3] = useState("password");

  // console.log("length-====", formInput.password.length);

  console.log("userid==>", user_id);

  const useStyles = makeStyles({
    cancel: {
      background: "linear-gradient(45deg, #f44336 30%, #ef9a9a  90%)",
      border: 0,
      borderRadius: 10,
      boxShadow: "0 3px 5px 2px #b488ff83",
      color: "white",
      height: 38,
      padding: "0 30px",
    },
    root: {
      background: "linear-gradient(45deg, #7c4dff 30%, #b388ff  90%)",
      border: 0,
      borderRadius: 10,
      boxShadow: "0 3px 5px 2px #b488ff83",
      color: "white",
      height: 38,
      padding: "0 30px",
    },
  });

  const classes = useStyles();

  const onSubmit = async (event) => {
    let data = {
      old_password: formInput.oldPassword,
      new_password: formInput.confirmPassword,
    };
    console.log("data====>", data);
    event.preventDefault();

    if (formInput.oldPassword?.length <= 0) {
      setOldPasswordError("Old Password should not be empty")
      return;
    }else{
      setOldPasswordError(null)
    }

    if(formInput.password?.includes(" ")){
      console.log(formInput.password);
     setPasswordError("Spaces are not allowed")
     return
    }else{
      setPasswordError(null)
    }

    if (formInput.password?.length < 8) {
      setPasswordError("New password must be minimum 8 characters")
      return;
    }else{
      setPasswordError(null)
    }

    if (!formInput.password || formInput.password?.length <= 0) {
      setPasswordError("Password should not be empty")
      // return;
    }else{
      setPasswordError(null)
    }

    if (formInput.confirmPassword?.length <= 0 || formInput.password !== formInput.confirmPassword) {
      setConfirmPasswordError("New password and confirm new password should be same")
      // return;
    }else{
      setConfirmPasswordError(null)
    }
    if (formInput.confirmPassword !== formInput.password) {
      setConfirmPasswordError("New password and confirm new password should be same")
      // return;
    }else{
      setConfirmPasswordError(null)
    }    


    if(formInput.oldPassword && formInput.password === formInput.confirmPassword){
        try {
          let res = await apis.user.changePassword(authToken, data);
          console.log("res =>>>>>>>>>", res)
          if (res.success === true) {
            setToastConfig({
              ...toastConfig,
              toastStatus: true,
              message: "Change Password Successfully",
              severity: "success"
            })
            setFormInput({password: "",
            confirmPassword: ""})
            return setTimeout(() => {
              logout();
            }, 2000)
          } else {
            setToastConfig({
              ...toastConfig,
              toastStatus: true,
              message: res.message,
              severity: "error"
            })
          }
          console.log(res)
        } catch (err) {
          console.log(err)
          setToastConfig({
            ...toastConfig,
            toastStatus: true,
            message: err.message,
            severity: "error"
          })
        }
    }
  };

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  const togglePassword2 = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
  };


  const togglePassword3 = () => {
    if (passwordType3 === "password") {
      setPasswordType3("text");
      return;
    }
    setPasswordType3("password");
  };

  return (
    <>
      <div>
        <div style={{ height: "64px" }}></div>
        <Row
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginTop: "64px",
          }}
        >
          <Col xl="5 " lg="5" md="7" sm="10" xs="11">
            <KK_Toast
              setToastConfig={setToastConfig}
              toastConfig={toastConfig}
            />

            <Card
              className=" border-0"
              style={{
                boxShadow:
                  "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 5px 20px 0 rgba(0, 0, 0, 0.19)",
                borderRadius: "5%",
                backgroundColor: "#ede7f6",
              }}
            >
              <CardHeader className="bg-transparent ">
                <div className="text-center ">
                  <h1>Change Password</h1>
                </div>
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-3">
                <Form onSubmit={onSubmit}>
                <Form.Group controlId="formBasicEmail" className="mb-4">
                    <Form.Label>Old Password</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <i className="fas fa-unlock-alt"></i>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        value={formInput.oldPassword ? formInput.oldPassword : ""}
                        onChange={({ target }) => {
                          handleUserInput(target.name, target.value);
                        }}
                        name="oldPassword"
                        type={passwordType1}
                        placeholder="Old Password"
                        maxLength={12}
                      />

                      {passwordType1 === "password" ? (
                        <i
                          className="fas fa-eye-slash post-append"
                          name="oldPassword"
                          onClick={() => {
                            setPasswordType1(false);
                            togglePassword1();
                          }}
                        ></i>
                      ) : (
                        <i
                          className="fas fa-eye post-append"
                          onClick={() => {
                            setPasswordType1(true);
                            togglePassword1();
                          }}
                        ></i>
                      )}
                    </InputGroup>
                    {oldPasswordError ? (
                      <small style={{ color: "red" }} className="error-message">
                        {oldPasswordError}
                      </small>
                    ) : null}
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail" className="mb-4">
                    <Form.Label>New Password</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <i className="fas fa-unlock-alt"></i>
                        </InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control
                        value={formInput.password? formInput.password : ""}
                        onChange={({ target }) => {
                          handleUserInput(target.name, target.value);
                        }}
                        name="password"
                        type={passwordType2}
                        placeholder="New password"
                        maxLength={12}
                      />
                      {passwordType2 === "password" ? (
                        <i
                          className="fas fa-eye-slash post-append"
                          onClick={() => {
                            setPasswordType2(false);
                            togglePassword2();
                          }}
                        ></i>
                      ) : (
                        <i
                          className="fas fa-eye post-append"
                          onClick={() => {
                            setPasswordType2(true);
                            togglePassword2();
                          }}
                        ></i>
                      )}
                    </InputGroup>
                    {passwordError? (
                      <small style={{ color: "red" }} className="error-message">
                        {passwordError}
                      </small>
                    ) : null}
                  </Form.Group>

                  <Form.Group controlId="formBasicPassword" className="mb-4">
                    <Form.Label>Confirm New Password</Form.Label>
                    <InputGroup hasValidation>
                      <InputGroup.Prepend>
                        <InputGroup.Text>
                          <i className="fas fa-unlock-alt"></i>
                        </InputGroup.Text>
                      </InputGroup.Prepend>

                      <Form.Control
                        value={formInput.confirmPassword? formInput.confirmPassword : ""}
                        onChange={({ target }) => {
                          handleUserInput(target.name, target.value);
                        }}
                        name="confirmPassword"
                        type={passwordType3}
                        placeholder="Confirm new password"
                        maxLength={12}
                      />
                      {passwordType3 === "password" ? (
                        <i
                          className="fas fa-eye-slash post-append"
                          onClick={() => {
                            setPasswordType3(false);
                            togglePassword3();
                          }}
                        ></i>
                      ) : (
                        <i
                          className="fas fa-eye post-append"
                          onClick={() => {
                            setPasswordType3(true);
                            togglePassword3();
                          }}
                        ></i>
                      )}
                    </InputGroup>
                    {confirmPasswordError ? (
                      <small style={{ color: "red" }} className="error-message">
                        {confirmPasswordError}
                      </small>
                    ) : null}
                  </Form.Group>

                  <Form.Group className="text-center">
                    <Button
                      type="submit"
                      className={classes.root}
                      value="Submit"
                    >
                      Submit
                    </Button>
                  </Form.Group>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.login.loading,
    user_id: state.login.data.user_id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
    loginRequest: () => {
      dispatch(loginUserRequest());
    },
    loginSuccess: (data) => {
      dispatch(loginUserSuccess(data));
    },
    loginFailure: (error) => {
      dispatch(loginUserFailure(error));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);

// const mapStateToProps = state => {
//   return {
//     loading: state.login.loading,
//     user_id: state.login.data.user_id
//   }
// }

// const mapDispatchToProps = dispatch => {
//   return {
//     logout: ()=>{dispatch(logout())},
//     loginRequest: () => { dispatch(loginUserRequest()) },
//     loginSuccess: (data) => { dispatch(loginUserSuccess(data)) },
//     loginFailure: (error) => { dispatch(loginUserFailure(error)) }
//   }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
