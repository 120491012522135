
import React, { useEffect } from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Navbar, Row } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import routes from '../routes'
import  { _navEmp, _navSa } from '../_nav'
import { connect } from "react-redux";

const Admin = (props) => {
  const {roleId} = props
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    
    return routes.map((prop,key)=>{
        return(
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        )
  })
  }
  
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==-1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };



  return (
    <>
      <Sidebar
        {...props}
        routes={
          roleId===1?_navSa:_navEmp
        }
        logo={{
          innerLink: "/admin/index",
          imgSrc: require("../assets/img/brand/argon-react.png").default,
          imgAlt: "...",
        }}
      />
      {/* <Kk_Sidebar/> */}
      <div className="main-content"
        style={{
          background: 'radial-gradient(circle, rgba(178,229,233,1) 10%, rgba(104,216,147,1) 98%)',
          overflowY: "hidden",
          minHeight: "100vh",
        }}
        ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
       {/* <Row>1</Row>
       <Row>2</Row>
       <Row>3</Row>
       <Row>4</Row>
       <Row>5</Row> */}
        <Switch>
          {getRoutes(routes)}
          <Redirect from="*" to="/admin/dashboard" />
        </Switch>
        {/* <Container fluid>
          <AdminFooter />
        </Container> */}
      </div>
    </>
  );
};

const mapStateToProps = state =>{
  return {
    roleId : state.login.data.role_id
  }
}
 
export default connect(mapStateToProps,null)(Admin);
