/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Form from "react-bootstrap/Form";
// reactstrap components
import {
  // Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Row,
  Col,
  Modal,
  ModalBody,
  Input,
  Label,
  InputGroupAddon,
} from "reactstrap";
import { useHistory, useLocation, useParams } from "react-router";
import KK_Toast from "components/Toast/KK_Toast2"; //Use Toast2 for display text center align of the page
import apis from "apis";
import { loginUserRequest } from "redux/login/login.action";
import { loginUserSuccess } from "redux/login/login.action";
import { loginUserFailure } from "redux/login/login.action";
import { connect } from "react-redux";
import { InputGroup } from "react-bootstrap";
import { validation } from "components/_helpers/validation";
import { API_SERVER_URL } from "../../constants";

const useStyles = makeStyles({
  cancel: {
    background: "linear-gradient(45deg, #f44336 30%, #ef9a9a  90%)",
    border: 0,
    borderRadius: 10,
    boxShadow: "0 3px 5px 2px #b488ff83",
    color: "white",
    height: 38,
    padding: "0 30px",
  },
  root: {
    background: "linear-gradient(45deg, #7c4dff 30%, #b388ff  90%)",
    border: 0,
    borderRadius: 10,
    boxShadow: "0 3px 5px 2px #b488ff83",
    color: "white",
    height: 38,
    padding: "0 30px",
  },
});

const Login = ({ loginRequest, loginSuccess, loginFailure, loading }) => {
  const [forgetPasswordMail, setForgetPasswordMail] = useState("");
  const [disableContactNumber, setDisableContactNumber] = useState("");
  const [forgetPasswordState, setForgetPasswordState] = useState(false);
  const [passwordStatus, setPasswordStatus] = useState(true);
  const [errorMsgs, setErrorMsgs] = useState({});
  const [errStyle, setErrStyle] = useState({});
  const [otpModalState, setOtpModalState] = useState(false);
  const [otpStep, setOtpStep] = useState(false); // Controls the step in OTP modal
  const [otpInput, setOtpInput] = useState(""); // Stores OTP input value
  const [otpValidationError, setOtpValidationError] = useState(""); // Stores OTP validation error
  const [ContactNumber, setContactNumber] = useState(""); // Stores OTP validation error
  const [OTPRespData,setOTPRespData] = useState();
  const [passwordStep, setPasswordStep] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  let emailRef = useRef(null);
  const passwordRef = useRef(null);

  useEffect(() => {
    emailRef.current.focus();
    passwordRef.current.focus();
    console.log(emailRef);
  }, []);

  let history = useHistory();
  const classes = useStyles();
  const loginRef = useRef();
  const [toastConfig, setToastConfig] = useState({
    toastStatus: false,
    message: "",
    severity: null,
  });

  //function to get ID from URL
  let { status } = useParams();
  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  let query = useQuery();
  status = query.get("status");

  // To display toast message when logged out
  useEffect(() => {
    if (status) {
      setToastConfig({
        toastStatus: true,
        message: "Logout Successfully",
        severity: "info",
      });
    }
  }, [status]);

  const [formData, setFormData] = useState({
    email: null,
    password: "",
  });

  const changePasswordVisibility = useCallback(() => {
    if (passwordStatus === true) {
      passwordRef.current.type = "text";
    } else {
      passwordRef.current.type = "password";
    }
  }, [passwordStatus]);

  const login = async (e) => {
    handleSubmit();
    loginRequest();
    setToastConfig({
      ...toastConfig,
      toastStatus: true,
      message: "Authenticating...",
      severity: "info",
    });

    let data = {
      user_name: formData.email,
      password: formData.password,
    };
    console.log("pdata", data);
    try {
      let res = await apis.authentication.loginRequest(data);
      console.log("Login Res", res);
      if (res.success) {
        // setToastConfig({
        //   ...toastConfig,
        //   toastStatus: true,
        //   message: res.message,
        //   severity: "success",
        // });
        return setTimeout(() => {
          loginSuccess(res.data);
          history.push("/admin/dashboard/success");
        }, 2000);
      } else {
        loginFailure(res.data);
        setToastConfig({
          ...toastConfig,
          toastStatus: true,
          message: res.message,
          severity: "error",
        });
      }
      console.log(res);
    } catch (err) {
      console.log(err);
      setToastConfig({
        ...toastConfig,
        toastStatus: true,
        message: err.message,
        severity: "error",
      });
    }
  };

  useEffect(() => {
    console.log(toastConfig);
  }, [toastConfig]);

  useEffect(() => {
    console.log("Initial LOgin DAta", formData);
  }, [formData]);

  const handleSubmit = () => {
    let resemail = validation.underscore(formData.email, "email");
    let respassword = validation.spacesCheck(formData.password, "password");

    setErrorMsgs({
      ...errorMsgs,
      email: { ...resemail },
      password: { ...respassword },
    });

    setErrStyle({
      ...errStyle,
      emailstyle: resemail.validation === false ? "error" : "success",
      passwordstyle: respassword.validation === false ? "error" : "success",
    });
  };

  const onChangeHandler = (e) => {
    switch (e.target.name) {
      case "email": {
        setErrStyle({
          ...errStyle,
          [e.target.name + "style"]: null,
        });
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: null,
        });
        let res = validation.underscore(e.target.value, e.target.name);

        console.log("Response", res);
        if (res.validation === true) {
          setErrStyle({
            ...errStyle,
            [e.target.name + "style"]: null,
          });
          setErrorMsgs({
            ...errorMsgs,
            [e.target.name]: {
              ...res,
            },
          });
          return setFormData({
            ...formData,
            [e.target.name]: e.target.value,
          });
        } else {
          setErrStyle({
            ...errStyle,
            [e.target.name + "style"]: "error",
          });
          return setErrorMsgs({
            ...errorMsgs,
            [e.target.name]: {
              ...res,
            },
          });
        }
      }
      case "password": {
        setErrStyle({
          ...errStyle,
          [e.target.name + "style"]: null,
        });
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: null,
        });
        let res;
        if (e.target.name === "password") {
          if (e.target.value.length < 6) {
            setToastConfig({
              ...toastConfig,
              toastStatus: true,
              message: "Please enter minimum 6 digit password",
              severity: "error",
            });
          }
          res = validation.spacesCheck(e.target.value, "password");
          console.log(res);
          console.log(res && res.validation === false);
          if (res && res.validation === false) {
            setToastConfig({
              ...toastConfig,
              toastStatus: true,
              message: "Spaces are not allowed",
              severity: "error",
            });
          } else {
            setFormData({
              ...formData,
              password: e.target.value,
            });
          }
        }
      }
    }
  };

  useEffect(() => {
    console.log(formData);
  }, [formData]);

  const handleKeyPress = (e) => {
    if (e.code === "Enter") {
      login();
    }
  };

  return (
    <>
      <Col xl="5" lg="5" md="7" sm="10" xs="11">
        <KK_Toast setToastConfig={setToastConfig} toastConfig={toastConfig} />
        <Modal
    isOpen={forgetPasswordState}
    style={{
      position: "absolute",
      top: "30%",
      left: "50%",
      transform: "translate(-50%, -50%)",
    }}
  >
    <ModalBody>
      <Label>
        <h2>Enter Your Contact Number</h2>
      </Label>
      <Input
        onChange={(e) => setContactNumber(e.target.value)}
        className="mb-2"
        placeholder="Contact Number"
        value={ContactNumber}
        maxLength={10}
        disabled={disableContactNumber}
      />
      {otpStep ? (
        passwordStep ? (
          <>
            <Input
              type="password"
              onChange={(e) => setNewPassword(e.target.value)}
              className="mb-2"
              placeholder="New Password"
              value={newPassword}
            />
            <Input
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="mb-2"
              placeholder="Confirm Password"
              value={confirmPassword}
            />
            {passwordError && (
              <small className="text-danger d-block">
                {passwordError}
              </small>
            )}
            <Button
              onClick={async () => {
                if (newPassword !== confirmPassword) {
                  setPasswordError("Passwords do not match.");
                } else {
                  setPasswordError("");
                  const data = {
                    "mobile": ContactNumber,
                    "new_password":newPassword,
                    "otp": String(OTPRespData.otp),
                    "entered_otp":otpInput
                };
                  try {
                    let res = await apis.user.forgotPassword(data);
                    if (res.success === true) {
                      setDisableContactNumber(false);
                      setToastConfig({
                        ...toastConfig,
                        toastStatus: true,
                        message: "Password updated successfully, Please login",
                        severity: "success",
                      });
                      setTimeout(() => {
                        history.push("/#/auth/");
                      }, 2000);
                    } else {
                      setToastConfig({
                        ...toastConfig,
                        toastStatus: true,
                        message: res.message,
                        severity: "error",
                      });
                    }
                  } catch (err) {
                    setToastConfig({
                      ...toastConfig,
                      toastStatus: true,
                      message: err.message,
                      severity: "error",
                    });
                  }
                }
              }}
              className={classes.root + " mr-3"}
            >
              Update Password
            </Button>
            <Button
              onClick={() => {
                setForgetPasswordState(false);
                setOtpStep(false);
                setPasswordStep(false);
                setOtpInput("");
                setNewPassword("");
                setConfirmPassword("");
                setPasswordError("");
                setContactNumber("");
                setDisableContactNumber(false);
              }}
              className={classes.cancel}
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            <Input
              onChange={(e) => setOtpInput(e.target.value)}
              className="mb-2"
              placeholder="Enter OTP"
              value={otpInput}
              maxLength={6}
            />
            {otpValidationError && (
              <small className="text-danger d-block">
                {otpValidationError}
              </small>
            )}
            <Button
              onClick={async () => {
                if (otpInput.length < 6) {
                  setOtpValidationError("");
                } else {
                  const data = {
                    "mobile": ContactNumber,
                    "otp": String(OTPRespData.otp),
                    "entered_otp": otpInput,
                  };
                  try {
                    let res = await apis.user.verifyOTP(data);
                    if (res.success === true) {
                      setToastConfig({
                        ...toastConfig,
                        toastStatus: true,
                        message: "OTP Verified Successfully",
                        severity: "success",
                      });
                      setPasswordStep(true);
                    } else {
                      setToastConfig({
                        ...toastConfig,
                        toastStatus: true,
                        message: otpInput?res.message:"Please enter OTP",
                        severity: "error",
                      });
                    }
                  } catch (err) {
                    setToastConfig({
                      ...toastConfig,
                      toastStatus: true,
                      message: otpInput?"Invalid OTP":"Please enter OTP",
                      severity: "error",
                    });
                  }
                }
              }}
              className={classes.root + " mr-3"}
            >
              Verify OTP
            </Button>
            <Button
              onClick={() => {
                setForgetPasswordState(false);
                setOtpStep(false);
                setOtpInput("");
                setOtpValidationError("");
                setContactNumber("");
                setDisableContactNumber(false);
              }}
              className={classes.cancel}
            >
              Cancel
            </Button>
          </>
        )
      ) : (
        <>
          <Button
            onClick={async () => {
              if (ContactNumber) {
                setOtpValidationError("");
                const data = {
                  "mobile": ContactNumber,
                  "key": "HdkdTssml",
                };
                try {
                  let res = await apis.user.sendOTP(data);
                  if (res.success === true) {
                    setOtpStep(true);
                    setDisableContactNumber(true);
                    setToastConfig({
                      ...toastConfig,
                      toastStatus: true,
                      message: "OTP Sent Successfully",
                      severity: "success",
                    });
                    setOTPRespData(res);
                  } else {
                    setToastConfig({
                      ...toastConfig,
                      toastStatus: true,
                      message: res.message,
                      severity: "error",
                    });
                  }
                } catch (err) {
                  setToastConfig({
                    ...toastConfig,
                    toastStatus: true,
                    message: "Invalid mobile number",
                    severity: "error",
                  });
                }
              } else {
                setOtpValidationError("Contact number is required.");
              }
            }}
            className={classes.root + " mr-3"}
          >
            Send OTP
          </Button>
          <Button
            onClick={() => {
              setForgetPasswordState(false);
              setOtpStep(false);
              setOtpInput("");
              setOtpValidationError("");
              setContactNumber("");
              setDisableContactNumber(false);
            }}
            className={classes.cancel}
          >
            Cancel
          </Button>
        </>
      )}
    </ModalBody>
  </Modal>
        <Card
          className=" border-0"
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 5px 20px 0 rgba(0, 0, 0, 0.19)",
            // boxShadow: '0 3px 5px 2px #75757983',

            borderRadius: "5%",
            backgroundColor: "#ede7f6",
          }}
        >
          <CardHeader className="bg-transparent  pb-5">
            <div className="text-center ">
              <h1>Log In</h1>
            </div>
          </CardHeader>
          <CardBody className="px-lg-5 py-lg-5">
            {/* <div className="text-center text-muted mb-4">
              <small>Or sign in with credentials</small>
            </div> */}
            <Form>
              <Form.Group controlId="formBasicEmail" className="mb-4">
                {/* <Form.Label>Email address</Form.Label> */}
                <InputGroup hasValidation>
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <i className="fas fa-envelope"></i>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    ref={emailRef}
                    value={formData.email}
                    type="text"
                    // onKeyPress={handleKeyPress}
                    name="email"
                    className={errStyle.email}
                    maxLength={10}
                    onChange={onChangeHandler}
                    placeholder="Enter username / Primary Contact Number"
                    required
                  />
                </InputGroup>
                {/* <Form.Control.Feedback type="invalid">
                  Please choose a username.
                </Form.Control.Feedback> */}
                {!(
                  errorMsgs &&
                  errorMsgs.email &&
                  errorMsgs.email.validation
                ) && (
                  <small className="errText">
                    {errorMsgs && errorMsgs.email && errorMsgs.email.errMsg}
                  </small>
                )}

                {/* {
										!(errorMsgs && errorMsgs.email && errorMsgs.email.validation) && <small className="errText">{errorMsgs && errorMsgs.email && errorMsgs.email.errMsg}</small>
									}  */}
              </Form.Group>

              <Form.Group controlId="formBasicPassword" className="mb-3">
                {/* <Form.Label>Password</Form.Label> */}
                <InputGroup hasValidation className="password-field">
                  <InputGroup.Prepend>
                    <InputGroup.Text>
                      <i className="fas fa-unlock-alt"></i>
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control
                    minLength={6}
                    maxLength={18}
                    ref={passwordRef}
                    type="password"
                    name="password"
                    value={formData.password}
                    onKeyPress={handleKeyPress}
                    onChange={onChangeHandler}
                    placeholder="Password"
                  />
                  {/* {
										!(errorMsgs && errorMsgs.email && errorMsgs.email.validation) && <small className="errText">{errorMsgs && errorMsgs.email && errorMsgs.email.errMsg}</small>
									}  */}
                  {passwordStatus ? (
                    <i
                      className="fas fa-eye-slash post-append"
                      onClick={() => {
                        setPasswordStatus(false);
                        changePasswordVisibility();
                      }}
                    ></i>
                  ) : (
                    <i
                      className="fas fa-eye post-append"
                      onClick={() => {
                        setPasswordStatus(true);
                        changePasswordVisibility();
                      }}
                    ></i>
                  )}
                </InputGroup>
                <div className="w-100 d-flex justify-content-end pointer mt-2">
                  <a
                    onClick={() => {
                      setForgetPasswordState(true);
                    }}
                  >
                    Forgot password?
                  </a>
                </div>
              </Form.Group>
              <FormGroup className="mt-0 mb-5" check inline>
                <Input type="checkbox" />
                <Label check>Remeber Me</Label>
              </FormGroup>
              <FormGroup check inline></FormGroup>
              <Form.Group className="text-center mb-3">
                <Button
                  onClick={login}
                  className={classes.root}

                  // ref={loginRef}
                >
                  Log In
                </Button>
              </Form.Group>
              <Form.Group className="text-center mb-3">
                <Button
                  onClick={() => setOtpModalState(true)}
                  className={classes.root}
                >
                  Log In with OTP
                </Button>
              </Form.Group>
            </Form>
            <Modal
              isOpen={otpModalState}
              style={{
                position: "absolute",
                top: "30%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            >
              <ModalBody>
                <Label>
                  <h2>Contact Number</h2>
                </Label>
                <Input
                  maxLength={10}
                  onChange={(e) => setContactNumber(e.target.value)}
                  className="mb-2"
                  placeholder="Contact Number"
                  value={ContactNumber}                          
                  disabled={disableContactNumber}          
                />
                {otpStep ? (
                  <>
                    <Input
                      onChange={(e) => setOtpInput(e.target.value)}
                      className="mb-2"
                      placeholder="Enter OTP"
                      value={otpInput}
                      maxLength={6}
                    />
                    {otpValidationError && (
                      <small className="text-danger d-block">
                        {otpValidationError}
                      </small>
                    )}
                    <Button
                      onClick={async() => {
                        if (otpInput.length < 6) {
                          setOtpValidationError(
                            ""
                          );
                        }
                        const data = {
                          "mobile": ContactNumber,
                          "otp": String(OTPRespData.otp),
                          "entered_otp":otpInput
                      }

                      console.log("data",data);
                        try {
                          console.log("====================");
                          let res = await apis.user.verifyOTP(data);
                          console.log("res =>>>>>>>>>", res)
                          if (res.success === true) {
                            setToastConfig({
                              ...toastConfig,
                              toastStatus: true,
                              message: "OTP Verified Successfully",
                              severity: "success"
                            })
                            return setTimeout(() => {
                              loginSuccess(res.data);
                              history.push("/admin/dashboard/success");
                            }, 2000);
                          } else {
                            setToastConfig({
                              ...toastConfig,
                              toastStatus: true,
                              message: res.message,
                              severity: "error"
                            })
                          }
                          console.log(res)
                        } catch (err) {
                          console.log(err)
                          setToastConfig({
                            ...toastConfig,
                            toastStatus: true,
                            message: otpInput?"Invalid OTP":"Please enter OTP",
                            severity: "error"
                          })
                        }
                      }}
                      className={classes.root + " mr-3"}
                    >
                      Login
                    </Button>
                    <Button
                      onClick={() => {
                        setOtpModalState(false);
                        setOtpStep(false);
                        setOtpInput("");
                        setFormData({ ...formData, usernameOrContact: "" });
                        setOtpValidationError("");
                        setContactNumber("");
                        setDisableContactNumber(false);
                      }}
                      className={classes.cancel}
                    >
                      Cancel
                    </Button>
                  </>
                ) : (
                  <>
                    <Button
                      onClick={async() => {
                        if (ContactNumber) {
                          setOtpValidationError("");
                        } else {
                          setOtpValidationError(
                            "contact number is required."
                          );
                        }
                        const data = {
                          "mobile": ContactNumber,
                          "key":"HdkdTssml"
                        }
                        try {
                          let res = await apis.user.sendOTP(data);
                          console.log("res =>>>>>>>>>", res)
                          if (res.success === true) {
                            setOtpStep(true);
                            setDisableContactNumber(true);
                            setToastConfig({
                              ...toastConfig,
                              toastStatus: true,
                              message: "OTP Send Successfully",
                              severity: "success"
                            })
                            setOTPRespData(res);
                          } else {
                            setToastConfig({
                              ...toastConfig,
                              toastStatus: true,
                              message: res.message,
                              severity: "error"
                            })
                          }
                          console.log(res)
                        } catch (err) {
                          console.log(err)
                          setToastConfig({
                            ...toastConfig,
                            toastStatus: true,
                            message: "Invalid mobile number",
                            severity: "error"
                          })
                        }
                      }}
                      className={classes.root + " mr-3"}
                    >
                      Send OTP
                    </Button>
                    <Button
                      onClick={() => {
                        setOtpModalState(false);
                        setOtpStep(false);
                        setOtpInput("");
                        setFormData({ ...formData, usernameOrContact: "" });
                        setOtpValidationError("");
                        setContactNumber("");
                      }}
                      className={classes.cancel}
                    >
                      Cancel
                    </Button>
                  </>
                )}
              </ModalBody>
            </Modal>

            {/* </div> */}
          </CardBody>
        </Card>
        {/* <Row className="mt-3">
          <Col xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Forgot password?</small>
            </a>
          </Col>
          <Col className="text-right" xs="6">
            <a
              className="text-light"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <small>Create new account</small>
            </a>
          </Col>
        </Row> */}
      </Col>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.login.loading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginRequest: () => {
      dispatch(loginUserRequest());
    },
    loginSuccess: (data) => {
      dispatch(loginUserSuccess(data));
    },
    loginFailure: (error) => {
      dispatch(loginUserFailure(error));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
