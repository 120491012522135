import React from 'react';
import Button from '@material-ui/core/Button';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert'
import { makeStyles } from '@material-ui/core/styles';
import './kktoast.css'

function Alert(props) {
  return <MuiAlert className='kk-toast-alert' elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function KK_Toast({toastConfig,setToastConfig, style}) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setToastConfig({
      ...toastConfig,
      toastStatus:false
    })
  };

  return (
    <div className={classes.root}>
      <Snackbar style={{...style}} anchorOrigin={{vertical:"top",horizontal:"center"}} open={toastConfig.toastStatus} autoHideDuration={2000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={toastConfig.severity}>
          {toastConfig.message}
        </Alert>
      </Snackbar>
      {/* <Alert severity="error">This is an error message!</Alert>
    //   <Alert severity="warning">This is a warning message!</Alert>
    //   <Alert severity="info">This is an information message!</Alert>
    //   <Alert severity="success">This is a success message!</Alert> */}
    </div>
  );
}