/* eslint-disable react-hooks/exhaustive-deps */
import Pagination from "../../../components/pagination/Pagination";
import apis from "apis";
import KK_Toast from "components/Toast/KK_Toast";
import { scrollToTop } from "components/_helpers/utils";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router";
import Pagination2 from "../../../components/pagination/Pagination2";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Button,
  UncontrolledTooltip,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";
import { decrementPageNo } from "redux/pagination/pagination.action";
import { incrementPageNo } from "redux/pagination/pagination.action";
import SearchBox from "components/SearchBox/SearchBox";
import { ConvertToExcel } from "components/excel/CovertToExcel";
import EmptyTablePlaceHolder from "components/EmptyTableReplacement.js/EmptyTablePlaceHolder";
import { API_SERVER_URL2 } from "./../../../constants";
const CandidateList = ({
  setCandidateData,
  setCandidateId,
  setToastConfig,
  setTab,
  setWorkFlow,
  pageNo,
  auth_token,
}) => {
  console.log("pageNo", pageNo);
  const [deleteId, setDeleteId] = useState();
  const [editId, setEditId] = useState();
  const [tableData, setTableData] = useState();
  const [allCandidate, setAllCandidate] = useState();
  const [exportAllCandidate, setExportAllCandidate] = useState([]);
  const [datalength, setDatalength] = useState();
  const [page_no, setPageNo] = useState(0);
  const [nextPageStatus, setNextPageStatus] = useState(false);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(9);
  const [nextStatus, setNextStatus] = useState(false);
  const [loader, setLoader] = useState(false);
  const [searchedText, setSearchedText] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const recordsPerPage = 10;
  const [pageOffset, setPageOffset] = useState({
    start: 0,
    end: recordsPerPage - 1,
  });
  const [totalPages, setTotalPages] = useState(0);

  const onSearch = (e) => {
    setSearchedText(e.target.value);
  };

  const onChangeNext = () => {
    setPageOffset({
      start: pageOffset.start + recordsPerPage,
      end: pageOffset.end + recordsPerPage,
    });
    console.log("Length", totalPages);

    setPageNumber(pageNumber + 1);
  };

  const onChangePrev = () => {
    setPageOffset({
      start: pageOffset.start - recordsPerPage,
      end: pageOffset.end - recordsPerPage,
    });

    setPageNumber(pageNumber - 1);
  };

  useEffect(() => {
    console.log("TOTAL PAGES", totalPages);
    console.log("page number", pageNumber);
    console.log("Page Offset", pageOffset);
  }, []);

  const getSearchedRecords = async (searchedText) => {
    let body = {
      searched_text: searchedText,
    };
    let searchedList = await apis.masters.getPublicSearchedRecords(
      "/api/V1/master/candidateSearch",
      body
    );
    console.log("SEARCHEDLIST", searchedList);
    // Pagination values set to original state
    setPageNumber(1)
    setPageOffset({
    start: 0,
    end: recordsPerPage - 1,
    })
    let remainder = searchedList.data.length % recordsPerPage;
    let limit = parseInt(searchedList.data.length / recordsPerPage);
    if (remainder !== 0) {
      limit = limit + 1;
    }
    setTotalPages(limit);
    setTableData(searchedList.data);
  };

  useEffect(() => {
    console.log("SearchedText", searchedText);
  }, [searchedText]);

  const previousPage = () => {
    setNextStatus(false);
    if (start > 0 && end > 9 && start - 10 >= 0 && end - 10 >= 9) {
      setStart((i) => i - 10);
      setEnd((i) => i - 10);
    }
  };

  const nextPage = () => {
    if (end < tableData.length) {
      setStart((i) => i + 10);
      setEnd((i) => i + 10);
    } else {
      setNextStatus(true);
    }
  };

  useEffect(() => {
    console.log("start:", start, "  End:", end);
  }, [start, end]);

  useEffect(() => {
    console.log("New TableDATA", tableData);
  }, [tableData]);

  useEffect(async () => {
    // setLoader(true);
    // try {
    //   let res = await apis.candidates.getAllCandidates();
    //   if (res.resultCode === 1) {
    //     setLoader(false);
    //     setTableData(res.resultData);
    //   }
    //   console.log(res);
    // } catch (err) {
    //   setLoader(false);
    //   console.log(err);
    // }
    getAllRecords();
  }, []);

  useEffect(() => {
    getAllRecords();
  }, [pageNo]);

  useEffect(async () => {
    console.log("deleteId", deleteId);
    if (deleteId) {
      try {
        let res = await apis.candidates.deleteCandidate(deleteId);
        console.log(res);
        if (res.resultCode === 1) {
          scrollToTop();
          setToastConfig({
            toastStatus: true,
            message: res.resultMsg,
            severity: "success",
          });
          return getAllRecords();
        } else {
          scrollToTop();
          return setToastConfig({
            toastStatus: true,
            message: res.message,
            severity: "error",
          });
        }
      } catch (err) {
        console.error(err.response);
        scrollToTop();
        setToastConfig({
          toastStatus: true,
          message: err.message,
          severity: "error",
        });
        console.error(err);
      }
    }
  }, [deleteId]);

  useEffect(() => {
    if (editId) {
      history.push({
        pathname: "/admin/addJob/" + editId,
      });
    }
  }, [editId]);

  // const generateThs = () => {
  //   let res =
  //     tableData &&
  //     tableData
  //       .filter((data, idx) => idx < 1)
  //       .map((data) => {
  //         let values = Object.getOwnPropertyNames(data);
  //         return values.map((item) => {
  //           return (
  //             <th className="text-white" scope="col">
  //               {item}                                {/* if {item} commented, will get bg color as blue */}
  //             </th>
  //           );                                            // commented, will get Action as table head
  //         });
  //       });
  //   return res;
  // };

  const generateTds = () => {
    //    let tdList=[]
    //    let res= []
    let res =
      tableData &&
      tableData
        .filter((i, idx) => idx >= pageOffset.start && idx <= pageOffset.end)
        .map((data) => {
          let values = Object.values(data);
          return (
            <tr>
              {values.map((item) => {
                let string = "" + item;
                let type = string.split(".").pop();
                return type.toLowerCase() === "pdf" ||
                  type.toLowerCase() === "jpg" ||
                  type.toLowerCase() === "png" ||
                  type.toLowerCase() === "docx"
                  ? (
                  <>
                    <td scope="col">
                      <a
                        rel="noreferrer"
                        href={API_SERVER_URL2 + "/" + item}
                        // href={"http://35.224.211.184:5050/" + item}
                        target="_blank"
                        download
                      >
                        View Document
                      </a>
                    </td>
                  </>
                  ) : (
                  <>
                    <td scope="col">{item}</td>
                  </>
                 );
               })}
                <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    // href="#pablo"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      name="EDIT"
                      onClick={(e) => {
                        setCandidateData(data);
                        setWorkFlow("EDIT");
                        setTab("ADD");
                      }}
                    >
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      name="DELETE"
                      onClick={(e) => {
                        setDeleteId(data.user_id);
                      }}
                    >
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          );
        });
    return res;
  };

  const getAllRecords = async () => {
    setLoader(true);
    try {
      let res = await apis.candidates.getAllCandidates();
      console.log("res", res);
      if (res.resultCode === 1) {
        setLoader(false);
        let remainder = res.resultData.length % recordsPerPage;
        let limit = parseInt(res.resultData.length / recordsPerPage);
        if (remainder !== 0) {
          limit = limit + 1;
        }
        setTotalPages(limit);
        setTableData(res.resultData);
        setAllCandidate(res.resultData);
        console.log("tabledata==>",res.resultData)
      } else {
        setLoader(false);
      }
    } catch (err) {
      setLoader(false);
      console.error(err);
    }
  };

  let history = useHistory();
  // This function used when press enter key to Search data in list
  const handleEnterkeySearch = (e, searchedText) => {
    if (e.key === "Enter") {
      getSearchedRecords(searchedText);
    }
  };
  // To Export candate data
  const exportAllCandidateData = () => {
    let resData = [];
    allCandidate && allCandidate.map((item) => {
      <>
      {
        resData.push({
          "User ID" : item.user_id,
          "Full Name": item.full_name,
          "Email ID": item.email_id,
          "Mobile Number": item.mobile_no,
          "Job Category": item.job_category,
          "Skills": item.skills,
          "Experience": item.experience,
          "Educational Qualification": item.education_qualification,
          "Upload Resume": item.upload_resume,
          "Upload Aadhar": item.upload_aadhaar,
          "Age": item.age,
          "Gender": item.gender,
        })
      }
      </>
    })
    console.log("Export Candidate - ",resData);
    setExportAllCandidate(resData)
  }
  useEffect(() => {
    exportAllCandidateData();
  },[allCandidate])

  return (
    <>
      {/* Table Code */}
      <Row className=" w-100 p-0 m-0">
        <Col className=" p-0 m-0">
          <SearchBox
            getAllRecords={getAllRecords}
            id="candidateSearch"
            onSearch={() => {
              getSearchedRecords(searchedText);
            }}
            onKeyPress={(e) => {
              handleEnterkeySearch(e,searchedText);
            }}
            searchedText={searchedText}
            setSearchedText={setSearchedText}
            onChange={onSearch}
          />
        </Col>
        <Col className=" p-0 m-0 d-flex justify-content-end align-items-center">
          <ConvertToExcel
            style={{}}
            dataToCovert={exportAllCandidate}
            filename="candidateList.csv"
            btnName="Download Candidate List"
          />
        </Col>
      </Row>
      {loader ? (
        <Spinner
          style={{ width: "3rem", height: "3rem", color: "whitesmoke" }}
        />
      ) : tableData && tableData.length > 0 ? (
        <>
          <Table
            className="align-items-center table-condensed table-striped  w3-animate-bottom"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              backgroundColor: "white",
            }}
            responsive
          >
            <thead className="thead-dark">
              {/* {generateThs()} */}
                <th className="text-white" scope="col">USER ID</th>
                <th className="text-white" scope="col">FULL NAME</th>
                <th className="text-white" scope="col">EMAIL ID</th>
                <th className="text-white" scope="col">MOBILE NUMEBR</th>
                <th className="text-white" scope="col">JOB CATEGORY</th>
                <th className="text-white" scope="col">SKILLS</th>
                <th className="text-white" scope="col">EXPERIENCE</th>
                <th className="text-white" scope="col">EDUCATION QUALIFICATION</th>
                <th className="text-white" scope="col">UPLOAD RESUME</th>
                <th className="text-white" scope="col">UPLOAD AADHAAR</th>
                <th className="text-white" scope="col">AGE</th>
                <th className="text-white" scope="col">GENDER</th>
                <th className="text-white" scope="col">ACTIONS</th>
            </thead>
            <tbody>{generateTds()}</tbody>
          </Table>
          <Row className="pagination mt-2 mb-5 w3-animate-bottom">
            <Pagination2
              style={{ marginLeft: "auto", marginRight: "auto" }}
              pageNumber={pageNumber}
              pageOffset={pageOffset}
              length={totalPages}
              onChangeNext={onChangeNext}
              onChangePrev={onChangePrev}
              recordsPerPage={recordsPerPage}
            />
          </Row>
        </>
      ) : (
        <EmptyTablePlaceHolder>No Candidate Found</EmptyTablePlaceHolder>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pageNo: state.pagination.jobPageNo,
    auth_token: state.login.data.auth_token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    nextPage: (item) => {
      dispatch(incrementPageNo(item));
    },
    previousPage: (item) => {
      dispatch(decrementPageNo(item));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(CandidateList);
