/* eslint-disable react-hooks/exhaustive-deps */
import Pagination from "../../../components/pagination/Pagination";
import apis from "apis";
import KK_Toast from "components/Toast/KK_Toast";
import { scrollToTop } from "components/_helpers/utils";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import { useHistory } from "react-router";

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Button,
  UncontrolledTooltip,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";
import { decrementPageNo } from "redux/pagination/pagination.action";
import { incrementPageNo } from "redux/pagination/pagination.action";
import EmptyTablePlaceHolder from "components/EmptyTableReplacement.js/EmptyTablePlaceHolder";
import { logout } from "redux/login/login.action";
import SearchBox from "components/SearchBox/SearchBox";
import { ConvertToExcel } from "components/excel/CovertToExcel";
import Pagination2 from "components/pagination/Pagination2";
import moment from "moment";

const JobList = ({
  setJobData,
  setToastConfig,
  setTab,
  setWorkFlow,
  pageNo,
  nextPage,
  previousPage,
  auth_token,
  logout,
}) => {
  const [deleteId, setDeleteId] = useState();
  const [editId, setEditId] = useState();
  const [tableData, setTableData] = useState();
  // creating separate state for display selected fields in job list
  const [rowTableData, setRowTableData] = useState();
  const [page_no, setPageNo] = useState(0);
  const [loader, setLoader] = useState(false);
  const [nextPageStatus, setNextPageStatus] = useState(false);
  const role_id = useSelector((state) => state.login.data.role_id);
  let history = useHistory();
  const [searchedText, setSearchedText] = useState("");
  const [allJobData, setAllJobData] = useState([]);
  // for pagination
  const [pageNumber, setPageNumber] = useState(1);
  const recordsPerPage = 10;
  const [pageOffset, setPageOffset] = useState({
    start: 0,
    end: recordsPerPage - 1,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [exportExcelData, setExportExcelData] = useState([]);

  const onChangeNext = () => {
    setPageOffset({
      start: pageOffset.start + recordsPerPage,
      end: pageOffset.end + recordsPerPage,
    });
    setPageNumber(pageNumber + 1);
  };

  const onChangePrev = () => {
    setPageOffset({
      start: pageOffset.start - recordsPerPage,
      end: pageOffset.end - recordsPerPage,
    });
    setPageNumber(pageNumber - 1);
  };

  // useEffect(() => {
  //   console.log("TOTAL PAGES", totalPages);
  //   console.log("page number", pageNumber);
  //   console.log("Page Offset", pageOffset);
  // }, []);

  const onSearch = (e) => {
    setSearchedText(e.target.value);
  };

  const getSearchedRecords = async (searchedText) => {
    let body = {
      searched_text: searchedText,
    };
    let searchedList = await apis.masters.getSearchedRecords(
      auth_token,
      "/api/V1/master/jobSearch",
      body
    );
    console.log("SEARCHEDLIST", searchedList);
    // Pagination values set to original state
    setPageNumber(1);
    setPageOffset({
      start: 0,
      end: recordsPerPage - 1,
    });
    let remainder = searchedList.data.length % recordsPerPage;
    let limit = parseInt(searchedList.data.length / recordsPerPage);
    if (remainder !== 0) {
      limit = limit + 1;
    }
    setTotalPages(limit);
    setTableData(searchedList.data);
  };

  useEffect(() => {
    console.log("SearchedText", searchedText);
  }, [searchedText]);

  useEffect(async () => {
    try {
      let res = await apis.masters.getAllRecords(
        auth_token,
        `/api/v1/master/postJob/${pageNo === 0 ? "" : pageNo}`
      );

      if (res.success === false && res.error_code) {
        console.log("Here is the error");
        setToastConfig({
          toastStatus: true,
          message: res.message,
          severity: "error",
        });
        return setTimeout(() => {
          logout();
        }, 2000);
      }
      console.log(res.data.length > 0);
      // Pagination2
      let remainder = res.data.length % recordsPerPage;
      let limit = parseInt(res.data.length / recordsPerPage);
      if (remainder !== 0) {
        limit = limit + 1;
      }
      setTotalPages(limit);
      console.log("DATA=======>", res);
      // Pagination
      if (res.data.length > 0) {
        setLoader(false);
        setNextPageStatus(true);
      } else {
        setLoader(false);
        setNextPageStatus(false);
      }
    } catch (err) {
      setLoader(false);
      if (err.response && err.response && err.response.status === 401) {
        setToastConfig({
          toastStatus: true,
          message: "Session Expired!",
          severity: "error",
        });
        setTimeout(() => {
          logout();
        }, 2000);
      }
      console.log("ERROR MEIN AAYA", err);
    }
    getAllRecords();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllRecords();
  }, [pageNo]);

  useEffect(() => {
    console.log(deleteId);
    async function DeleteRecord() {
      if (deleteId) {
        try {
          let res = await apis.masters.deleteRecordRequest(
            auth_token,
            "/api/v1/master/postJob",
            deleteId
          );
          if (res.success) {
            scrollToTop();
            setToastConfig({
              toastStatus: true,
              message: res.message,
              severity: "success",
            });
            return getAllRecords();
          } else {
            scrollToTop();
            return setToastConfig({
              toastStatus: true,
              message: res.message,
              severity: "error",
            });
          }
        } catch (err) {
          scrollToTop();
          if (err.response && err.response.status === 401) {
            setToastConfig({
              toastStatus: true,
              message: "Session Expired!",
              severity: "error",
            });
            setTimeout(() => {
              logout();
            }, 2000);
          }
          setToastConfig({
            toastStatus: true,
            message: err.message,
            severity: "error",
          });
          console.error(err);
        }
      }
    }
    DeleteRecord();
  }, [deleteId]);

  useEffect(() => {
    if (editId) {
      history.push({
        pathname: "/admin/addJob/" + editId,
      });
    }
  }, [editId]);

  // created onEditEventHandle function to send select job details for edit
  // We display job list with particular data like we remove column "industry and job id" from the job list but it's necessary for edit thats why we create this function we get all details by id from tableData
  const onEditEventHandle = (id) => {
    let item =
      tableData &&
      tableData.find((ele) => {
        return ele.id === id;
      });
    setJobData(item);
  };

  const generateThs = () => {
    let res =
      rowTableData &&
      rowTableData
        .filter((data, idx) => idx < 1)
        .map((data) => {
          let values = Object.getOwnPropertyNames(data);
          return values.map((item, idx) => {
            return (
              <th className="text-white" scope="col" key={idx}>
                {item}
              </th>
            );
          });
        });
    return res;
  };

  const generateTds = () => {
    //    let tdList=[]
    //    let res= []
    let res =
      rowTableData &&
      rowTableData.sort((a, b) => parseFloat(b.id) - parseFloat(a.id))
        .filter((i, idx) => idx >= pageOffset.start && idx <= pageOffset.end)
        .map((data) => {
          let values = Object.values(data);
          return (
            <tr>
              {values.map((item, idz) => {
                return (
                  <>
                    {item?.length > 48 ? (
                      item.length > 80 ? (
                        <td className="td-column-lg" key={idz}>
                          {item}
                        </td>
                      ) : (
                        <td className="td-column-md">{item}</td>
                      )
                    ) : (
                      <td>{item}</td>
                    )}
                  </>
                );
              })}
              {/* {console.log("Edit Data",data)} */}
              <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    // href="#pablo"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    {!(role_id === 1) && (
                      <DropdownItem
                        name="EDIT"
                        onClick={(e) => {
                          onEditEventHandle(data.id);
                          setWorkFlow("EDIT");
                          setTab("ADD");
                        }}
                      >
                        Edit
                      </DropdownItem>
                    )}

                    <DropdownItem
                      name="DELETE"
                      onClick={(e) => {
                        setDeleteId(data.id);
                      }}
                    >
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            </tr>
          );
        });
        console.log("TD RES",res)
    return res;
  };

  // array data modified
  const modifyTableData = () => {
    let arr = [];
    console.log("arr data", tableData);
    tableData &&
      tableData.map((data) => (
        <>
          {arr.push({
            id: data.id,
            company_name: data.company_name,
            job_title: data.job_title,
            industry_name: data.industry_name,
            job_skills: data.job_skills,
            job_type_name: data.job_type_name,
            gender_preference:
              data.gender_preference === "1"
                ? "Male"
                : data.gender_preference === "2"
                ? "Female"
                : data.gender_preference === "3"
                ? "Other"
                : "",
            job_experience: data.job_experience,
            job_description: data.job_description,
            address: data.address,
            posted_date: data.added_date && moment(data.added_date).utc().format("DD-MM-YYYY, hh:mm:ss A")
          })}
          {/* {arr.push({"id":data.id, "job_title":data.job_title, "industry_name": data.industry_name, "industry_type": data.industry_type, "job_type" : data.job_type, "job_type_name": data.job_type_name, "gender_preference": data.gender_preference ==="1"? "Male" : data.gender_preference === "2" ? "Female" : data.gender_preference === "3" ? "Other" : "", "experience_needed": data.experience_needed, "job_experience": data.job_experience, "job_skills": data.job_skills, "job_description": data.job_description, "address": data.address})} */}
        </>
      ));
    setRowTableData(arr);
  };
  useEffect(() => {
    modifyTableData();
  }, [tableData]);

  const getAllRecords = async () => {
    // console.log("auth_token",auth_token)
    setLoader(true);
    try {
      let res = await apis.masters.getAllRecords(
        auth_token,
        `/api/v1/master/postJob/${pageNo === 0 ? "" : pageNo}`
      );
      if (res.success === false && res.error_code) {
        console.log("Here is the error");
        setToastConfig({
          toastStatus: true,
          message: res.message,
          severity: "error",
        });
        setTimeout(() => {
          logout();
        }, 2000);
      }
      // console.log("RES====>",res)
      if (res.success) {
        setLoader(false);
        setTableData(res.data);
        // Pagination values set to original state
        setPageNumber(1);
        setPageOffset({
          start: 0,
          end: recordsPerPage - 1,
        });
        let remainder = res.data.length % recordsPerPage;
        let limit = parseInt(res.data.length / recordsPerPage);
        if (remainder !== 0) {
          limit = limit + 1;
        }
        setTotalPages(limit);
        // setRowTableData(res.data);
      }
    } catch (err) {
      setLoader(false);
      if (err.response && err.response && err.response.status === 401) {
        setToastConfig({
          toastStatus: true,
          message: "Session Expired!",
          severity: "error",
        });
        setTimeout(() => {
          logout();
        }, 2000);
      }
      console.error(err);
    }
    // To Export all job list
    try {
      let res = await apis.masters.getAllRecords(
        auth_token,
        `/api/v1/master/getAllJobList`
      );
      if (res.success === false && res.error_code) {
        console.log("Here is the error");
        setToastConfig({
          toastStatus: true,
          message: res.message,
          severity: "error",
        });
      }
      if (res.success) {
        setAllJobData(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  };
  // This function used when press enter key to Search data in list
  const handleEnterkeySearch = (e, searchedText) => {
    if (e.key === "Enter") {
      getSearchedRecords(searchedText);
    }
  };
  //Export data to excel
  const exportDataTOExcel = () => {
    let resData = [];
    allJobData &&
      allJobData.sort((a, b) => parseFloat(b.id) - parseFloat(a.id)).map((item) => {
        <>
          {resData.push({
            "Id": item.id,
            "Company Name": item.company_name,
            "Job Title": item.job_title,
            "Job Category": item.industry_name,
            "Job Skills": item.job_skills,
            "Job Type": item.job_type_name,
            "Gender": item.gender_preference === "1" ? "Male" : "Female",
            "Job Experience": item.job_experience,
            "Job Description": item.job_description,
            "Address": item.address,
            "Posted Date": item.added_date && moment(item.added_date).utc().format("DD-MM-YYYY, hh:mm:ss A")
          })}
        </>;
      });
    setExportExcelData(resData);
  };
  useEffect(() => {
    exportDataTOExcel();
  }, [allJobData]);

  return (
    <>
      {/* Table Code */}
      <Row className=" w-100 p-0 m-0">
        <Col className=" p-0 m-0">
          <SearchBox
            getAllRecords={getAllRecords}
            id="jobSearch"
            onSearch={() => {
              getSearchedRecords(searchedText);
            }}
            onKeyPress={(e) => {
              handleEnterkeySearch(e, searchedText);
            }}
            searchedText={searchedText}
            setSearchedText={setSearchedText}
            onChange={onSearch}
          />
        </Col>
        <Col className=" p-0 m-0 d-flex justify-content-end align-items-center">
          <ConvertToExcel
            style={{}}
            dataToCovert={exportExcelData}
            filename="jobList.csv"
            btnName="Download Job List"
          />
        </Col>
      </Row>

      {loader ? (
        <Spinner
          style={{ width: "3rem", height: "3rem", color: "whitesmoke" }}
        />
      ) : rowTableData && rowTableData.length > 0 ? (
        <>
          <Table
            className="align-items-center table-condensed table-striped  w3-animate-bottom"
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              backgroundColor: "white",
            }}
            responsive
          >
            <thead className="thead-dark">
              {/* {generateThs()} */}

              <th className="text-white" scope="col">
                ID
              </th>
              <th className="text-white" scope="col">
                COMPANY NAME
              </th>
              <th className="text-white" scope="col">
                JOB TITLE
              </th>
              <th className="text-white" scope="col">
                JOB CATEGORY
              </th>
              <th className="text-white" scope="col">
                JOB SKILLS
              </th>
              <th className="text-white" scope="col">
                JOB TYPE NAME
              </th>
              <th className="text-white" scope="col">
                GENDER PREFERENCE
              </th>
              <th className="text-white" scope="col">
                JOB EXPERIENCE
              </th>
              <th className="text-white" scope="col">
                JOB DESCRIPTION
              </th>
              <th className="text-white" scope="col">
                ADDRESS
              </th>
              <th className="text-white" scope="col">
                POSTED DATE
              </th>
              <th className="text-white" scope="col">
                ACTIONS
              </th>
            </thead>
            <tbody>{generateTds()}</tbody>
          </Table>
          {/* <Pagination
            nextPageStatus={nextPageStatus}
            length={tableData && tableData.length}
            nextPage={nextPage}
            name="jobPageNo"
            previousPage={previousPage}
            pageNo={pageNo}
          /> */}
          <Pagination2
            style={{ marginLeft: "auto", marginRight: "auto" }}
            pageNumber={pageNumber}
            pageOffset={pageOffset}
            length={totalPages}
            onChangeNext={onChangeNext}
            onChangePrev={onChangePrev}
            recordsPerPage={recordsPerPage}
          />
          {/* {console.log("tableData.length", tableData.length)} */}
        </>
      ) : (
        <EmptyTablePlaceHolder>No Jobs Found</EmptyTablePlaceHolder>
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pageNo: state.pagination.jobPageNo,
    auth_token: state.login.data.auth_token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    nextPage: (item) => {
      dispatch(incrementPageNo(item));
    },
    previousPage: (item) => {
      dispatch(decrementPageNo(item));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(JobList);
