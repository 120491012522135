import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import KK_Toast from "components/Toast/KK_Toast";
import { useHistory } from "react-router";
import { connect } from "react-redux";
import AddCandidate from "./AddCandidate";
import CandidateList from "./CandidateList";
import IncompleteCandidateList from "./IncompleteCandidateList";

const Candidate = () => {
  const [toastConfig, setToastConfig] = useState({
    toastStatus: false,
    message: "",
    severity: null,
  });
  const [tab, setTab] = useState("LIST");
  const [workFlow, setWorkFlow] = useState("ADD");
  const [candidateId, setCandidateId] = useState(null);
  const [candidateData, setCandidateData] = useState(null);
  let history = useHistory();

  useEffect(() => {
    console.log("CandidateDATA", candidateData);
  }, [candidateId, candidateData]);

  return (
    <div className="pr-2 pl-2 container-fluid">
      <Row className="mt-7 text-center w3-animate-top">
        <Col></Col>
        <Col xs="12" lg="4">
          <KK_Toast setToastConfig={setToastConfig} toastConfig={toastConfig} />
          <h2 className="listbrand">
            {tab === "LIST"
              ? "CANDIDATE LIST"
              : workFlow === "EDIT"
              ? "EDIT CANDIDATE"
              : "ADD CANDIDATE"}
          </h2>
        </Col>
        <Col xs="12 mt-3" lg="4" className="d-flex justify-content-end">
          <Button
            className="ripple"
            style={{ backgroundColor: "transparent", color: "white" }}
            onClick={() => {
              tab === "LIST" ? setTab("ADD") : setTab("LIST");
              if (tab === "ADD") {
                setWorkFlow("ADD");
              }
            }}
          >
            <i
              class="fas fa-user-plus mr-2"
              style={{
                color: "white",
              }}
            ></i>
            {tab === "LIST" ? "Add Candidate" : "Candidate List"}
          </Button>
        </Col>
      </Row>
      {tab === "LIST" ? (
        <Row className="d-flex justify-content-end">
          <Col xs="12 mt-3" lg="4" className="d-flex justify-content-end">
            <Button
              className="ripple"
              style={{ backgroundColor: "transparent", color: "white" }}
              onClick={() => {
				history.push("/admin/incompleteProfile");
			  }}
            >
              Incomplete Profile
            </Button>
          </Col>
        </Row>
      ) : (
        ""
      )}

      <div
        className="mt-2"
        style={{
          // backgroundColor:"#b388ff",
          // backgroundColor:"white",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {tab === "LIST" ? (
          <CandidateList
            setCandidateData={setCandidateData}
            setCandidateId={setCandidateId}
            setToastConfig={setToastConfig}
            setWorkFlow={setWorkFlow}
            setTab={setTab}
          />
        ) : (
          <AddCandidate
            candidateData={candidateData}
            candidateId={candidateId}
            setWorkFlow={setWorkFlow}
            setToastConfig={setToastConfig}
            setTab={setTab}
            workFlow={workFlow}
          />
        )}
      </div>
    </div>
  );
};

export default Candidate;
