import React from 'react';
import { Table } from 'reactstrap';

const EmptyTablePlaceHolder = ({children}) => {
    return (
        <div className='table-responsive'>
        <Table className="align-items-center table-condensed table-striped mt-3 w3-animate-bottom"
            style={{
                marginLeft: "auto",
                marginRight: "auto",
                // width: "550px",
                backgroundColor: "white",
            }}
            responsive>
                 <thead className="thead-dark ">
                <th className='text-white' scope="col"></th>
              
            </thead>
            <tbody>
            <td scope="col" className=" text-center" style={{fontSize:"20px"}}>{children}</td>
            </tbody>
            </Table>
    </div>
    );
};

export default EmptyTablePlaceHolder;