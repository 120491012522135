/* eslint-disable default-case */
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Button,
  UncontrolledTooltip,
} from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { content } from "../../components/_helpers/Content";
import apis from "apis";
import KK_Toast from "components/Toast/KK_Toast";
import KK_Table from "components/table/KK_Table";
import axios from "axios";
import { logout } from "redux/login/login.action";
import { connect } from "react-redux";

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(45deg, #7c4dff 30%, #b388ff  90%)",
    border: 0,
    borderRadius: 10,
    boxShadow: "0 3px 5px 2px #b488ff83",
    color: "white",
    height: 38,
    width: "100%",
    padding: "0 20px",
  },
  disabled: {
    // background: 'linear-gradient(45deg,#b39ddb 30%, #d1c4e9 90%)',
    backgroundColor: "lightgrey",
    border: 0,
    borderRadius: 10,
    // boxShadow: '0 3px 5px 2px grey',
    color: "white",
    height: 38,
    padding: "0 20px",
  },
});

//Dummy Data For Table

const data = [
  {
    id: 1,
    company: "Company A",
    category: "IT",
    range: "1000",
    status: "test1",
    completion: "80%",
  },
  {
    id: 2,
    company: "Company B",
    category: "Sales",
    range: "2000",
    status: "test2",
    completion: "70%",
  },
  {
    id: 3,
    company: "Company C",
    category: "Marketing",
    range: "10000",
    status: "test3",
    completion: "90%",
  },
  {
    id: 4,
    company: "Company D",
    category: "Finance",
    range: "500",
    status: "test4",
    completion: "60%",
  },
  {
    id: 5,
    company: "Company E",
    category: "Pharma",
    range: "10000",
    status: "test5",
    completion: "40%",
  },
  {
    id: 6,
    company: "Company F",
    category: "Auto-Mobile",
    range: "100000",
    status: "test6",
    completion: "90%",
  },
];

const SkillsMaster = ({ logout }) => {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const pathName = history.location.pathname;
  const [inputClass, setInputClass] = useState("normal");
  const [selectedContent, setSelectedContent] = useState();
  const [tableData, setTableData] = useState([]);
  const [enteredValue, setEnteredValue] = useState("");
  const [operableData, setOperableData] = useState("");
  const [toastConfig, setToastConfig] = useState({
    toastStatus: false,
    message: "",
    severity: null,
  });
  const [workFlow, setWorkFlow] = useState("ADD");
  const [enterMax, setMax] = useState(null);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const generateThs = () => {
    let res = tableData
      .filter((data, idx) => idx < 1)
      .map((data) => {
        let values = Object.getOwnPropertyNames(data);
        return values.map((item) => {
          return <th scope="col">{item}</th>;
        });
      });
    return res;
  };

  const generateTds = () => {
    //    let tdList=[]
    //    let res= []
    let res = tableData.map((data) => {
      let values = Object.values(data);
      return (
        <tr>
          {values.map((item) => {
            return (
              <>
                <td scope="col">{item}</td>
              </>
            );
          })}
          <td className="text-right">
            <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                // href="#pablo"
                role="button"
                size="sm"
                color=""
                onClick={(e) => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem
                  name="EDIT"
                  onClick={(e) => {
                    setOperableData(data);
                    scrollToTop();
                    actionHandler(e);
                  }}
                >
                  Edit
                </DropdownItem>
                <DropdownItem
                  href="#pablo"
                  name="DELETE"
                  value={data}
                  onClick={(e) => {
                    setOperableData(data);
                    scrollToTop();
                    // actionHandler(e)
                  }}
                >
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </td>
        </tr>
      );
    });
    return res;
  };

  useEffect(() => {
    //api call to populate the table as per the req in content object
    getAllRecords("/api/v1/master/jobSkill");
  }, []);

  const getAllRecords = async (path) => {
    try {
      let res = await apis.masters.getAllRecords(path);
      console.log("Real Response", res);
      if (res.success === true) {
        setTableData(res.data);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setToastConfig({
          toastStatus: true,
          message: "Session Expired!",
          severity: "error",
        });
        setTimeout(() => {
          logout();
        }, 2000);
      }
      console.error(err);
    }
  };

  // const createNewRecord = (path) => {
  // 	apis.masters.createNewRecord(path).then(res => {
  // 		console.log(res)
  // 	}).catch(err => {
  // 		console.log(err)
  // 	})
  // }

  useEffect(() => {
    console.log("CLASS", inputClass);
  }, [inputClass]);

  useEffect(() => {
    console.log("tableData", tableData);
  }, [tableData]);

  const onChangeHandler = (e) => {
    setInputClass("normal");
    if (e.target.name === "max") {
      setMax(e.target.value);
    } else {
      setEnteredValue(e.target.value);
    }
  };

  useEffect(() => {
    console.log(enteredValue);
  }, [enteredValue]);

  useEffect(() => {
    setEnteredValue(operableData.name);
    console.log("workFlow", workFlow);
  }, [workFlow]);

  const actionHandler = async (e) => {
    switch (e.target.name) {
      case "ADD": {
        if (enteredValue === undefined || enteredValue.length === 0) {
          return setInputClass("error");
        } else {
          let data = null;
          if (selectedContent && selectedContent.id === 2) {
            data = {
              min_exp: parseInt(enteredValue),
              max_exp: parseInt(enterMax),
            };
          } else {
            data = {
              name: enteredValue,
            };
          }

          console.log(data, "DATA");
          // scrollToTop()
          if (workFlow === "EDIT") {
            try {
              let res = await apis.masters.updateRecordRequest(
                "/api/v1/master/jobSkill",
                operableData.id,
                data
              );
              if (res.success === true) {
                console.log("Update res", res);
                setToastConfig({
                  toastStatus: true,
                  message: res.message,
                  severity: "success",
                });
                setWorkFlow("ADD");
                setEnteredValue("");
                scrollToTop();
                return getAllRecords("/api/v1/master/jobSkill");
              } else {
                setToastConfig({
                  toastStatus: true,
                  message: res.message,
                  severity: "error",
                });
              }
            } catch (err) {
              setToastConfig({
                toastStatus: true,
                message: err.message,
                severity: "error",
              });
              if (err.response && err.response.status === 401) {
                setToastConfig({
                  toastStatus: true,
                  message: "Session Expired!",
                  severity: "error",
                });
                setTimeout(() => {
                  logout();
                }, 2000);
              }
              console.error(err);
            }
          } else {
            try {
              let res = await apis.masters.createRecordRequest(
                "/api/v1/master/jobSkill",
                data
              );
              if (res.success === true) {
                console.log("ADD res", res);
                setToastConfig({
                  toastStatus: true,
                  message: res.message,
                  severity: "success",
                });
                setEnteredValue("");
                scrollToTop();
                return getAllRecords("/api/v1/master/jobSkill");
              }
            } catch (err) {
              console.error(err);
              if (err.response && err.response.status === 401) {
                setToastConfig({
                  toastStatus: true,
                  message: "Session Expired!",
                  severity: "error",
                });
                setTimeout(() => {
                  logout();
                }, 2000);
              }
            }
          }
        }

        break;
      }
      case "EDIT": {
        return setWorkFlow("EDIT");
      }
      case "DELETE": {
        let body = {
          name: operableData.name,
        };
        console.log(body);
        // scrollToTop()
        try {
          let res = await apis.masters.deleteRecordRequest(
            "/api/v1/master/jobSkill",
            operableData.id,
            body
          );
          if (res.success === true) {
            console.log(res);
            setToastConfig({
              toastStatus: true,
              message: res.message,
              severity: "success",
            });
            return getAllRecords("/api/v1/master/jobSkill");
          } else {
            setToastConfig({
              toastStatus: true,
              message: res.message,
              severity: "error",
            });
          }
        } catch (err) {
          if (err.response && err.response.status === 401) {
            setToastConfig({
              toastStatus: true,
              message: "Session Expired!",
              severity: "error",
            });
            setTimeout(() => {
              logout();
            }, 2000);
          }
          setToastConfig({
            toastStatus: true,
            message: err.message,
            severity: "error",
          });

          console.error(err);
        }

        break;
      }
    }
  };

  const actionArray = [actionHandler, setOperableData];

  useEffect(() => {
    console.log("Operable Data", operableData);
    setEnteredValue(operableData.name);
  }, [operableData]);
  return (
    <>
      <div
        className="container text-center mt-8 mb-4"
        style={{ position: "relative" }}
      >
        <h2 style={{ color: "white" }} className="w3-animate-top">
          JOB SKILLS MASTER
        </h2>
        <KK_Toast setToastConfig={setToastConfig} toastConfig={toastConfig} />
      </div>

                     
                      
      <div className="d-flex justify-content-center flex-column align-items-center pr-5 pl-5" >
        <Col lg="7  w3-animate-left">
          <Card style={{ width: "100%" }}>
            <CardHeader className="pl-4 pt-2 pb-0">
              <Form.Label>Add Skill</Form.Label>
            </CardHeader>
            <CardBody className="pt-3 pb-0">
              <Row className="p-0">
                <Col
                  xs="12"
                  sm={
                    selectedContent && selectedContent.id === 2
                      ? "12 pl-3"
                      : "9 pl-3"
                  }
                  md={
                    selectedContent && selectedContent.id === 2
                      ? "12 pl-3"
                      : "9 pl-3"
                  }
                  lg={
                    selectedContent && selectedContent.id === 2
                      ? "12 pl-3"
                      : "9 pl-3"
                  }
                >
                  {selectedContent && selectedContent.id === 2 && (
                    <Form.Label>
                      {selectedContent &&
                        selectedContent &&
                        selectedContent.min}
                    </Form.Label>
                  )}
                  <Form.Group controlId="language">
                    <Form.Control
                      type="text"
                      onChange={onChangeHandler}
                      className={inputClass}
                      value={enteredValue}
                      placeholder={
                        selectedContent && selectedContent.id === 2
                          ? selectedContent && selectedContent.min
                          : selectedContent && selectedContent.placeholder
                      }
                    />
                    {inputClass === "error" && (
                      <small className="errText">
                        This Field Can't be Empty
                      </small>
                    )}
                  </Form.Group>
                </Col>
                {selectedContent && selectedContent.id === 2 && (
                  <Col xs="12 " sm="12 pl-3" md="12 pl-3" lg={"12 pl-3"}>
                    <Form.Label>
                      {selectedContent && selectedContent.max}
                    </Form.Label>
                    <Form.Group controlId="language">
                      <Form.Control
                        type="text"
                        name="max"
                        onChange={onChangeHandler}
                        className={inputClass}
                        placeholder={selectedContent && selectedContent.max}
                      />
                      {inputClass === "error" && (
                        <small className="errText">
                          This Field Can't be Empty
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                )}

                <Col
                  style={
                    selectedContent && selectedContent.id === 2
                      ? {
                          left: "50%",
                          transform: "translateX(-50%)",
                        }
                      : null
                  }
                  xs="12 d-flex justify-content-center pb-3 w-100"
                  sm="3"
                  md="3"
                  lg={
                    selectedContent && selectedContent.id === 2
                      ? "3 pr-3"
                      : "3 pr-3"
                  }
                >
                  <Button
                    name="ADD"
                    className="ripple"
                    onClick={actionHandler}
                    style={{
                      background:
                        "linear-gradient(45deg, #7c4dff 30%, #b388ff  90%)",
                      color: "white",
                      height: "40px",
                      width: "100%",
                      minWidth: "70px",
                    }}
                    // className={classes.root}
                  >
                    {workFlow === "EDIT" ? "Update" : "Add"}
                  </Button>
                </Col>
              </Row>
              <Row className="p-0">
                <Col
                  xs="12 "
                  sm={
                    selectedContent && selectedContent.id === 2
                      ? "12 pl-3"
                      : "9 pl-3"
                  }
                  md={
                    selectedContent && selectedContent.id === 2
                      ? "12 pl-3"
                      : "9 pl-3"
                  }
                  lg={
                    selectedContent && selectedContent.id === 2
                      ? "12 pl-3"
                      : "9 pl-3"
                  }
                >
                  {selectedContent && selectedContent.id === 2 && (
                    <Form.Label>
                      {selectedContent &&
                        selectedContent &&
                        selectedContent.min}
                    </Form.Label>
                  )}
                  <Form.Group controlId="language">
                    <Form.Control
                      type="text"
                      onChange={onChangeHandler}
                      className={inputClass}
                      value={enteredValue}
                      placeholder={
                        selectedContent && selectedContent.id === 2
                          ? selectedContent && selectedContent.min
                          : selectedContent && selectedContent.placeholder
                      }
                    />
                    {inputClass === "error" && (
                      <small className="errText">
                        This Field Can't be Empty
                      </small>
                    )}
                  </Form.Group>
                </Col>
                {selectedContent && selectedContent.id === 2 && (
                  <Col xs="12 " sm="12 pl-3" md="12 pl-3" lg={"12 pl-3"}>
                    <Form.Label>
                      {selectedContent && selectedContent.max}
                    </Form.Label>
                    <Form.Group controlId="language">
                      <Form.Control
                        type="text"
                        name="max"
                        onChange={onChangeHandler}
                        className={inputClass}
                        // value={enteredValue}
                        placeholder={selectedContent && selectedContent.max}
                      />
                      {inputClass === "error" && (
                        <small className="errText">
                          This Field Can't be Empty
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                )}

                <Col
                  style={
                    selectedContent && selectedContent.id === 2
                      ? {
                          left: "50%",
                          transform: "translateX(-50%)",
                        }
                      : null
                  }
                  xs="12 d-flex justify-content-center pb-3 w-100"
                  sm="3"
                  md="3"
                  lg={
                    selectedContent && selectedContent.id === 2
                      ? "3 pr-3"
                      : "3 pr-3"
                  }
                >
                  <Button
                    name="ADD"
                    className="ripple"
                    onClick={actionHandler}
                    style={{
                      background:
                        "linear-gradient(45deg, #7c4dff 30%, #b388ff  90%)",
                      color: "white",
                      height: "40px",
                      width: "100%",
                      minWidth: "70px",
                    }}
                    // className={classes.root}
                  >
                    {workFlow === "EDIT" ? "Update" : "Add"}
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Table
          className="align-items-center table-condensed table-striped mt-6 w3-animate-bottom"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            width: "550px",
            backgroundColor: "white",
          }}
          responsive
        >
          <thead className="thead-light">{generateThs()}</thead>
          <tbody>{generateTds()}</tbody>
        </Table>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(null, mapDispatchToProps)(SkillsMaster);
