import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row, Modal, ModalBody } from "reactstrap";
import "./map.scss";
import Autocomplete from "@material-ui/lab/Autocomplete";

import {
  InfoWindow,
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";
import { compose, withProps } from "recompose";
import Geocode from "react-geocode";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Map2 from "./Map2";
import { validation } from "components/_helpers/validation";
import { useRef } from "react";

Geocode.setApiKey("AIzaSyAWCm8fLrC0D4l4GBYggEzpFfEf7lDh1Is");
Geocode.setLocationType("ROOFTOP");
Geocode.enableDebug();
const google = window.google;

const LocationSearchInput = ({
  setPosition,
  setcAddress,
  setMapPosition,
  setMarkerPosition,
  caddress,
  address,
  setAddress,
  inputAddress,
  setInputAddress,
}) => {
  const locationRef = useRef(null);

  const handleChange = (address) => {
    setInputAddress(address);
  };

  const handleSelect = (address) => {
    setInputAddress(address);
    setcAddress({
      ...caddress,
      address_line2: address,
    });
    geocodeByAddress(address)
      .then((results) => getLatLng(results && results[0]))
      .then((latLng) => {
        console.log(latLng.lat);

        setMapPosition({
          lat: latLng.lat,
          lng: latLng.lng,
        });
        setMarkerPosition({
          lat: latLng.lat,
          lng: latLng.lng,
        });
      })
      .catch((error) => console.error("Error", error));
  };

  useEffect(() => {
    locationRef.current.focus();
  }, []);

  return (
    <>
      <PlacesAutocomplete
        value={inputAddress}
        onChange={handleChange}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              style={{
                border: "1px solid rgba(128, 128, 128, 0.774)",
                width: "100%",
                padding: "8px",
                borderRadius: "5px",
              }}
              {...getInputProps({
                placeholder: "Search Your Location...",
                className: "location-search-input",
              })}
              ref={locationRef}
            />
            <div
              className="autocomplete-dropdown-container"
              style={{
                position: "absolute",
                zIndex: "100",
              }}
            >
              {loading && (
                <div style={{ padding: "3px 15px 3px 15px" }}>Loading...</div>
              )}
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? {
                      backgroundColor: "#536dfe",
                      color: "#ffffff",
                      cursor: "pointer",
                      padding: "3px 15px 3px 15px",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }
                  : {
                      backgroundColor: "#ffffff",
                      cursor: "pointer",
                      padding: "3px 15px 3px 15px",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </>
  );
};

const Map = ({
  isOpen,
  setMapVisibility,
  selectedIdx,
  setAddress,
  address,
  selectedLocation,
  workFlow,
  setWorkFlow,
}) => {
  const [currentZoom, setCurrentZoom] = useState(16);
  const [inputAddress, setInputAddress] = useState(null);
  const [position, setPosition] = useState({
    lat: null,
    lng: null,
  });
  const [errStyle, setErrStyle] = useState({
    address_line1style: null,
    zipstyle: null,
    landmarkstyle: null,
    address_line2style: null,
    citystyle: null,
    statestyle: null,
  });
  const [errorMsgs, setErrorMsgs] = useState();
  const [mapPosition, setMapPosition] = useState({
    lat: null,
    lng: null,
  });
  const [markerPosition, setMarkerPosition] = useState({
    lat: null,
    lng: null,
  });

  const [markers, setMarkers] = useState([]);

  const [caddress, setcAddress] = useState({
    address_line1: null,
    address_line2: null,
    landmark: null,
    city: null,
    state: null,
    country: null,
    zip: null,
    geopoint_lat: null,
    geopoint_long: null,
  });
  const [addressString, setAddressString] = useState(null);
  const [pos, setPos] = useState({
    lat: null,
    lng: null,
  });

  const getAddress = (position) => {
    Geocode.fromLatLng(position.lat, position.lng).then(
      (response) => {
        let address = response.results[0].formatted_address;
        let city, state, country;
        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "locality":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_1":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
            }
          }
        }
        setAddressString(address);
        let addressSplit1 = address.split(",");
        console.log(addressSplit1);
        addressSplit1 = addressSplit1.join(" ");
        console.log("addressSplit1", addressSplit1);
        let addressSplit2 = addressSplit1.split(" ");
        console.log("addressSplit2", addressSplit2);
        let pincode = addressSplit2.find((item) => {
          return !isNaN(item) && item.length === 6;
        });
        console.log("addressline2", addressSplit2);

        let newAddress = address.replace(city + ",", "");
        newAddress = newAddress.replace(state + ",", "");
        newAddress = newAddress.replace(state, "");
        newAddress = newAddress.replace(country, "");
        newAddress = newAddress.replace(pincode, "");
        newAddress = newAddress.replace(/,\s*$/, "");

        console.log("address here", newAddress);

        //  address_line2= address_line2.join(" ")
        //   console.log("address_line2",address_line2)
        setcAddress({
          ...caddress,
          // address_line2: newAddress,
          city,
          state,
          zip: pincode ? pincode : "",
          country,
          geopoint_lat: position.lat.toString(),
          geopoint_long: position.lng.toString(),
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  // useEffect(()=>{
  //     if(addressString){

  //         console.log("pincode",pincode)
  //     }
  // },[addressString])

  function geoFindMe() {
    function success(position) {
      setMapPosition({
        lat: Number(position.coords.latitude),
        lng: Number(position.coords.longitude),
      });
      setMarkerPosition({
        lat: Number(position.coords.latitude),
        lng: Number(position.coords.longitude),
      });
    }

    function error() {
      console.log("Unable to retrieve your location");
      setMapPosition({
        lat: 26.8466937,
        lng: 80.94616599999999,
      });
      setMarkerPosition({
        lat: 26.8466937,
        lng: 80.94616599999999,
      });
    }

    if (!navigator.geolocation) {
      console.log("Geolocation is not supported by your browser");
    } else {
      navigator.geolocation.getCurrentPosition(success, error);
    }
  }

  useEffect(() => {
    geoFindMe();
  }, []);

  useEffect(() => {
    if (workFlow === "EDIT") {
      setMapPosition({
        lat: Number(selectedLocation.geopoint_lat),
        lng: Number(selectedLocation.geopoint_long),
      });
      setMarkerPosition({
        lat: Number(selectedLocation.geopoint_lat),
        lng: Number(selectedLocation.geopoint_long),
      });
      setcAddress(selectedLocation);
    } else {
      geoFindMe();
    }
  }, [workFlow]);

  const addressHandler = () => {
    let resAddress_line1 = validation.checkForEmpty(
      caddress.address_line1,
      "Building/Flat"
    );
    let resAddress_line2 = validation.checkForEmpty(
      caddress.address_line1,
      "Full Address"
    );
    let resZip = validation.checkForEmpty(caddress.zip, "Zip");
    let resLandmark = validation.checkForEmpty(caddress.landmark, "Landmark");
    let resCity = validation.checkForEmpty(caddress.city, "City");
    let resState = validation.checkForEmpty(caddress.state, "state");
    setErrorMsgs({
      address_line1: resAddress_line1,
      zip: resZip,
      landmark: resLandmark,
      address_line2: resAddress_line2,
      city: resCity,
      state: resState,
    });

    setErrStyle({
      address_line1style:
        resAddress_line1.validation === false ? "map-error" : "success",
      landmarkstyle: resLandmark.validation === false ? "map-error" : "success",
      zipstyle: resZip.validation === false ? "map-error" : "success",
      address_line2style:
        resAddress_line2.validation === false ? "map-error" : "success",
      citystyle: resCity.validation === false ? "map-error" : "success",
      statestyle: resState.validation === false ? "map-error" : "success",
    });
    if (
      resAddress_line1.validation === true &&
      resZip.validation === true &&
      resLandmark.validation === true &&
      resAddress_line2.validation === true &&
      resCity.validation === true &&
      resState.validation === true
    ) {
      if (workFlow === "EDIT") {
        address[selectedIdx] = {
          ...caddress,
          geopoint_lat: caddress.geopoint_lat.toString(),
          geopoint_long: caddress.geopoint_long.toString(),
        };
      } else {
        setAddress([
          ...address,
          {
            ...caddress,
            geopoint_lat: caddress.geopoint_lat.toString(),
            geopoint_long: caddress.geopoint_long.toString(),
          },
        ]);
      }
      setErrStyle({
        address_line1style: null,
        zipstyle: null,
        landmarkstyle: null,
        address_line2style: null,
      });
      setErrorMsgs(null);
      setTimeout(() => {
        setWorkFlow("ADD");
      }, 500);
      setcAddress({
        address_line1: null,
        address_line2: null,
        landmark: null,
        city: null,
        state: null,
        country: null,
        zip: null,
        geopoint_lat: null,
        geopoint_long: null,
      });
      setMapVisibility(false);
    }
  };

  const onChangeHandler = (e) => {
    setErrStyle({
      ...errStyle,
      [e.target.name + "style"]: null,
    });

    setErrorMsgs({
      ...errorMsgs,
      [e.target.name]: null,
    });

    setcAddress({
      ...caddress,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    console.log("CADDRESs", caddress);
  }, [caddress]);

  useEffect(() => {
    getAddress(markerPosition);
  }, [markerPosition]);

  // useEffect(() => {
  //     console.log(caddress)
  // }, [caddress])
  return (
    <Modal
      size="xl"
      style={{ width: "100vw" }}
      // className="mapmodal"
      isOpen={isOpen}
    >
      <ModalBody>
        <Row>
          <Col style={{ minHeight: "500px", zIndex: 5 }} xl="7 pb-5 mt-2">
            {console.log("mapPosition", mapPosition)}
            {console.log("markerPosition", markerPosition)}
            <Map2
              id="myMap"
              options={{
                center: mapPosition,
                zoom: currentZoom,
              }}
              setMapPosition={setMapPosition}
              mapPosition={mapPosition}
              addressString={addressString}
              setcAddress={setcAddress}
              caddress={caddress}
              markerPosition={markerPosition}
              setMarkerPosition={setMarkerPosition}
              setPosition={setPosition}
              position={position}
              setCurrentZoom={setCurrentZoom}
            />

            {/* <h1 className="mt-2">Address</h1>
                    <h3 className="text-muted">{addressString}</h3> */}
            {/* </div> */}
          </Col>
          <Col xl="5">
            <form className="border-left mapForm formstyle">
              <div className="formgroup">
                <legend>
                  <i className="fas fa-map-marker-alt mr-3"></i>
                  Add Location
                </legend>
              </div>

              <div className="formgroup preIcon ">
                <LocationSearchInput
                  setMapPosition={setMapPosition}
                  setMarkerPosition={setMarkerPosition}
                  setcAddress={setcAddress}
                  caddress={caddress}
                  address={address}
                  setAddress={setAddress}
                  inputAddress={inputAddress}
                  setInputAddress={setInputAddress}
                  setPosition={setPosition}
                />
              </div>

              <div className="formgroup preIcon">
                <label></label>
                <i className="far fa-building"></i>
                <input
                  name="address_line1"
                  id="address_line1"
                  className={errStyle.address_line1style}
                  value={caddress.address_line1}
                  onChange={onChangeHandler}
                  placeholder="Enter Building/Flat address"
                />
              </div>
              <div className="formgroup-err">
                {!(
                  errorMsgs &&
                  errorMsgs.address_line1 &&
                  errorMsgs.address_line1.validation
                ) && (
                  <small className="errText">
                    {errorMsgs &&
                      errorMsgs.address_line1 &&
                      errorMsgs.address_line1.errMsg}
                  </small>
                )}
              </div>
              <div className="formgroup preIcon mt-2">
                <i className="fas fa-landmark"></i>
                <input
                  name="landmark"
                  className={errStyle.landmarkstyle}
                  value={caddress.landmark}
                  onChange={onChangeHandler}
                  placeholder="Enter Landmark "
                />
              </div>
              <div className="formgroup-err">
                {!(
                  errorMsgs &&
                  errorMsgs.landmark &&
                  errorMsgs.landmark.validation
                ) && (
                  <small className="errText">
                    {errorMsgs &&
                      errorMsgs.landmark &&
                      errorMsgs.landmark.errMsg}
                  </small>
                )}
              </div>
              <div className="formgroup preIcon mt-2">
                <i className="far fa-building"></i>
                <input
                  name="address_line2"
                  className={errStyle.address_line2style}
                  value={caddress.address_line2}
                  onChange={onChangeHandler}
                  placeholder="Enter Address Line "
                />
              </div>
              <div className="formgroup-err">
                {!(
                  errorMsgs &&
                  errorMsgs.address_line2 &&
                  errorMsgs.address_line2.validation
                ) && (
                  <small className="errText">
                    {errorMsgs &&
                      errorMsgs.address_line2 &&
                      errorMsgs.address_line2.errMsg}
                  </small>
                )}
              </div>
              <div className="formgroup mt-2">
                {/* <i class="fas fa-city text-gray"></i> */}
                <input
                  name="city"
                  className={errStyle.citystyle}
                  value={caddress.city}
                  onChange={onChangeHandler}
                  placeholder="Enter City "
                />
              </div>
              <div className="formgroup-err">
                {!(
                  errorMsgs &&
                  errorMsgs.city &&
                  errorMsgs.city.validation
                ) && (
                  <small className="errText">
                    {errorMsgs && errorMsgs.city && errorMsgs.city.errMsg}
                  </small>
                )}
              </div>
              <div className="formgroup mt-2">
                <input
                  name="state"
                  className={errStyle.statestyle}
                  value={caddress.state}
                  onChange={onChangeHandler}
                  placeholder="Enter State "
                />
              </div>
              <div className="formgroup-err">
                {!(
                  errorMsgs &&
                  errorMsgs.state &&
                  errorMsgs.state.validation
                ) && (
                  <small className="errText">
                    {errorMsgs && errorMsgs.state && errorMsgs.state.errMsg}
                  </small>
                )}
              </div>
              <div className="formgroup mt-2">
                <input
                  name="zip"
                  value={caddress.zip}
                  className={errStyle.zipstyle}
                  onChange={onChangeHandler}
                  placeholder="Enter Pincode "
                />
              </div>
              <div className="formgroup-err">
                {!(errorMsgs && errorMsgs.zip && errorMsgs.zip.validation) && (
                  <small className="errText">
                    {errorMsgs && errorMsgs.zip && errorMsgs.zip.errMsg}
                  </small>
                )}
              </div>
              <div>
                <div className="formgroup d-flex ">
                  <Button
                    onClick={addressHandler}
                    className="mt-3  text-white"
                    style={{
                      marginLeft: "auto",
                      background: "#7c4dff",
                    }}
                  >
                    {workFlow === "EDIT" ? "UPDATE" : "ADD"}
                  </Button>
                  <Button
                    onClick={() => {
                      setTimeout(() => {
                        setWorkFlow("ADD");
                      }, 500);
                      setMapVisibility(false);
                      setcAddress({
                        address_line1: null,
                        address_line2: null,
                        landmark: null,
                        city: null,
                        state: null,
                        country: null,
                        zip: null,
                        geopoint_lat: null,
                        geopoint_long: null,
                      });
                    }}
                    className="mt-3 text-white"
                    style={{
                      background: "#f5365c",
                      color: "white",
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default Map;
