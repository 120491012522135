/* eslint-disable react-hooks/exhaustive-deps */
import apis from "apis";
import SelectComponent from "components/customSelect/Select.Component";
import EmptyTablePlaceHolder from "components/EmptyTableReplacement.js/EmptyTablePlaceHolder";
import { ConvertToExcel } from "components/excel/CovertToExcel";
import Pagination2 from "components/pagination/Pagination2";
import KK_Toast from "components/Toast/KK_Toast";
import { API_SERVER_URL2 } from "../../../constants";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import {
  Col,
  Row,
  Table,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Input,
} from "reactstrap";
import { logout } from "redux/login/login.action";
import moment from "moment";

const AppliedJobList = ({ authToken }) => {
  const [toastConfig, setToastConfig] = useState({
    toastStatus: false,
    message: "",
    severity: null,
  });
  const [jobList, setJobList] = useState([]);
  const [selectedJobId, setSelectedJobId] = useState("");
  const [tab, setTab] = useState("Applied");
  const [activeStyle, setActiveTabStyle] = useState({
    Appliedstyle: "active-tab",
  });
  const [activeBtnStyle, setActiveBtnStyle] = useState({
    Appliedstyle: "bounce-1",
  });
  const [candidateList, setCandidateList] = useState([]);
  const [appliedList, setAppliedList] = useState([]);
  const [exportAppliedList, setExportAppliedList] = useState([]);
  const [jobId, setJobId] = useState(null);
  const [status, setStatus] = useState(1);
  const auth_token = useSelector((state) => state.login.data.auth_token);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageOffset, setPageOffset] = useState({
    start: 0,
    end: 9,
  });
  const [totalPages, setTotalPages] = useState(0);
  const [searchedList,setSearchedList] = useState([]);
  const [searchedText,setSearchedText] = useState("");
  // const [btnStatus, setbtnStatus]
  const recordsPerPage = 10

  useEffect(async () => {
    try {
      let list = await apis.masters.getJobListDirectly();
      // console.log("list", list);
      // console.log("item.upload_cv")
      let jobList = list.data.resultData.map((item) => {
        return {
          id: item.id,
          job_title: item.job_title,
        };
      });
      setJobList(jobList);
    } catch (err) {
      if (err.response && err.response && err.response.status === 401) {
        setToastConfig({
          toastStatus: true,
          message: "Session Expired!",
          severity: "error",
        });
        setTimeout(() => {
          logout();
        }, 2000);
      }
      console.log(err);
    }
    getCandidates();
  }, []);

  const onSearch = (e) =>{
    setSearchedText(e.target.value)
  }
  useEffect(()=>{
    let searchedList = updatedCandidateList && updatedCandidateList?.filter(i=>(
      i.applied_for?.toLowerCase().includes(searchedText.toLowerCase())||
      i.job_category?.toLowerCase().includes(searchedText.toLowerCase())||
      i.full_name?.toLowerCase().includes(searchedText.toLowerCase())||
      i.candidate_name?.toLowerCase().includes(searchedText.toLowerCase())||
      i.mobile_no?.toLowerCase().includes(searchedText.toLowerCase())||
      i.email_id?.toLowerCase().includes(searchedText.toLowerCase())||
      i.skills?.toLowerCase().includes(searchedText.toLowerCase())||
      i.education_qualification?.toLowerCase().includes(searchedText.toLowerCase())
      ))
      console.log("Searched List",searchedList)
      setCandidateList(searchedList)
      if(searchedText.length===0){
        console.log("Control came here")
        getCandidates()
      }
  },[searchedText])

  const onChangeHandler = (e) => {
    setJobId(e.target.value);
  };

  const onChangeNext = () => {
    setPageOffset({
      start: pageOffset.start + recordsPerPage,
      end: pageOffset.end + recordsPerPage,
    });
    console.log("Length", totalPages);

    setPageNumber(pageNumber + 1);
  };

  const onChangePrev = () => {
    setPageOffset({
      start: pageOffset.start - recordsPerPage,
      end: pageOffset.end - recordsPerPage,
    });

    setPageNumber(pageNumber - 1);
  };

  const selectOrRejectCandidate = async (job_application_id, status) => {
    let body = {
      job_application_id,
      process_status: status,
    };
    console.log("BODY", body, auth_token);
    try {
      let res = await apis.candidates.selectOrRejectCandidateApplication(
        auth_token,
        body
      );
      if (res.success) {
        setToastConfig({
          toastStatus: true,
          message: res.message,
          severity: "success",
        });
        getCandidates();
      }
    } catch (err) {
      if (err.response && err.response && err.response.status === 401) {
        setToastConfig({
          toastStatus: true,
          message: "Session Expired!",
          severity: "error",
        });
        setTimeout(() => {
          logout();
        }, 2000);
      } else {
        setToastConfig({
          toastStatus: true,
          message: "Something Went Wrong!",
          severity: "error",
        });
      }
    }
  };

  const getCandidates = async () => {
    try {
      let res = await apis.candidates.getCandidatesWithProcessStatus(
        auth_token,
        jobId,
        status
      );
      console.log(res);
      if (res.success) {
        console.log("list", res.data);
		let remainder = res.data.length % recordsPerPage;
        let limit = parseInt(res.data.length / recordsPerPage);
        if (remainder !== 0) {
          limit = limit + 1;
        }
        setTotalPages(limit);
        setCandidateList(res.data);
        setAppliedList(res.data);
      } else {
        setToastConfig({
          toastStatus: true,
          message: res.message,
          severity: "error",
        });
        setCandidateList([]);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setToastConfig({
          toastStatus: true,
          message: "Session Expired!",
          severity: "error",
        });
        setTimeout(() => {
          logout();
        }, 2000);
      }
      console.error(err.response);
    }
  };
  useEffect(() => {
    console.log(candidateList);
  }, [candidateList]);

  const onActive = (e) => {
    setActiveTabStyle({
      [e.target.name + "style"]: "active-tab",
    });
    setActiveBtnStyle({
      [e.target.name + "style"]: "bounce-1",
    });
    setTab(e.target.name);
    if (e.target.name === "Applied") {
      setStatus(1);
    }
    if (e.target.name === "Selected") {
      setStatus(2);
    }
    if (e.target.name === "Rejected") {
      setStatus(3);
    }
  };

  useEffect(() => {
    console.log(jobId, status);
    getCandidates();
  }, [status, jobId]);
  // useEffect(()=>{
  //     if(tab==="Applied"){

  //     }
  // },[tab])


  // useEffect(()=>{
  //   resp = candidateList && candidateList.map((data) => <>
  //   {added_date: data.added_date, candidate_id:data.candidate_id, education_qualification: data.education_qualification, email_id: data.email_id, experience: data.experience, full_name: data.full_name, job_application_id: data.job_application_id, job_category: data.job_category, job_id: data.job_id, job_title: data.job_title, mobile_no: data.mobile_no, process_status: data.process_status, skills: data.skills, upload_aadhaar: data.upload_aadhaar, upload_resume: data.upload_resume ? "https://apimob.kaamkaz.com/" + data.upload_resume : null}
  //   </>),[])

    // Show all EM client list
  let updatedCandidateList =
  candidateList &&
  candidateList.map((data) => {
    let candidateList = {
      full_name: `${data.full_name || data.candidate_name}`,
      email_id: `${data.email_id}`,
      job_category_user: `${data.job_category_user}`,
      skills: `${data.skills}`,
      experience: `${data.experience}`,
      education_qualification: `${data.education_qualification}`,
      job_title: `${data.job_title || data.job_category}`,
      job_category: `${data.job_category || data.job_category}`,
      job_skills: `${data.job_skills || data.job_skills}`,
      job_type: `${data.job_type || data.job_type}`,
      upload_resume: data.upload_resume || data.Resume ? <a  rel="noreferrer" href={`${API_SERVER_URL2}/${data.upload_resume || data.Resume}`} target="_blank">View document</a>: null,
      upload_aadhaar: <a  rel="noreferrer" href={`${API_SERVER_URL2}/${data.upload_aadhaar || data.Aadhaar}`} target="_blank">View document</a>,
      mobile_no:`${data.mobile_no}`,
      candidate_id: `${data.candidate_id}`,
      job_application_id: `${data.job_application_id || data.application_id}`,
      job_id: `${data.job_id}`,
      // process_status: `${status === 1 ? "Applied" : null && status === 2 ? "Rejected" : null && status === 3 ? "Selected" :null}`,
      process_status: `${status === 1 ? "Applied" :null || status === 3 ? "Rejected": null || status === 2 ? "Selected" : null}`,
      added_date: `${data.added_date && moment(data.added_date).utc().format("DD-MM-YYYY, hh:mm:ss A")}`,
    };
    return candidateList;
  });


// String year = credits < 30 ? "freshman" : credits <= 59 ? "sophomore" : credits <= 89 ? "junior" : "senior";

  const generateThs = () => {
    let res =
    updatedCandidateList.length > 0 &&
    updatedCandidateList
        .filter((data, idx) => idx < 1)
        .map((data) => {
          let values = Object.getOwnPropertyNames(data);
          return values.map((item) => {
            return (
              <th className="text-white" scope="col">
                {item}
              </th>
            );
          });
        });
    return res;
  };

  const generateTds = () => {
    //    let tdList=[]
    //    let res= []
    console.log("updatedCandidateList", updatedCandidateList)
    let res =
    updatedCandidateList.length > 0 &&
    updatedCandidateList.filter((i, idx) => idx >= pageOffset.start && idx <= pageOffset.end).map((data) => {
        let values = Object.values(data);
        return (
          <tr>
            {status === 2 ? (
              <td className="text-right">
                <i className="fas fa-circle text-green"></i>
              </td>
            ) : null}
            {status === 3 ? (
              <td className="text-right">
                <i className="fas fa-circle text-red"></i>
              </td>
            ) : null}
            {values.map((item) => {
              return (
                <>
                  <td scope="col">{item}</td>
                </>
              );
            })}
            {status === 1 && (
              <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    // href="#pablo"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      name="Select"
                      onClick={(e) => {
                        selectOrRejectCandidate(data.application_id || data.job_application_id, 2);
                      }}
                    >
                      <i className="fas fa-circle text-green"></i>
                      SELECT
                    </DropdownItem>
                    <DropdownItem
                      name="Reject"
                      onClick={(e) => {
                        selectOrRejectCandidate(data.application_id || data.job_application_id, 3);
                      }}
                    >
                      <i className="fas fa-circle text-red"></i>
                      REJECT
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td>
            )}
          </tr>
        );
      });
    return res;
  };
  // To Export candate data
  const exportAppliedData = () => {
    let resData = [];
    appliedList && appliedList.map((item) => {
      <>
      {
        resData.push({
          "Full Name": item.full_name,
          "Email ID": item.email_id,
          "Job Category (User)": item.job_category_user,
          "Skiils (User)": item.skills,
          "Experience": item.experience,
          "Educational Qualification": item.education_qualification,
          "Job Title": item.job_title,
          "Job Category": item.job_category,
          "Job Skills": item.job_skills,
          "Job Type": item.job_type,
          "Upload Resume": item.upload_resume,
          "Upload Aadhar": item.upload_aadhaar,
          "Mobile Number": item.mobile_no,
          "Candidate Id": item.candidate_id,
          "Job Application Id": item.job_application_id,
          "Job Id": item.job_id,
          "Process Status": item.process_status === 1 ? "Applied" : item.process_status === 2 ? "Selected" : "Rejected",
          "Added Date": item.added_date && moment(item.added_date).utc().format("DD-MM-YYYY, hh:mm:ss A"),
        })
      }
      </>
    })
    setExportAppliedList(resData)
  }
  useEffect(() => {
    exportAppliedData();
  },[appliedList])

  
  return (
    <div className="pr-2 pl-2 container-fluid">
      <Row className="mt-8 text-center w3-animate-top">
        <Col>
          <KK_Toast setToastConfig={setToastConfig} toastConfig={toastConfig} />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col
          xl="4"
          className={`d-flex justify-content-center tab-border ${activeStyle.Appliedstyle} `}
        >
          <button
            className={`tab-style ${activeBtnStyle.Appliedstyle}`}
            onClick={onActive}
            name="Applied"
          >
            {"Applied Candidates".toLocaleUpperCase()}
          </button>
        </Col>
        <Col
          xl="4"
          className={`d-flex justify-content-center tab-border ${activeStyle.Rejectedstyle}`}
        >
          <button
            className={`tab-style ${activeBtnStyle.Rejectedstyle}`}
            onClick={onActive}
            name="Rejected"
          >
            {"Rejected Candidates".toLocaleUpperCase()}
          </button>
        </Col>
        <Col
          xl="4"
          className={`d-flex justify-content-center tab-border ${activeStyle.Selectedstyle}`}
        >
          <button
            className={`tab-style ${activeBtnStyle.Selectedstyle}`}
            onClick={onActive}
            name="Selected"
          >
            {"Selected Candidates".toLocaleUpperCase()}
          </button>
        </Col>
        {/* <Col className={`d-flex justify-content-center tab-border`}><button name="Tab4">Tab4</button></Col> */}
      </Row>
      <Row className="mt-6 text-center w3-animate-top">
        <Col xl="4" className="d-flex">
          <Input placeholder="Search" type="text" className="w-75" onChange={onSearch}/>
        </Col>
        <Col xl="4" className="d-flex justify-content-center ">
          <SelectComponent
          className="w-75"
            name="job_category"
            onChange={onChangeHandler}
            
          >
            <option>Select Job</option>
            {jobList.map((job, idx) => {
              return (
                <option key={idx} value={job.id}>
                  {job.job_title}
                </option>
              );
            })}
          </SelectComponent>
        </Col>
        <Col xl="4">
        <ConvertToExcel
                style={{}}
                dataToCovert={exportAppliedList}
                filename="appliedJobList.csv"
                btnName={`Download ${status === 1 ? "Applied" : status === 2 ? "Selected" : "Rejected"} Candidate List`}
              />
        </Col>
      </Row>

      <Row className="text-center mt-3">
        <Col xl="12" style={{ overflowX:"hidden"}}>
          {updatedCandidateList && updatedCandidateList.length > 0 ? (
            <div className="table-responsive" style={{overflowX:"hidden"}}>
              <Table
                className="align-items-center table-condensed table-striped mt-3 w3-animate-bottom"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  // width: "550px",
                  backgroundColor: "white",
                }}
                responsive
              >
                <thead className="thead-dark ">
                  {(status === 2 || status === 3) && (
                    <>
                    <th className="text-white" scope="col">
                      Indicator
                    </th>
                    <th className="text-white" scope="col">FULL NAME</th>
                      <th className="text-white" scope="col">EMAIL ID</th>
                      <th className="text-white" scope="col">JOB CATEGORY (USER)</th>
                      <th className="text-white" scope="col">SKILLS (USER)</th>
                      <th className="text-white" scope="col">EXPERIENCE</th>
                      <th className="text-white" scope="col">EDUCATION QUALIFICATION</th>
                      <th className="text-white" scope="col">JOB TITLE</th>
                      <th className="text-white" scope="col">JOB CATEGORY</th>
                      <th className="text-white" scope="col">JOB SKILLS</th>
                      <th className="text-white" scope="col">JOB TYPE</th>
                      <th className="text-white" scope="col">UPLOADED RESUME</th>
                      <th className="text-white" scope="col">UPLOADED AADHAAR</th>
                      <th className="text-white" scope="col">MOBILE NO</th>
                      <th className="text-white" scope="col">CANDIDATE ID</th>
                      <th className="text-white" scope="col">JOB APPLICATION ID</th>
                      <th className="text-white" scope="col">JOB ID</th>
                      <th className="text-white" scope="col">PROCESS STATUS</th>
                      <th className="text-white" scope="col">ADDED DATE</th>
                    </>
                  )}
                  {/* {generateThs()} */}
                  {status === 1 && (
                    <>
                      <th className="text-white" scope="col">FULL NAME</th>
                      <th className="text-white" scope="col">EMAIL ID</th>
                      <th className="text-white" scope="col">JOB CATEGORY (USER)</th>
                      <th className="text-white" scope="col">SKILLS (USER)</th>
                      <th className="text-white" scope="col">EXPERIENCE</th>
                      <th className="text-white" scope="col">EDUCATION QUALIFICATION</th>
                      <th className="text-white" scope="col">JOB TITLE</th>
                      <th className="text-white" scope="col">JOB CATEGORY</th>
                      <th className="text-white" scope="col">JOB SKILLS</th>
                      <th className="text-white" scope="col">JOB TYPE</th>
                      <th className="text-white" scope="col">UPLOADED RESUME</th>
                      <th className="text-white" scope="col">UPLOADED AADHAAR</th>
                      <th className="text-white" scope="col">MOBILE NO</th>
                      <th className="text-white" scope="col">CANDIDATE ID</th>
                      <th className="text-white" scope="col">JOB APPLICATION ID</th>
                      <th className="text-white" scope="col">JOB ID</th>
                      <th className="text-white" scope="col">PROCESS STATUS</th>
                      <th className="text-white" scope="col">ADDED DATE</th>
                      <th className="text-white" scope="col">ACTION</th>
                    </>
                    // <th className="text-white" scope="col">
                    //   Actions
                    // </th>
                  )}
                </thead>
                <tbody>{generateTds()}</tbody>
              </Table>
              <Pagination2
			          style={{marginLeft:"auto",marginRight:"auto"}}
                pageNumber={pageNumber}
                pageOffset={pageOffset}
                length={totalPages}
                onChangeNext={onChangeNext}
                onChangePrev={onChangePrev}
				        recordsPerPage={recordsPerPage}
              />
            </div>
          ) : (
            <EmptyTablePlaceHolder>No Candidate Found</EmptyTablePlaceHolder>
          )}
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.login.data.auth_token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps())(AppliedJobList);
