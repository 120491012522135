/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import { Multiselect } from "multiselect-react-dropdown";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Button,
  CustomInput,
  Label,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { useSelector } from "react-redux";
import apis from "apis";
import { useHistory, useParams } from "react-router";
import KK_Toast from "components/Toast/KK_Toast";
import CustomFileInput from "components/Custom_file_input/CustomFileInput";
import SelectComponent from "components/customSelect/Select.Component";
import { validation } from "components/_helpers/validation";
import { Select } from "@material-ui/core";

const AddCandidate = ({
  setWorkFlow,
  setTab,
  workFlow,
  setToastConfig,
  toastStatus,
  candidateData,
}) => {
  const [resume, setResume] = useState(null);
  const auth_token = useSelector((state) => state.login.data.auth_token);
  const [skillList, setSkillList] = useState([]);
  const params = useParams();
  const history = useHistory();
  const pathName = history.location.pathname;
  const [inputClass, setInputClass] = useState("normal");
  const [selectedContent, setSelectedContent] = useState();
  const [tableData, setTableData] = useState([]);
  const [enteredValue, setEnteredValue] = useState("");
  const [operableData, setOperableData] = useState("");
  const [industryList, setIndustryList] = useState([]);
  const [experienceList, setExperienceList] = useState("");
  const [qualificationList, setqualificationList] = useState("");
  const [voterId, setVoterId] = useState(null);
  const [selectedCv, setSelectedCv] = useState();
  const [isCvPicked, setIsCvPicked] = useState(false);
  const [selectedIdentity, setSelectedIdentity] = useState();
  const [isIdentityPicked, setIsIdentityPicked] = useState(false);
  const [skillDisabled, setSkillDisable] = useState(true);
  const [industry_id, setIndustryId] = useState(null);
  const [resumePath, setResumePath] = useState("");
  const [idPath, setIdPath] = useState("");
  const [preSelectedSkillList, setPreSelectedSkillList] = useState([]);
  const [prevExperience, setPrevExperience] = useState({});

  // const [workFlow, setWorkFlow] = useState("ADD");
  const [enterMax, setMax] = useState(null);
  const [errStyle, setErrStyle] = useState({});
  const [formData, setFormData] = useState({
    full_name: null,
    mobile_no: null,
    email_id: null,
    gender: null,
    age: null,
    job_category: null,
    skills: null,
    experience: null,
    education_qualification: null,
    upload_resume: null,
    upload_aadhaar: null,
  });
  const [errorMsgs, setErrorMsgs] = useState();
  const [prevData, setPrevData] = useState({});

  const cvChangeHandler = (event) => {
    if (event.target.files[0] && event.target.files[0].size > 1050000) {
      console.log("filesize", event.target.files[0].size);

      return setToastConfig({
        toastStatus: true,
        message: "File size cannot be more than 1MB",
        severity: "error",
      });
    }
    // added else if block for stop calling API when data is empty
    else if (event.target.files[0] && event.target.files[0].size !== 0) {
      setSelectedCv(event.target.files[0]);
      setIsCvPicked(true);
    }
    // setSelectedCv(event.target.files[0])
    // setIsCvPicked(true)
  };
  const identityChangeHandler = (event) => {
    if (event.target.files[0] && event.target.files[0].size > 1050000) {
      return setToastConfig({
        toastStatus: true,
        message: "File size cannot be more than 1MB",
        severity: "error",
      });
    } else if (event.target.files[0] && event.target.files[0].size !== 0) {
      setSelectedIdentity(event.target.files[0]);
      setIsIdentityPicked(true);
    }
  };

  useEffect(() => {
    console.log("PrevD", prevData);
    if (workFlow === "EDIT") {
      setFormData({
        ...(prevData && prevData),
      });
      setIdPath(prevData.upload_aadhaar);
      setResumePath(prevData.upload_resume);
    }
  }, [prevData]);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    console.log(selectedCv);
    setErrorMsgs({
      ...errorMsgs,
      upload_resume: null,
    });
    async function uploadCv() {
      var bodyFormData = new FormData();
      bodyFormData.append("cv", selectedCv);
      try {
        let res = await apis.candidates.documentUpload(
          "/cv_upload",
          bodyFormData
        );
        console.log("upload cv", res);
        setResumePath(res.resultData.filePath);
        setFormData({
          ...formData,
          upload_resume: res.resultData.filePath,
        });
      } catch (err) {
        console.error(err.response);
        // setToastConfig({
        //   toastStatus: true,
        //     message: err.response.data.resultMsg,
        //     severity: "error",
        // })
        setResumePath("");
      }
    }
    uploadCv();
  }, [selectedCv]);

  useEffect(() => {
    console.log(selectedIdentity);
    setErrorMsgs({
      ...errorMsgs,
      upload_aadhaar: null,
    });
    async function uploadIdentity() {
      var bodyFormData = new FormData();
      bodyFormData.append("doc", selectedIdentity);
      try {
        let res = await apis.candidates.documentUpload(
          "/doc_upload",
          bodyFormData
        );
        console.log(res);

        setIdPath(res.resultData.filePath);
        setFormData({
          ...formData,
          upload_aadhaar: res.resultData.filePath,
        });
      } catch (err) {
        console.error(err.response);
        // setToastConfig({
        //   toastStatus: true,
        //     message: err.response.data.resultMsg,
        //     severity: "error",
        // })
        setIdPath("");
        setFormData({
          ...formData,
          upload_aadhaar: null,
        });
      }
    }
    uploadIdentity();
  }, [selectedIdentity]);

  const getSkillList = async (industry_id) => {
    try {
      let res = await apis.masters.getPublicAllRecords(
        `/api/v1/master/jobSkillByIndustryId/${industry_id}`
      );

      return res.data;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    let prevSkills = prevData && prevData.skills && prevData.skills.split(",");
    let finalList = skillList.filter(
      (skill) => prevSkills && prevSkills.indexOf(skill.name) != -1
    );
    setPreSelectedSkillList(finalList);
  }, [skillList]);

  useEffect(() => {
    if (formData.job_category) {
      let industryId =
        industryList &&
        industryList.filter((i) => i.name === formData.job_category);
      setIndustryId(industryId[0]);
    }
    console.log("FormData", formData);
  }, [formData]);

  useEffect(async () => {
    if (industry_id) {
      try {
        let skills = await getSkillList(industry_id.id);
        setSkillList(skills);
        setSkillDisable(false);
      } catch (err) {
        console.log(err);
      }
    }
  }, [industry_id]);

  useEffect(() => {
    console.log("voterId", voterId);
    setPreSelectedSkillList(null);
  }, [voterId, formData.job_category]);

  //Component Did mount
  useEffect(async () => {
    //api call to populate the table as per the req in content object
    getAllRecords("/api/v1/master/jobCandidate");
    try {
      let skills = await apis.masters.getAllRecords(
        auth_token,
        "/api/v1/master/jobSkill"
      );
      let industryList = await apis.masters.getAllRecords(
        auth_token,
        "/api/v1/master/jobCategory"
      );
      let qualificationList = await apis.masters.getAllRecords(
        auth_token,
        "/api/v1/master/educationalQualification/"
      );
      let experienceList = await apis.masters.getAllRecords(
        auth_token,
        "/api/v1/master/jobExperience"
      );
      if (skills.success === true) {
        setSkillList(skills.data);
      }
      if (industryList.success === true) {
        setIndustryList(industryList.data);
      }
      if (qualificationList.success === true) {
        setqualificationList(qualificationList.data);
      }
      if (experienceList.success === true) {
        setExperienceList(experienceList.data);
      }
    } catch (error) {
      console.log(error);
    }

    if (candidateData && candidateData.user_id) {
      console.log("CANDIDATE DATA", candidateData);
      setPrevData({
        ...candidateData,
      });
    }
  }, []);

  const onSelect = (e) => {
    setErrorMsgs({
      ...errorMsgs,
      skills: null,
    });
    console.log("E FROM MULTI SELECT", e);
    let ls = e.map((i) => i.name).toString();
    let res = validation.checkForEmpty(ls, "Skill");
    console.log("Skill Response", res);
    if (res.validation === true) {
      setErrorMsgs({
        ...errorMsgs,
        skills: {
          ...res,
        },
      });
      setErrStyle({ ...errStyle, skillsstyle: null });
      return setFormData({
        ...formData,
        skills: ls,
      });
    } else {
      setErrorMsgs({
        skills: {
          ...res,
        },
      });
      return setFormData({
        ...formData,
        skills: ls,
      });
    }
  };

  const onRemove = (e) => {
    setErrorMsgs({
      ...errorMsgs,
      skills: null,
    });
    console.log("E FROM MULTI SELECT", e);
    let ls = e.map((i) => i.name).toString();
    let res = validation.checkForEmpty(ls, "Skill");
    console.log("Skill Response", res);
    if (res.validation === true) {
      setErrorMsgs({
        ...errorMsgs,
        skills: {
          ...res,
        },
      });
      return setFormData({
        ...formData,
        skills: ls,
      });
    } else {
      setErrorMsgs({
        skills: {
          ...res,
        },
      });
      return setFormData({
        ...formData,
        skills: ls,
      });
    }
  };

  const onChangeHandler = (e) => {
    setErrStyle({
      ...errStyle,

      [e.target.name + "style"]: null,
    });
    setErrorMsgs({
      ...errorMsgs,
      [e.target.name]: null,
    });

    if (e.target.name === "mobile_no") {
      let res = validation.onlyNumberCheck(e.target.value);
      if (res.validation === true) {
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: { ...res },
        });
        setErrStyle({
          ...errStyle,
          [e.target.name + "style"]: null,
        });

        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      } else {
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: { ...res },
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "email_id") {
      let res = validation.emailCheck(e.target.value);
      console.log("Email Res", res);
      if (res.validation === true) {
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: {
            ...res,
          },
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      } else {
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: {
            ...res,
          },
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "full_name") {
      let res = validation.fullnameCheck(e.target.value);
      console.log("Email Res", res);
      if (res.validation === true) {
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: {
            ...res,
          },
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      } else {
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: {
            ...res,
          },
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "job_category") {
      let res = validation.checkForEmpty(e.target.value, "Job category");
      console.log("Email Res", res);
      if (res.validation === true) {
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: {
            ...res,
          },
        });
        console.log("errormsg");
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      } else {
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: {
            ...res,
          },
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
    }
    // gender data
    else if (e.target.name === "gender") {
      let res = validation.checkForEmpty(e.target.value, "Gender");
      console.log("Gender res", res);
      if (res.validation === true) {
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: {
            ...res,
          },
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      } else {
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: {
            ...res,
          },
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "age") {
      let res = validation.ageCheck(e.target.value, "Age");
      console.log("Age res", res);
      if (res.validation === true) {
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: {
            ...res,
          },
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
          // [e.target.name]: e.target.value.length === 0?"NA":e.target.value,
        });
      } else {
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: {
            ...res,
          },
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "experience") {
      let res = validation.checkForEmpty(e.target.value, "Experience");
      console.log("Email Res", res);
      if (res.validation === true) {
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: {
            ...res,
          },
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      } else {
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: {
            ...res,
          },
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
    } else if (e.target.name === "education_qualification") {
      let res = validation.checkForEmpty(
        e.target.value,
        "Education Qualification"
      );
      console.log("Email Res", res);
      if (res.validation === true) {
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: {
            ...res,
          },
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      } else {
        setErrorMsgs({
          ...errorMsgs,
          [e.target.name]: {
            ...res,
          },
        });
        return setFormData({
          ...formData,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  useEffect(() => {
    console.log(enteredValue);
  }, [enteredValue]);

  useEffect(() => {
    setEnteredValue(operableData.name);
    console.log("workFlow", workFlow);
  }, [workFlow]);

  const getAllRecords = async (path) => {
    try {
      let res = await apis.masters.getAllRecords(path);
      console.log("Real Response", res);
      if (res.success === true) {
        setTableData(res.data);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {}, [qualificationList, experienceList, industryList]);
  useEffect(() => {
    console.log("FORM dATA", formData);
  }, [formData]);
  useEffect(() => {
    console.log("ERROR LISt", errorMsgs);
  }, [errorMsgs]);

  const actionHandler = async (e) => {
    let resfull_name = validation.checkForEmpty(
      formData.full_name,
      "Full name"
    );
    let resmobile_no = validation.checkForEmpty(
      formData.mobile_no,
      "Mobile number"
    );
    let resjob_category = validation.checkForEmpty(
      formData.job_category,
      "Job category"
    );
    let resemail = validation.emailCheck(formData.email_id);
    console.log("Resmail", resemail);
    let resgender = validation.checkForEmpty(formData.gender, "Gender");
    let resage = validation.checkForEmpty(formData.age, "Age");

    let resskills = validation.checkForEmpty(formData.skills, "Skills");
    let resexperience = validation.checkForEmpty(
      formData.experience,
      "Experience"
    );
    let reseducation_qualification = validation.checkForEmpty(
      formData.education_qualification,
      "Education qualification"
    );

    setErrorMsgs({
      ...errorMsgs,
      full_name: { ...resfull_name },
      mobile_no: { ...resmobile_no },
      job_category: { ...resjob_category },
      email_id: { ...resemail },
      gender: { ...resgender },
      age: { ...resage },
      skills: { ...resskills },
      experience: { ...resexperience },
      education_qualification: { ...reseducation_qualification },
    });

    setErrStyle({
      ...errStyle,
      full_namestyle: resfull_name.validation === false ? "error" : "success",
      mobile_nostyle: resmobile_no.validation === false ? "error" : "success",
      job_categorystyle:
        resjob_category.validation === false ? "error" : "success",
      emailstyle: resemail.validation === false ? "error" : "success",
      genderstyle: resgender.validation === false ? "error" : "success",
      agestyle: resage.validation === false ? "error" : "success",
      skillsstyle:
        resskills.validation === false
          ? "jobskills_error"
          : "jobskills_success",
      experiencestyle: resexperience.validation === false ? "error" : "success",
      education_qualificationstyle:
        reseducation_qualification.validation === false ? "error" : "success",
    });

    let res = {};

    let formDataKeys = Object.keys(formData);
    formDataKeys.map((i) => {
      res["res" + i] = null;
    });

    // formDataKeys.map((i) => {
    //   if (i === 'job_category') {
    //     res['res' + i] = validation.checkForEmpty(formData[i], 'Industry')
    //   } else {
    //     res['res' + i] = validation.checkForEmpty(formData[i], i)
    //   }
    // })

    formDataKeys.map((i) => {
      if (i === "job_category") {
        res["res" + i] = validation.checkForEmpty(formData[i], "Job category");
      } else if (i === "full_name") {
        res["res" + i] = validation.checkForEmpty(formData[i], "Full name");
      } else if (i === "mobile_no") {
        res["res" + i] = validation.checkForEmpty(formData[i], "Mobile number");
      } else if (i === "education_qualification") {
        res["res" + i] = validation.checkForEmpty(
          formData[i],
          "Education qualification"
        );
      } else {
        res["res" + i] = validation.checkForEmpty(formData[i], i);
      }
    });

    let errmsg = {};
    formDataKeys.map((i) => {
      errmsg[i] = { ...res["res" + i] };
    });
    let idRes = validation.checkForEmpty(idPath, "Identity proof");
    console.log("id Path", idPath);

    console.log("Validation Check 1", idRes);

    console.log("Validation Check 2", errmsg);

    errmsg = {
      ...errmsg,
      ...errorMsgs,
      upload_aadhaar: idRes,
    };

    setErrorMsgs({
      ...errmsg,
    });

    console.log("Main Err", errmsg);

    const {
      full_name,
      mobile_no,
      job_category,
      gender,
      age,
      skills,
      experience,
      education_qualification,
      // upload_aadhaar,
    } = errmsg;

    console.log(
      full_name.validation &&
        mobile_no.validation &&
        job_category.validation &&
        gender.validation &&
        age.validation &&
        skills.validation &&
        experience.validation &&
        education_qualification.validation
      // upload_aadhaar.validation
    );

    //  if(!!(full_name && mobile_no && email_id && job_category && skills && experience && education_qualification && upload_aadhaar)){
    if (
      full_name.validation &&
      mobile_no.validation &&
      gender.validation &&
      age.validation &&
      job_category.validation &&
      skills.validation &&
      experience.validation &&
      education_qualification.validation
      // upload_aadhaar.validation
    ) {
      const {
        full_name,
        mobile_no,
        email_id,
        job_category,
        gender,
        age,
        skills,
        experience,
        education_qualification,
        upload_aadhaar,
        upload_resume,
      } = formData;
      const body = {
        full_name: full_name.trim(),
        mobile_no: mobile_no.trim(),
        job_category: job_category.trim(),
        gender: gender.trim(),
        age: age,
        skills: skills.trim(),
        experience: experience.trim(),
        education_qualification: education_qualification.trim(),
        upload_aadhaar: upload_aadhaar,
        email_id: email_id ? email_id : "",
        upload_resume: upload_resume ? upload_resume : "",
      };
      console.log("body", body);
      if (email_id && email_id.length > 0) body.email_id = email_id.trim();
      if (prevData.user_id) body.user_id = prevData.user_id;
      if (upload_resume && upload_resume.length > 0)
        body.upload_resume = upload_resume.trim();
      if (workFlow === "ADD") {
        try {
          let res = await apis.candidates.addCandidates(body);
          console.log(res);
          // if(res.resultCode===1){
          setToastConfig({
            toastStatus: true,
            message: "Candidate added successfully",
            severity: "success",
          });
          setTab("LIST");
          // }
        } catch (err) {
          console.log(err.response);
          setToastConfig({
            toastStatus: true,
            message: "mobile number already exists",
            severity: "error",
          });
        }
      } else if (workFlow === "EDIT") {
        console.log("EDIT WAS CALLED");
        try {
          let res = await apis.candidates.updateCandidate(body);
          console.log("response", res);
          // if(res.resultCode===1){
          setToastConfig({
            toastStatus: true,
            message: "Candidate record updated successfully.",
            severity: "success",
          });
          setWorkFlow("ADD");
          setTab("LIST");
          // }
        } catch (err) {
          console.error(err.response);
          setToastConfig({
            toastStatus: true,
            message: "Mobile number already exists",
            severity: "error",
          });
        }
      }
    }
  };

  return (
    <>
      <Card style={{ width: "100%" }}>
        <CardHeader className="pl-4 pt-2 pb-0">
          {workFlow === "EDIT" ? (
            <Form.Label>Edit Candidate</Form.Label>
          ) : (
            <Form.Label>Add Candidate</Form.Label>
          )}
        </CardHeader>
        <CardBody className="pt-3 pb-0">
          <Form id="myform" autoComplete="none">
            <Row className="p-0">
              {/* <Col xs="12 " sm={selectedContent && selectedContent.id === 2 ? "12 pl-3" : "9 pl-3"} md={selectedContent && selectedContent.id === 2 ? "12 pl-3" : "9 pl-3"} lg={selectedContent && selectedContent.id === 2 ? "12 pl-3" : "9 pl-3"}> */}
              {/* <Col xs="12"> */}
              <Col className="custombrdrright" md="12" lg="6" xl="6  p-4">
                <Form.Group controlId="full_name">
                  <Form.Label className="mandatory">Full Name</Form.Label>
                  <Form.Control
                    name="full_name"
                    maxLength={50}
                    autoComplete="none"
                    className={errStyle.full_namestyle}
                    type="text"
                    value={formData.full_name}
                    onChange={onChangeHandler}
                    placeholder="Enter Full Name"
                  />
                  {!(
                    errorMsgs &&
                    errorMsgs.full_name &&
                    errorMsgs.full_name.validation
                  ) && (
                    <small className="errText">
                      {errorMsgs &&
                        errorMsgs.full_name &&
                        errorMsgs.full_name.errMsg}
                    </small>
                  )}
                </Form.Group>
                <Form.Group controlId="email_id">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    name="email_id"
                    autoComplete="none"
                    maxLength={90}
                    className={errStyle.email_idstyle}
                    type="text"
                    value={formData.email_id}
                    onChange={onChangeHandler}
                    placeholder="Enter Email"
                  />
                  {/* {!(
                    errorMsgs &&
                    errorMsgs.email_id &&
                    errorMsgs.email_id.validation
                  ) && (
                    <small className='errText'>
                      {errorMsgs &&
                        errorMsgs.email_id &&
                        errorMsgs.email_id.errMsg}
                    </small>
                  )} */}
                </Form.Group>

                {/* Gender field */}
                <Form.Group controlId="gender">
                  <Form.Label className="mandatory">Gender</Form.Label>
                  <SelectComponent
                    name="gender"
                    value={formData.gender}
                    onChange={onChangeHandler}
                    style={{ width: "100%" }}
                    placeholder="Select Gender"
                    className={errStyle.genderstyle}
                  >
                    <option value="">Select Gender</option>
                    <option>Male</option>
                    <option>Female</option>
                    <option>Other</option>
                  </SelectComponent>
                  {!(
                    errorMsgs &&
                    errorMsgs.gender &&
                    errorMsgs.gender.validation
                  ) && (
                    <small className="errText">
                      {errorMsgs && errorMsgs.gender && errorMsgs.gender.errMsg}
                    </small>
                  )}
                </Form.Group>
                <Form.Group controlId="age">
                  <Form.Label>Age</Form.Label>
                  <Form.Control
                    name="age"
                    autoComplete="none"
                    type="text"
                    maxLength={2}
                    value={formData.age}
                    onChange={onChangeHandler}
                    placeholder="Enter Age"
                  />
                  {/* {!(
                    errorMsgs &&
                    errorMsgs.age &&
                    errorMsgs.age.validation
                  ) && (
                    <small className="errText">
                      {errorMsgs && errorMsgs.age && errorMsgs.age.errMsg}
                    </small>
                  )} */}
                </Form.Group>
                <Form.Group controlId="mobile_no">
                  <Form.Label className="mandatory">Mobile Number</Form.Label>
                  <Form.Control
                    name="mobile_no"
                    autoComplete="none"
                    className={errStyle.mobile_nostyle}
                    type="text"
                    maxLength={10}
                    disabled={workFlow === "EDIT" ? true : false}
                    value={formData.mobile_no}
                    onChange={onChangeHandler}
                    placeholder="Enter Mobile Number"
                  />
                  {!(
                    errorMsgs &&
                    errorMsgs.mobile_no &&
                    errorMsgs.mobile_no.validation
                  ) && (
                    <small className="errText">
                      {errorMsgs &&
                        errorMsgs.mobile_no &&
                        errorMsgs.mobile_no.errMsg}
                    </small>
                  )}
                </Form.Group>
                <Form.Group controlId="job_category">
                  <Form.Label className="mandatory">Job Category</Form.Label>
                  {/* <Form.Control
                    autoComplete="none"
                    className={errStyle.industry_type_idstyle}
                    placeholder="Select Industry"
                    value={formData.job_category}
                    style={{ width: "100%" }}
                    name="job_category"
                    onChange={onChangeHandler}
                    as="select"
                  >
                    <option value="">
                      Select Industry Type
                    </option>
                    {industryList &&
                      industryList.map((item,idx) => {
                        return (<option key={idx} >{item.name}</option>);
                      })}
                  </Form.Control> */}
                  <SelectComponent
                    name="job_category"
                    value={formData.job_categorystyle}
                    placeholder="Select Job Category"
                    style={{ width: "100%" }}
                    onChange={onChangeHandler}
                    className={errStyle.job_categorystyle}
                  >
                    <option value="" selected>
                      Select Job Category
                    </option>
                    {industryList &&
                      industryList.map((item) => {
                        return <option value={item.name}>{item.name}</option>;
                      })}
                  </SelectComponent>
                  {!(
                    errorMsgs &&
                    errorMsgs.job_category &&
                    errorMsgs.job_category.validation
                  ) && (
                    <small className="errText">
                      {errorMsgs &&
                        errorMsgs.job_category &&
                        errorMsgs.job_category.errMsg}
                    </small>
                  )}
                </Form.Group>
              </Col>
              <Col className="custombrdrright" md="12" lg="6" xl="6  p-4">
                {!skillDisabled && (
                  <Form.Group
                    controlId="skills"
                    className={errStyle.skillsstyle}
                  >
                    <Form.Label className="mandatory">Skills</Form.Label>
                    <Multiselect
                      className={errStyle.skillsstyle}
                      selectedValues={preSelectedSkillList}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      value={formData.skills}
                      options={skillList}
                      placeholder="Select Skills"
                      displayValue="name"
                      name="skills"
                    />
                    {!(
                      errorMsgs &&
                      errorMsgs.skills &&
                      errorMsgs.skills.validation
                    ) && (
                      <small className="errText">
                        {errorMsgs &&
                          errorMsgs.skills &&
                          errorMsgs.skills.errMsg}
                      </small>
                    )}
                  </Form.Group>
                )}

                <Form.Group controlId="experience">
                  <Form.Label className="mandatory">Experience</Form.Label>
                  <SelectComponent
                    value={formData.experience}
                    onChange={onChangeHandler}
                    name="experience"
                    style={{ width: "100%" }}
                    className={errStyle.experiencestyle}
                  >
                    <option value="" selected>
                      Select Experience
                    </option>
                    {experienceList &&
                      experienceList.map((item) => {
                        return (
                          <option value={item.min_exp + "-" + item.max_exp}>
                            {item.min_exp}-{item.max_exp}
                          </option>
                        );
                      })}
                  </SelectComponent>
                  {!(
                    errorMsgs &&
                    errorMsgs.experience &&
                    errorMsgs.experience.validation
                  ) && (
                    <small className="errText">
                      {errorMsgs &&
                        errorMsgs.experience &&
                        errorMsgs.experience.errMsg}
                    </small>
                  )}
                </Form.Group>
                <Form.Group controlId="education_qualification">
                  <Form.Label className="mandatory">
                    Educational Qualification
                  </Form.Label>
                  <SelectComponent
                    value={formData.education_qualification}
                    name="education_qualification"
                    onChange={onChangeHandler}
                    style={{ width: "100%" }}
                    className={errStyle.education_qualificationstyle}
                  >
                    <option value="" selected>
                      Select Education Qualification
                    </option>
                    {qualificationList &&
                      qualificationList?.map((item) => {
                        return <option value={item.name}>{item.name}</option>;
                      })}
                    {console.log("qualificationlist", qualificationList)}
                  </SelectComponent>
                  {!(
                    errorMsgs &&
                    errorMsgs.education_qualification &&
                    errorMsgs.education_qualification.validation
                  ) && (
                    <small className="errText">
                      {errorMsgs &&
                        errorMsgs.education_qualification &&
                        errorMsgs.education_qualification.errMsg}
                    </small>
                  )}
                </Form.Group>

                <Form.Group controlId="upload_resume">
                  <Label for="exampleCustomFileBrowser">Upload Resume</Label>
                  <CustomFileInput
                    accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf"
                    id="resume"
                    onChange={cvChangeHandler}
                  />

                  <h5>{resumePath && resumePath.split("\\").pop()}</h5>
                </Form.Group>

                <Form.Group controlId="voter_id">
                  <Label for="exampleCustomFileBrowser">
                    Upload Identity Proof
                  </Label>
                  <CustomFileInput
                    accept="application/pdf,image/png,image/jpeg"
                    id="voterId"
                    onChange={identityChangeHandler}
                  />

                  {/* {!(
                    errorMsgs &&
                    errorMsgs.upload_aadhaar &&
                    errorMsgs.upload_aadhaar.validation
                  ) && (
                    <small className='errText'>
                      {errorMsgs &&
                        errorMsgs.upload_aadhaar &&
                        errorMsgs.upload_aadhaar.errMsg}
                    </small>
                  )} */}
                  <h5>{idPath && idPath.split("\\").pop()}</h5>
                </Form.Group>
              </Col>
              {selectedContent && selectedContent.id === 2 && (
                <Col xs="12 " sm="12 pl-3" md="12 pl-3" lg={"12 pl-3"}>
                  <Form.Label>
                    {selectedContent && selectedContent.max}
                  </Form.Label>
                  <Form.Group controlId="language">
                    <Form.Control
                      type="text"
                      name="max"
                      onChange={onChangeHandler}
                      className={inputClass}
                      // value={enteredValue}
                      placeholder={selectedContent && selectedContent.max}
                    />
                    {inputClass === "error" && (
                      <small className="errText">
                        This Field Can't be Empty
                      </small>
                    )}
                  </Form.Group>
                </Col>
              )}
              <Row
                style={
                  selectedContent && selectedContent.id === 2
                    ? {
                        left: "50%",
                        transform: "translateX(-50%)",
                      }
                    : null
                }
                xs="12 d-flex justify-content-center pb-3 w-100"
                sm="3"
                md="3"
                // lg={
                //   selectedContent && selectedContent.id === 2
                //     ? "3 pr-3"
                //     : "3 pr-3"
                // }
                lg="6"
              >
                <Button
                  name="ADD"
                  className="ripple"
                  onClick={actionHandler}
                  style={{
                    background:
                      "linear-gradient(45deg, #7c4dff 30%, #b388ff  90%)",
                    color: "white",
                    height: "40px",
                    width: "100%",
                    minWidth: "70px",
                    marginLeft: "auto",
                  }}
                  // className={classes.root}
                >
                  {workFlow === "EDIT" ? "Update" : "Add"}
                </Button>
              </Row>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default AddCandidate;
