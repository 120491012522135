
import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import DashBoard from "pages/dashboard/DashBoard"

export const _navEmp = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fas fa-chart-line",
    layout: "/admin"
  },

  {
    path: "/job",
    icon: "fas fa-building",
    name: "Jobs",
    layout: "/admin"
  },
  {
    path: "/appliedJobList",
    icon: "fas fa-copy",
    name: "Applied Job List",
    layout: '/admin'
  },

]

export const _navSa = [

  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "fas fa-chart-line",
    layout: "/admin"
  },
  {
    path: "/candidateMaster",
    name: "Candidates",
    icon: "fas fa-users",
    layout: "/admin"
  },
  // {
  //   path:"",
  //   name:"User Permissions",
  //   icon:"fas fa-key"
  // },
  {
    path: "/employers",
    name: "Employers",
    icon: "fas fa-user-tie",
    layout: "/admin",
  },
  // {
  //   path:"",
  //   name:"Employer Companies",
  //   icon:"fas fa-globe-asia",
  // },
  // {
  //   path: "",
  //   icon:"fas fa-user-friends",
  //   name: "Candidates"
  // },
  {
    path: "/job",
    icon: "fas fa-building",
    // name: "Jobs",
    name: "Jobs",
    layout: "/admin"
  },
  {
    path: "/appliedJobList",
    icon: "fas fa-copy",
    name: "Applied Job List",
    layout: '/admin'
  },
  // {
  //   path: "",
  //   icon:"fas fa-bell",
  //   name:"Notifications"
  // },
  // {
  //   path: "/languageMaster",
  //   icon:"fas fa-language",
  //   name: "Language",
  //   layout:"/admin"
  // },
  {
    path: "/master",
    name: "Master",
    icon: "fas fa-columns text-primary",
    // component: DashBoard,
    layout: "/admin",
    childrens: [
      {
        path: "/qualificationMaster",
        icon: "fas fa-user-shield",
        name: "Qualification",
        layout: "/admin"
      },

      {
        path: "/jobTypeMaster",
        icon: "fas fa-briefcase",
        name: "Job Type",
        layout: "/admin"
      },
      {
        path: "/jobExperienceMaster",
        icon: "fas fa-paper-plane",
        name: "Job Experience",
        layout: "/admin"
      },
      {
        path: "/jobCategoryMaster",
        icon: "fas fa-scroll",
        name: "Job Category",
        layout: '/admin'
      },
      {
        path: "/skillsMaster",
        icon: "fas fa-network-wired",
        name: "Skills",
        layout: "/admin"
      },
      {
        path: "/assistanceNumberMaster",
        icon: "fas fa-headset",
        name: "Assistance Number",
        layout: "/admin"
      },
    ]
  },

];

