
import React from "react";
import { useLocation, Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";

import routes from "routes.js";
import { Hidden } from "@material-ui/core";

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    // document.body.classList.add("bg-default");
    // return () => {
    //   document.body.classList.remove("bg-default");
    // };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content"
        style={{
          overflowY: "hidden",
          backgroundColor: ""
        }}
        ref={mainContent}>

<svg 
style={{
  position:"absolute"
}}
xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080">
  <defs>
    <clipPath id="clip-KamKaj">
      <rect width="1920" height="1080"/>
    </clipPath>
  </defs>
  <g id="KamKaj" clip-path="url(#clip-KamKaj)">
    <rect width="1920" height="1080" fill="rgba(255,255,255,0)"/>
    <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(-97 -1169)" fill="#7e57c2" stroke="#707070" stroke-width="1">
      <ellipse cx="1055.5" cy="826" rx="1055.5" ry="826" stroke="none"/>
      <ellipse cx="1055.5" cy="826" rx="1055" ry="825.5" fill="none"/>
    </g>
  </g>
</svg>

        {/* <svg style={{
          position: "absolute"
        }} xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" viewBox="0 0 1920 1000">
          <defs>
            <clipPath id="clip-KamKaj">
              <rect width="1920" height="1080" />
            </clipPath>
          </defs>
          <g id="KamKaj" clip-path="url(#clip-KamKaj)">
            <rect width="1920" height="1080" fill="rgba(255,255,255,0)" />
            <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(-60 -1170)" fill="#7e57c2" stroke="#707070" stroke-width="1">
              <ellipse cx="1020" cy="878" rx="1020" ry="878" stroke="none" />
              <ellipse cx="1020" cy="878" rx="1019.5" ry="877.5" fill="none" />
            </g>
          </g>
        </svg> */}

        {/* <svg
          style={{
            position: "absolute",
          }}
          xmlns="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/1999/xlink" viewBox="0 0 1920 1080">
          <defs>
            <linearGradient id="linear-gradient" x1="0.5" x2="0.347" y2="1.06" gradientUnits="objectBoundingBox">
              <stop offset="0" stop-color="#7e57c2" />
              <stop offset="0.582" stop-color="#8d5edd" />
              <stop offset="1" stop-color="#3f2c61" />
            </linearGradient>
            <clipPath id="clip-KamKaj2">
              <rect width="1920" height="1080" />
            </clipPath>
          </defs>
          <g id="KamKaj2" clip-path="url(#clip-KamKaj2)">
            <rect width="1920" height="1080" fill="rgba(255,255,255,0)" />
            <g id="Ellipse_1" data-name="Ellipse 1" transform="translate(-133 -1296)" stroke="#707070" stroke-width="1" fill="url(#linear-gradient)">
              <ellipse cx="1093" cy="882.5" rx="1093" ry="882.5" stroke="none" />
              <ellipse cx="1093" cy="882.5" rx="1092.5" ry="882" fill="none" />
            </g>
          </g>
        </svg> */}


        <AuthNavbar />
        <div className="header " style={{ height: "64px" }}>
        </div>
        {/* Page content */}
        <Container className="mt-6 pb-5">
          <Row className="justify-content-center">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/auth/login" />
            </Switch>
          </Row>
        </Container>
      </div>
      {/* <AuthFooter /> */}
    </>
  );
};

export default Auth;
