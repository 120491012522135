import React, { useState,useEffect } from 'react';
import EmployerListPage from './EmployerListPage/EmployerListPage';
import {
	Card,
	CardBody,
	CardHeader,
	Col, Row,
	Badge,
	DropdownMenu,
	DropdownItem,
	UncontrolledDropdown,
	DropdownToggle,
	Media,
	Progress,
	Table,
	Button,
	UncontrolledTooltip
} from 'reactstrap';
import KK_Toast from 'components/Toast/KK_Toast';
import { useHistory } from 'react-router';
import AddEmployer from './add_employers/AddEmployer';
import { connect } from 'react-redux';



const Employer = () => {

	const [toastConfig, setToastConfig] = useState({
		toastStatus: false,
		message: "",
		severity: null,
	})
	let [address,setAddress] = useState([])
	const [tab,setTab] = useState("LIST")
	const [workFlow,setWorkFlow] = useState("ADD")
	const [employerId,setEmployerId] = useState(null)
	let history = useHistory()
	
	useEffect(()=>{ 
		console.log(address)
	},[address])

	return (
		<div className="pr-2 pl-2 container-fluid">
				
				<Row className="mt-7 text-center w3-animate-top">
					<Col></Col>
					<Col xs="12" lg="4">
						<KK_Toast setToastConfig={setToastConfig} toastConfig={toastConfig} />
						<h2 className="listbrand">{
							tab==="LIST"?"EMPLOYER LIST":workFlow==="EDIT"?"EDIT EMPLOYER":"ADD EMPLOYER"
						}</h2>
					</Col>
					<Col xs="12 mt-3" lg="4" className="d-flex justify-content-end">
						<Button className="ripple" style={{ backgroundColor: "transparent", color: "white" }}
							onClick={() => {
								tab==="LIST"?setTab("ADD"):setTab("LIST");
								if(tab==="ADD"){
									setAddress([])
									setWorkFlow("ADD")
								}
							
							}}
						>
							<i className="fas fa-user-plus mr-2" style={{
								color: "white"
							}}></i>
							{
							tab==="LIST"?"Add Employer":"Employer List"
						}
            </Button>
					</Col>
				</Row>
			<div 
			className="mt-2" 
			style={{
				// backgroundColor:"#b388ff",
				// backgroundColor:"white",
				height: "100%",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
				flexDirection: "column",

			}}>
				{
					tab==="LIST"?
					<EmployerListPage address={address} setEmployerId={setEmployerId} setToastConfig={setToastConfig} setWorkFlow={setWorkFlow} setTab={setTab}/>
					:
					<AddEmployer 
					setAddress={setAddress} 
					address={address} 
					// setMapVisibility={setMapVisibility} 
					employerId={employerId} 
					setWorkFlow={setWorkFlow} 
					setToastConfig={setToastConfig}
					 setTab={setTab} 
					 workFlow={workFlow}
					 />
				}
			</div>
		</div>
	);
};


 
export default Employer;