import loginActionTypes from "./login.action.types"


let INITIAL_STATE={
    loading:false,
    isLoggedIn:false,
    data:[],
    error:null
}


export const loginReducer = (state=INITIAL_STATE,action) =>{
        switch(action.type){
            case loginActionTypes.LOGIN_USER_REQUEST:
                return{
                    ...state,
                    loading:true,
                }
            
            case loginActionTypes.LOGIN_USER_SUCCESS:{
                return{
                    ...state,
                    loading:false,
                    data:action.payload,
                    isLoggedIn:true,
                    error:null
                }
            }
    
            case loginActionTypes.LOGIN_USER_FAILURE:
                return{
                    ...state,
                    loading: false,
                    data:null,
                    isLoggedIn:false,
                    error:action.payload
                }
            
            case loginActionTypes.LOGOUT:{
                return INITIAL_STATE
            }
                
            default :
            return state;
        }
}