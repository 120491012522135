const { default: PaginationActionType } = require("./pagination.action.type")


const INITIAL_STATE = {
    jobPageNo:0,
    empPageNo:0,
    masterPageNo:0
}


const paginationReducer =(state=INITIAL_STATE,action)=>{
    switch(action.type){
        case PaginationActionType.INCREMENT_PAGE_NO:{
            return{
                ...state,
                [action.payload]: state[action.payload] + 1
            }
        }
        case PaginationActionType.DECREMENT_PAGE_NO:{
            return{
                ...state,
                [action.payload]: state[action.payload] - 1
            }
        }
        default:{
            return state
        }
    }
}

export default paginationReducer