/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import Form from "react-bootstrap/Form";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Badge,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Progress,
  Table,
  Button,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory, useParams } from "react-router-dom";
import { content } from "../../components/_helpers/Content";
import apis from "apis";
import KK_Toast from "components/Toast/KK_Toast";
import KK_Table from "components/table/KK_Table";
import axios from "axios";
import { connect, useDispatch, useSelector } from "react-redux";

import paginationReducer from "redux/pagination/pagination.reducer";

import { decrementPageNo } from "redux/pagination/pagination.action";
import Pagination from "components/pagination/Pagination";
import { incrementPageNo } from "redux/pagination/pagination.action";
import { logout } from "redux/login/login.action";
import SelectComponent from "components/customSelect/Select.Component";
import Pagination2 from "components/pagination/Pagination2";
import { validation } from "components/_helpers/validation";

const useStyles = makeStyles({
  root: {
    background: "linear-gradient(45deg, #7c4dff 30%, #b388ff  90%)",
    border: 0,
    borderRadius: 10,
    boxShadow: "0 3px 5px 2px #b488ff83",
    color: "white",
    height: 38,
    width: "100%",
    padding: "0 20px",
  },
  disabled: {
    // background: 'linear-gradient(45deg,#b39ddb 30%, #d1c4e9 90%)',
    backgroundColor: "lightgrey",
    border: 0,
    borderRadius: 10,
    // boxShadow: '0 3px 5px 2px grey',
    color: "white",
    height: 38,
    padding: "0 20px",
  },
});

const Master = ({ auth_token, newPage, oldPage, pageNo, logout }) => {
  const [nextPageStatus, setNextPageStatus] = useState(false);
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const pathName = history.location.pathname;
  const [inputClass, setInputClass] = useState("normal");
  const [industryList, setIndustryList] = useState([]);
  const [selectedIndustry, setSelectedIndustry] = useState(null);
  const [selectedContent, setSelectedContent] = useState();
  const [tableData, setTableData] = useState([]);
  const [enteredValue, setEnteredValue] = useState("");
  const [operableData, setOperableData] = useState("");
  const [toastConfig, setToastConfig] = useState({
    toastStatus: false,
    message: "",
    severity: null,
  });
  const [workFlow, setWorkFlow] = useState("ADD");
  const [enterMax, setMax] = useState();
  const [min, setMin] = useState();
  const [loader, setLoader] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageOffset, setPageOffset] = useState({
    start: 0,
    end: 9,
  });
  const recordsPerPage = 10;
  const [totalPages, setTotalPages] = useState(0);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };
  const mainForm = useRef();

  // Generate <th> dynamically
  const generateThs = () => {
    let res = tableData
      .filter((data, idx) => idx < 1)
      .map((data) => {
        let values = Object.getOwnPropertyNames(data);
        return values.map((item) => {
          return (
            <th scope="col">
              {item === "Industry_Type" ? "Job Category" : item}
            </th>
          );
        });
      });
    return res;
  };
  console.log("name===>", tableData);

  useEffect(() => {
    console.log("operableData", operableData);
  }, [operableData]);

  const onChangeIndustry = (e) => {
    setSelectedIndustry({
      [e.target.name]: e.target.value,
    });
  };

  const onChangeNext = () => {
    setPageOffset({
      start: pageOffset.start + 10,
      end: pageOffset.end + 10,
    });
    console.log("Length", totalPages);

    setPageNumber(pageNumber + 1);
  };

  useEffect(() => {
    console.log("PAGENUMBER", pageNumber);
    console.log("Total Pages", totalPages);
  }, [pageNumber]);

  const onChangePrev = () => {
    setPageOffset({
      start: pageOffset.start - 10,
      end: pageOffset.end - 10,
    });

    setPageNumber(pageNumber - 1);
  };
  // Educational Qualification
  const generateTds = () => {
    //    let tdList=[]
    //    let res= []
    let res =
      tableData.length > 0 &&
      tableData
        .filter((i, idx) => idx >= pageOffset.start && idx <= pageOffset.end)
        .map((data) => {
          let values = Object.values(data);
          return (
            <tr>
              {values.map((item) => {
                return (
                  <>
                    <td scope="col">{item}</td>
                  </>
                );
              })}
              <td className="text-left">
                <span
                  style={{
                    marginRight: "15px",
                    cursor: "pointer",
                    fontSize: "14px",
                    color: "#6c63ff",
                  }}
                  name="EDIT"
                  onClick={(e) => {
                    setOperableData(data);
                    actionHandler(e);
                    setWorkFlow("EDIT");
                  }}
                >
                  {" "}
                  <i className="far fa-edit" aria-hidden="true"></i>
                </span>
                <span
                  style={{
                    cursor: "pointer",
                    fontSize: "14px",
                    color: "#f03131",
                  }}
                  name="DELETE"
                  onClick={(e) => {
                    setOperableData(data);
                    setEnteredValue(null);
                    setWorkFlow("DELETE");
                    setEnteredValue("");
                  }}
                >
                  {" "}
                  <i className="fas fa-trash" aria-hidden="true"></i>
                </span>
                {/* <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                // href="#pablo"
                role="button"
                size="sm"
                color=""
                onClick={(e) => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem
                  name="EDIT"
                  onClick={(e) => {
                    setOperableData(data);
                    actionHandler(e);
                  }}
                >
                  Edit
                </DropdownItem>
                <DropdownItem
                  name="DELETE"
                  onClick={(e) => {
                    setOperableData(data);
                    setEnteredValue(null);
                    setWorkFlow("DELETE");
                  }}
                >
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
              </td>
            </tr>
          );
        });
    return res;
  };

  const generateTdsV2 = () => {
    //    let tdList=[]
    //    let res= []
    let res =
      tableData.length > 0 &&
      tableData
        .filter((i, idx) => idx >= pageOffset.start && idx <= pageOffset.end)
        .map((data) => {
          let values = Object.values(data);
          return (
            <tr>
              {values.map((item) => {
                return (
                  <>
                    <td scope="col">{item}</td>
                  </>
                );
              })}

              <td className="text-left">
                <span
                  style={{
                    marginRight: "15px",
                    cursor: "pointer",
                    fontSize: "14px",
                    color: "#6c63ff",
                  }}
                  name="EDIT"
                  onClick={(e) => {
                    setOperableData(data);
                    if (selectedContent.id === 5) {
                      console.log("LOGGERRRR", data.id);
                      console.log("Industry list", industryList);

                      let selectedIndustryId = industryList.find(
                        ({ name }) => name === data.Industry_Type
                      );
                      console.log("filtered record", selectedIndustryId);
                      setSelectedIndustry({
                        industry_type_id: selectedIndustryId.id,
                      });
                    }
                    actionHandler(e);
                    setWorkFlow("EDIT");
                  }}
                  // onClick={(e) => {
                  //   setOperableData(data);
                  //   actionHandler(e);
                  //   setWorkFlow("EDIT");
                  // }}
                >
                  {" "}
                  <i className="far fa-edit" aria-hidden="true"></i>
                </span>
                <span
                  style={{
                    cursor: "pointer",
                    fontSize: "14px",
                    color: "#f03131",
                  }}
                  name="DELETE"
                  onClick={(e) => {
                    setOperableData(data);
                    setEnteredValue(null);
                    setWorkFlow("DELETE");
                  }}
                  // onClick={(e) => {
                  //   setOperableData(data);
                  //   setEnteredValue(null);
                  //   setWorkFlow("DELETE");
                  //   setEnteredValue("");
                  // }}
                >
                  {" "}
                  <i className="fas fa-trash" aria-hidden="true"></i>
                </span>
                {/* <UncontrolledDropdown>
              <DropdownToggle
                className="btn-icon-only text-light"
                // href="#pablo"
                role="button"
                size="sm"
                color=""
                onClick={(e) => e.preventDefault()}
              >
                <i className="fas fa-ellipsis-v" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem
                  name="EDIT"
                  onClick={(e) => {
                    setOperableData(data);
                    actionHandler(e);
                  }}
                >
                  Edit
                </DropdownItem>
                <DropdownItem
                  name="DELETE"
                  onClick={(e) => {
                    setOperableData(data);
                    setEnteredValue(null);
                    setWorkFlow("DELETE");
                  }}
                >
                  Delete
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown> */}
              </td>

              {/* <td className="text-right">
                <UncontrolledDropdown>
                  <DropdownToggle
                    className="btn-icon-only text-light"
                    // href="#pablo"
                    role="button"
                    size="sm"
                    color=""
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fas fa-ellipsis-v" />
                  </DropdownToggle>
                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem
                      name="EDIT"
                      onClick={(e) => {
                        setOperableData(data);
                        if (selectedContent.id === 5) {
                          console.log("LOGGERRRR", data.id);
                          console.log("Industry list", industryList);

                          let selectedIndustryId = industryList.find(
                            ({ name }) => name === data.Industry_Type
                          );
                          console.log("filtered record", selectedIndustryId);
                          setSelectedIndustry({
                            industry_type_id: selectedIndustryId.id,
                          });
                        }
                        actionHandler(e);
                        setWorkFlow("EDIT");
                      }}
                    >
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      name="DELETE"
                      onClick={(e) => {
                        setOperableData(data);
                        setEnteredValue(null);
                        setWorkFlow("DELETE");
                      }}
                    >
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </td> */}
            </tr>
          );
        });
    return res;
  };

  // component did mount

  useEffect(async () => {
    setLoader(true);
    let filteredContent = content.find((item) => {
      return item.path === pathName;
    });

    if (filteredContent.id === 5) {
      try {
        let industryList = await apis.masters.getAllRecords(
          auth_token,
          "/api/v1/master/jobCategory"
        );
        console.log("Industry List", industryList);
        setIndustryList(industryList.data);
      } catch (err) {
        console.log(err);
      }
    }

    setSelectedContent(filteredContent);
    console.log("filteredContent========>", filteredContent);
    try {
      let res = await apis.masters.getAllRecords(
        auth_token,
        filteredContent.reqpath
      );
      if (res.data.length > 0) {
        setLoader(false);
        setNextPageStatus(true);
      } else {
        setLoader(false);
        setNextPageStatus(false);
      }
    } catch (err) {
      setLoader(false);
      if (err.response && err.response.status === 401) {
        setToastConfig({
          toastStatus: true,
          message: "Session Expired!",
          severity: "error",
        });
        setTimeout(() => {
          logout();
        }, 2000);
      }
      console.error(err.response);
    }
    //api call to populate the table as per the req in content object
    getAllRecords(filteredContent.reqpath);
  }, []);

  const getAllRecords = async (path) => {
    setLoader(true);
    console.log(path);
    try {
      let res = await apis.masters.getAllRecords(
        auth_token,
        path // + `${pageNo === 0 ? "" : "/" + pageNo}`
      );
      console.log("Real Response", res);
      if (res.success === true) {
        setLoader(false);
        let remainder = res.data.length % 10;
        let limit = parseInt(res.data.length / 10);
        if (remainder !== 0) {
          limit = limit + 1;
        }

        setTotalPages(limit);
        setTableData(res.data);
      }
    } catch (err) {
      setLoader(false);
      if (err.response && err.response.status === 401) {
        setToastConfig({
          toastStatus: true,
          message: "Session Expired!",
          severity: "error",
        });
        setTimeout(() => {
          logout();
        }, 2000);
      }
      console.error(err);
    }
  };

  // const createNewRecord = (path) => {
  // 	apis.masters.createNewRecord(path).then(res => {
  // 		console.log(res)
  // 	}).catch(err => {
  // 		console.log(err)
  // 	})
  // }

  useEffect(() => {
    console.log("CLASS", inputClass);
  }, [inputClass]);

  useEffect(() => {
    console.log("tableData", tableData);
  }, [tableData]);

  const onChangeHandler = (e) => {
    setInputClass("normal");
    if (e.target.name === "max") {
      setMax(e.target.value);
    } else {
      setEnteredValue(e.target.value);
    }
  };

  useEffect(() => {
    console.log(enteredValue);
  }, [enteredValue]);

  useEffect(async () => {
    if (workFlow === "DELETE") {
      let body = {
        name: operableData.name,
      };
      console.log("Body", body);
      // scrollToTop()
      try {
        let res = await apis.masters.deleteRecordRequest(
          auth_token,
          selectedContent && selectedContent.reqpath,
          operableData.id,
          body
        );
        if (res.success === true) {
          console.log(res);
          setToastConfig({
            toastStatus: true,
            message: res.message,
            severity: "success",
          });
          setPageNumber(1);
          setPageOffset({
            start: 0,
            end: recordsPerPage - 1,
          });
          let remainder = tableData.length % recordsPerPage;
          let limit = parseInt(tableData.length / recordsPerPage);
          if (remainder !== 0) {
            limit = limit + 1;
          }
          setTotalPages(limit);
          setWorkFlow("ADD");
          return getAllRecords(selectedContent && selectedContent.reqpath);
        } else {
          setToastConfig({
            toastStatus: true,
            message: `You cannot delete the applied ${selectedContent?.header}`,
            severity: "error",
          });
          setWorkFlow("ADD");
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          setToastConfig({
            toastStatus: true,
            message: "Session Expired!",
            severity: "error",
          });
          setTimeout(() => {
            logout();
          }, 2000);
        }
        setToastConfig({
          toastStatus: true,
          message: err.message,
          severity: "error",
        });
        setWorkFlow("ADD");
        return console.error(err);
      }
    }
    console.log("workFlow", workFlow);
  }, [workFlow]);

  useEffect(() => {
    console.log("si", selectedIndustry);
  }, [selectedIndustry]);

  const actionHandler = async (e) => {
    let enterKeyVal="ADD"
    e.preventDefault()
    let min_val = parseInt(enteredValue);
    let zero_parseint = parseInt("0", 0);
    console.log("parseint", typeof zero_parseint);
    let max_val = parseInt(enterMax);
    console.log("If condition", enteredValue >= enterMax);
    console.log("enteredValue",  enteredValue);
    console.log("enterMax ", enterMax);
    console.log("min_val >= 0", min_val >= 0);
    if (selectedContent && selectedContent.id === 2) {
      // if (min_val.length === 0){
      //    return setToastConfig ({
      //      toastStatus: true,
      //      message: " Min experience required",
      //      severity: "error",
      //    })
      // }
      // let resmin_value = validation.checkForEmpty(min_val,"Min Experience ")
      // let resmax_value = validation.checkForEmpty(max_val,"Max Experience ")
      // if(resmin_value.validation === false){
      //   return setToastConfig({
      //         toastStatus: true,
      //         message: resmin_value.errMsg,
      //         severity: "error",
      //       });
      // }
      // if(resmax_value.validation === false){
      //   return setToastConfig({
      //         toastStatus: true,
      //         message: resmax_value.errMsg,
      //         severity: "error",
      //       });
      // }
       if (isNaN(min_val)) {
        return setToastConfig({
          toastStatus: true,
          message: "Min experience required and only numbers accept",
          severity: "error",
        });
      } 
      else
       if (min_val <= 0) {
        return setToastConfig({
          toastStatus: true,
          message: "Minimum experience should greater than 0",
          severity: "error",
        });
      }
       else if (isNaN(max_val)) {
        return setToastConfig({
          toastStatus: true,
          message: "Max experience required and only numbers accept",
          severity: "error",
        });
      } 
      else if (max_val <= 0 && zero_parseint) {
        console.log("minimum 0 value");
        return setToastConfig({
          toastStatus: true,
          message: "minimum experience should greater than 0",
          severity: "error",
        });
      }
      else if(min_val>= 100 || max_val >= 100){
        return setToastConfig({
          toastStatus: true,
          message: "Up to 2 digits are allowed",
          severity: "error",
        });
      }
       else if (min_val > max_val) {
        return setToastConfig({
          toastStatus: true,
          message:
            "min experience can't be more than or equal to max experience",
          severity: "error",
        });
      } else if (max_val <= min_val) {
        return setToastConfig({
          toastStatus: true,
          message: " max experience can't be less than minimum experience",
          severity: "error",
        });
      }
    }
    console.log("selectedContent.id", selectedContent.id);
    console.log("Target - ",e.target.name,);
    switch ( e.target.name) {
      case "ADD": {
        if (enteredValue === undefined || enteredValue.length === 0) {
          return setInputClass("error");
        } else {
          // Adding this if statement for validating Educational Qualification field
          if (selectedContent.id === 9) {
            let res = validation.onlyLettersWithSpaceCheck(
              enteredValue,
              "enteredValue"
            );
            let res1 = validation.checkMaxLength(enteredValue);
            console.log(res1);
            if (res1.validation === false) {
              return setToastConfig({
                toastStatus: true,
                message: res1.errMsg,
                severity: "error",
              });
            }
            console.log("validation here", res);
            if (res.validation === false) {
              // return the error for stop execution of if statement when error occured
              return setToastConfig({
                toastStatus: true,
                message: res.errMsg,
                severity: "error",
              });
            }
          }

          // if else for add job type and skills
          if (selectedContent.id === 3 && selectedContent.id === 5) {
            let res = validation.checkLettersWithSymbol(
              enteredValue,
              "enteredValue"
            );

            console.log("onlyLettersWithSpaceCheck validaiton here", res);
            if (res.validation === false) {
              return setToastConfig({
                toastStatus: true,
                message: res.errMsg,
                severity: "error",
              });
            }
          }

          // for add skills
          if (selectedContent.id === 5) {
            let res1 = validation.checkMaxLength(enteredValue);
            console.log("Check MaxLength Response : ", res1);

            if (res1.validation === false) {
              return setToastConfig({
                toastStatus: true,
                message: res1.errMsg,
                severity: "error",
              });
            }
            let res = validation.checkForSkills(enteredValue, "enteredValue");
            console.log("Enter Skill : ", res);
            if (res.validation === false) {
              return setToastConfig({
                toastStatus: true,
                message: res.errMsg,
                severity: "error",
              });
            }
          }

          // if else for add job category
          if (selectedContent.id === 4) {
            let res = validation.checkLettersSymbolsUnicode(
              enteredValue,
              "enteredValue"
            );
            let res1 = validation.checkMaxLength(enteredValue);

            console.log("onlyLettersWithSpaceCheck validaiton here", res);
            console.log("Max Length Checking", res1);

            if (res1.validation === false) {
              return setToastConfig({
                toastStatus: true,
                message: res1.errMsg,
                severity: "error",
              });
            }

            if (res.validation === false) {
              return setToastConfig({
                toastStatus: true,
                message: res.errMsg,
                severity: "error",
              });
            }
          }

          // if else for add job type
          if (selectedContent.id === 3) {
            let res = validation.checkLettersSymbolsUnicode(enteredValue);
            console.log(
              "job type: checkLettersSymbolsUnicode validaiton here",
              res
            );
            let res1 = validation.checkMaxLength(enteredValue);
            console.log(res1);
            if (res1.validation === false) {
              return setToastConfig({
                toastStatus: true,
                message: res1.errMsg,
                severity: "error",
              });
            }
            if (res.validation === false) {
              return setToastConfig({
                toastStatus: true,
                message: res.errMsg,
                severity: "error",
              });
            }
          }

          let data = null;
          if (selectedContent && selectedContent.id === 2) {
            console.log("Enter Value - ", enteredValue);
            console.log("Enter Max - ", enterMax);
            data = {
              min_exp: parseInt(enteredValue),
              max_exp: parseInt(enterMax),
            };
          } else {
            data = {
              name: enteredValue.trim(),
            };
          }

          if (selectedContent.id === 5 && selectedIndustry) {
            data.industry_type_id = selectedIndustry.industry_type_id;
          }

          console.log(data, "DATA");
          // scrollToTop()
          if (workFlow === "EDIT") {
            try {
              let res = await apis.masters.updateRecordRequest(
                auth_token,
                selectedContent && selectedContent.reqpath,
                operableData.id,
                data
              );
              console.log("RISHABH RES", data);
              if (res.success === true) {
                console.log("Update res", res);
                setToastConfig({
                  toastStatus: true,
                  message: res.message,
                  severity: "success",
                });
                setWorkFlow("ADD");
                setMax("");
                setEnteredValue("");
                setOperableData("");

                return getAllRecords(
                  selectedContent && selectedContent.reqpath
                );
              } else {
                if (res.data.code && res.data.code === "ER_DUP_ENTRY") {
                  setToastConfig({
                    toastStatus: true,
                    message: "Provided data Already Exists",
                    severity: "error",
                  });
                }

                // setToastConfig({
                //   toastStatus: true,
                //   message: res.message,
                //   severity: "error",
                // });
              }
            } catch (err) {
              if (err.response && err.response.status === 401) {
                setToastConfig({
                  toastStatus: true,
                  message: "Session Expired!",
                  severity: "error",
                });
                setTimeout(() => {
                  logout();
                }, 2000);
              }
              setToastConfig({
                toastStatus: true,
                message: err.message,
                severity: "error",
              });
              console.error(err);
            }
          } else {
            try {
              let res = await apis.masters.createRecordRequest(
                auth_token,
                selectedContent && selectedContent.reqpath,
                data
              );
              console.log("RISHABH RES", res);

              if (res.success === false) {
                if (res.data.code && res.data.code === "ER_DUP_ENTRY") {
                  return setToastConfig({
                    toastStatus: true,
                    message: "Provided Data Already Exists",
                    severity: "error",
                  });
                }
                return setToastConfig({
                  toastStatus: true,
                  message: res.message,
                  severity: "success",
                });
              }
              if (res.success === true) {
                mainForm.current.reset();
                console.log("ADD res", res);
                setToastConfig({
                  toastStatus: true,
                  message: res.message,
                  severity: "success",
                });
                setMax("");
                setEnteredValue("");
                setOperableData("");
                setSelectedIndustry("");
                return getAllRecords(
                  selectedContent && selectedContent.reqpath
                );
              }
            } catch (err) {
              console.log("Rishabh Error log", err.response);
              if (err.response && err.response.status === 401) {
                setToastConfig({
                  toastStatus: true,
                  message: "Session Expired!",
                  severity: "error",
                });
                setTimeout(() => {
                  logout();
                }, 2000);
              } else {
                if (err.response && err.response.data.errors) {
                  err.response.data.errors.map(({ msg = null }) =>
                    setToastConfig({
                      toastStatus: true,
                      message: msg || "wrong parameters passed",
                      severity: "error",
                    })
                  );
                } else if (
                  (err.response && err.response.status === 400) ||
                  (err.response && err.response.status === 422)
                ) {
                  setToastConfig({
                    toastStatus: true,
                    message: "wrong parameters passed",
                    severity: "error",
                  });
                } else {
                  setToastConfig({
                    toastStatus: true,
                    message: "Network Error",
                    severity: "error",
                  });
                }
              }
              console.error(err);
            }
          }
        }

        break;
      }
      case "EDIT": {
        return setWorkFlow("EDIT");
      }
    }
  };

  const actionArray = [actionHandler, setOperableData];

  const handleEnterkey = (e)=>{
    console.log(e);
    if (e.key === "Enter") {
      // document.getElementsByClassName("ripple").click()
      e.target.setAttribute("name","ADD")
      console.log(workFlow);
      actionHandler(e);
    }
  }

  useEffect(() => {
    if (workFlow === "DELETE") {
      setEnteredValue("");
    } else {
      if (selectedContent && selectedContent.id === 2) {
        setEnteredValue(operableData.min_exp);
        return setMax(operableData.max_exp);
      }
      setEnteredValue(operableData.name);
    }
  }, [operableData]);

  useEffect(() => {
    getAllRecords(selectedContent && selectedContent.reqpath);
  }, [pageNo]);

  useEffect(() => {
    console.log("PAGE OFFSET", pageOffset);
    console.log("Data Length", tableData.length);
    console.log("Table Data", tableData);
  }, [pageOffset]);

  return (
    <>
      <div
        className="container text-center mt-8 mb-4"
        style={{ position: "relative" }}
      >
        <h2 style={{ color: "white" }} className="w3-animate-top">
          {workFlow === "EDIT"
            ? `EDIT ${selectedContent && selectedContent.title}`
            : `ADD ${selectedContent && selectedContent.title}`}
        </h2>
        <KK_Toast setToastConfig={setToastConfig} toastConfig={toastConfig} />
      </div>

      <div className="d-flex justify-content-center flex-column align-items-center pr-5 pl-5">
        <Col lg="7  w3-animate-left">
          <Card style={{ width: "100%" }}>
            <CardHeader className="pl-4 pt-2 pb-0">
              <Form.Label>
                {workFlow === "EDIT"
                  ? `Edit ${selectedContent && selectedContent.header}`
                  : `Add ${selectedContent && selectedContent.header}`}
              </Form.Label>
            </CardHeader>
            <CardBody className="pt-3 pb-0 ">
              <form ref={mainForm} id="master-form" >
                <Row className="p-0">
                  <Col
                    xs="12 "
                    sm={
                      selectedContent &&
                      (selectedContent.id === 2 || selectedContent.id === 5)
                        ? "12 pl-3"
                        : "9 pl-3"
                    }
                    md={
                      selectedContent &&
                      (selectedContent.id === 2 || selectedContent.id === 5)
                        ? "12 pl-3"
                        : "9 pl-3"
                    }
                    lg={
                      selectedContent &&
                      (selectedContent.id === 2 || selectedContent.id === 5)
                        ? "12 pl-3"
                        : "9 pl-3"
                    }
                  >
                    {selectedContent && selectedContent.id === 2 && (
                      <Form.Label>
                        {selectedContent &&
                          selectedContent &&
                          selectedContent.min}
                      </Form.Label>
                    )}
                    <Form.Group controlId="language">
                      <Form.Control
                        type="text"
                        name="minn"
                        onChange={onChangeHandler}
                        className={inputClass}
                        min="0"
                        onKeyPress={handleEnterkey}
                        value={enteredValue}
                        placeholder={
                          selectedContent && selectedContent.id === 2
                            ? selectedContent && selectedContent.min
                            : selectedContent && selectedContent.placeholder
                        }
                      />
                      {inputClass === "error" && (
                        <small className="errText">
                          This field cannot be empty
                        </small>
                      )}
                    </Form.Group>
                  </Col>
                  {/* Job Experience second input field */}
                  {selectedContent && selectedContent.id === 2 && (
                    <Col xs="12 " sm="12 pl-3" md="12 pl-3" lg={"12 pl-3"}>
                      <Form.Label>
                        {selectedContent && selectedContent.max}
                      </Form.Label>
                      <Form.Group controlId="language">
                        <Form.Control
                          type="text"
                          name="max"
                          onChange={onChangeHandler}
                          onKeyPress={handleEnterkey}
                          className={inputClass}
                          value={enterMax}
                          placeholder={selectedContent && selectedContent.max}
                        />
                        {inputClass === "error" && (
                          <small className="errText">
                            This field cannot be empty
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                  )}

                  {/* MODIFICATION IN MASTER FOR SKILLS*/}
                  {selectedContent && selectedContent.id === 5 && (
                    <Col xs="12 " sm="12 pl-3" md="12 pl-3" lg={"12 pl-3"}>
                      <Form.Label>
                        {selectedContent && selectedContent.max}
                      </Form.Label>
                      <Form.Group controlId="language">
                        <Form.Control
                          autoComplete="none"
                          name="industry_type_id"
                          disabled={workFlow === "EDIT" ? true : false}
                          onChange={onChangeIndustry}
                          // onKeyPress={handleEnterkey}
                          style={{ width: "100%" }}
                          value={
                            selectedIndustry &&
                            selectedIndustry.industry_type_id
                          }
                          as="select"
                        >
                          <option value="">Select Job Category </option>
                          {industryList.map((i, idx) => {
                            return (
                              <option key={idx} value={i.id}>
                                {i.name}
                              </option>
                            );
                          })}
                        </Form.Control>

                        {inputClass === "error" && (
                          <small className="errText">
                            This field cannot be empty
                          </small>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                  {/* Job Experience and Skill Page div */}
                  <Col
                    style={
                      selectedContent && selectedContent.id === 2
                        ? {
                            left: "50%",
                            transform: "translateX(-50%)",
                          }
                        : selectedContent && selectedContent.id === 5
                        ? {
                            left: "37%",
                          }
                        : null
                    }
                    xs="12 d-flex justify-content-center pb-3 w-100"
                    sm="3"
                    md="3"
                    lg={
                      selectedContent && selectedContent.id === 2
                        ? "3 pr-3"
                        : "3 pr-3"
                    }
                  >
                    <Button
                      name="ADD"
                      className="ripple"
                      type="submit"
                      onClick={actionHandler}
                      style={{
                        background:
                          "linear-gradient(45deg, #7c4dff 30%, #b388ff  90%)",
                        color: "white",
                        height: "40px",
                        width: "100%",
                        minWidth: "100px",
                      }}
                      // className={classes.root}
                    >
                      {workFlow === "EDIT" ? "Update" : "Add"}
                    </Button>
                  </Col>
                </Row>
              </form>
            </CardBody>
          </Card>
        </Col>
        {/* loader */}
        {loader ? (
          <Spinner
            className="mt-4"
            style={{ width: "3rem", height: "3rem", color: "whitesmoke" }}
          />
        ) : (
          <>
            <Table
              className="align-items-center table-condensed table-striped mt-6 w3-animate-bottom"
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                width: "550px",
                backgroundColor: "white",
              }}
              responsive
            >
              <thead className="thead-light">
                {generateThs()}
                <th scope="col">Action</th>
              </thead>
              <tbody>
                {selectedContent && selectedContent.id === 9
                  ? generateTds()
                  : generateTdsV2()}
              </tbody>
            </Table>
            <Row className="pagination mt-2 mb-5 w3-animate-bottom  d-flex justify-content-center">
              <Pagination2
                pageNumber={pageNumber}
                pageOffset={pageOffset}
                length={totalPages}
                onChangeNext={onChangeNext}
                onChangePrev={onChangePrev}
              />
              {/* {selectedContent && selectedContent.id === 9 ? (
                <Pagination
                  nextPageStatus={nextPageStatus}
                  length={tableData && tableData.length}
                  nextPage={newPage}
                  name="masterPageNo"
                  getAllRecords={getAllRecords}
                  previousPage={oldPage}
                  pageNo={pageNo}
                />
              ) : (
                <Pagination2
                  pageNumber={pageNumber}
                  pageOffset={pageOffset}
                  length={totalPages}
                  onChangeNext={onChangeNext}
                  onChangePrev={onChangePrev}
                />
              )} */}
            </Row>
          </>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    pageNo: state.pagination.masterPageNo,
    auth_token: state.login.data.auth_token,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    newPage: (item) => {
      dispatch(incrementPageNo(item));
    },
    oldPage: (item) => {
      dispatch(decrementPageNo(item));
    },
    logout: () => {
      dispatch(logout());
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Master);
