export const content = [
	// {	id:1,
	// 	title:"JOB TYPE MASTER",
	// 	header:"Add Job Type",
	// 	placeholder:"Enter Job Type",
	// 	path:"/admin/jobTypeMaster",
	// 	reqpath:"",
	// 	th:"Job Type"
	// },
	{
		id: 2,
		title: "JOB EXPERIENCE",
		header: "Job Experience",
		placeholder: "Enter Job Experience",
		path: "/admin/jobExperienceMaster",
		min: "Minimum Experience (Years)",
		max: "Maximum Experience (Years)",
		reqpath: "/api/v1/master/jobExperience",
		th: [
			"Minimum",
			"Maximum"
		]

	},
	{
		id: 3,
		title: "JOB TYPE",
		header: "Job Type",
		placeholder: "Enter Job Type",
		path: "/admin/jobTypeMaster",
		reqpath: "/api/v1/master/jobTypes",
		th: ["Job Type"]
	},
	{
		id: 4,
		title: "JOB CATEGORY",
		header: "Job Category",
		placeholder: "Enter Job Category",
		path: "/admin/jobCategoryMaster",
		reqpath: "/api/v1/master/jobCategory",
		th: ["Job Category"]
	},
	{
		id: 5,
		title: "SKILLS",
		header: "Skills",
		placeholder: "Enter Skill",
		placeholder2:"Select Industry Type",
		path: "/admin/skillsMaster",
		reqpath: "/api/v1/master/jobSkill",
		th: ["Skills"]
	},
	{
		id: 6,
		title: "JOB STATUS",
		header: "Job Status",
		placeholder: "Enter Job Status",
		path: "/admin/jobStatus",
		reqpath: "/api/v1/master/jobStatus",
		th: ["Job Status"]
	},
	{
		id: 7,
		title: "Language",
		header: "Language",
		placeholder: "Enter Language",
		path: "/admin/languageMaster",
		reqpath: "",
		th: ["Languages"]
	},
	{
		id: 8,
		title: "Notification Type",
		header: "Notification Type",
		placeholder: "Enter Type Of Notification",
		path: "/admin/notificationTypeMaster",
		reqpath: "",
		th: ["Notification"]
	},
	{
		id: 9,
		title: "Educational Qualification",
		header: "Educational Qualification",
		placeholder: "Enter Qualification",
		path: "/admin/qualificationMaster",
		reqpath: "/api/v1/master/educationalQualification",
		th: ["Qualification"]
	},
	// {
	// 	id: 10,
	// 	title: "Assistance Number",
	// 	header: "Assistance Number",
	// 	placeholder: "Enter Assistance Number",
	// 	path: "/admin/assistanceNumber",
	// 	reqpath: "/api/v1/master/assistanceNumber",
	// 	th: ["Assistance Number"]
	// },
	
]
