
// import React, { useEffect, useState } from 'react';
// import CsvDownload from 'react-json-to-csv'
// import api from '../../../api';
// import { useSelector } from 'react-redux';

// export const ConvertToExcel = ({ dataToCovert, fileName }) => {

//     console.log(dataToCovert)
//     return (
//         <CsvDownload data={dataToCovert} fileName={fileName} style={{ heigth: '35px' }} />
//     );
// };



import React from 'react'
import csvDownload from 'json-to-csv-export'

export const ConvertToExcel = ({ dataToCovert, filename, btnName }) => {
    console.log("filename ", filename)

    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end'}}>
            <button onClick={() => csvDownload(dataToCovert, filename)}
                style={{ height: '35px', borderRadius: '10px', backgroundColor: '#3c4b64', borderColor: '#3c4b64', color: 'white' }}>
                {btnName ? btnName : "Download List"}
            </button>
        </div>
    )
}
// export default ConvertToExcel