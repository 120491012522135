import { map } from "jquery";
import React, { Component, useEffect, useState } from "react";
import { render } from "react-dom";
import { Button, Col, Form, Row, Modal, ModalBody } from "reactstrap";
import Geocode from "react-geocode";

const google = window.google;

const Map2 = ({
  options,
  mapPosition,
  id,
  setMapPosition,
  addressString,
  setcAddress,
  caddress,
  markerPosition,
  setMarkerPosition,
  setCurrentZoom,
}) => {
  const onMarkerDragEnd = (event) => {
    let newLat = Number(event.latLng.lat());
    let newLng = Number(event.latLng.lng());
    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        console.log(response);
        const newad = response.results[0].formatted_address;
        setcAddress({
          ...caddress,
          address_line2: newad,
        });
        setMarkerPosition({
          lat: Number(newLat),
          lng: Number(newLng),
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const onMapLoad = (map) => {
    const infowindow = new google.maps.InfoWindow({
      content: addressString || "hello",
    });
    var marker = new window.google.maps.Marker({
      position: {
        lat: Number(markerPosition.lat),
        lng: Number(markerPosition.lng),
      },
      // animation:google.maps.Animation.BOUNCE,
      map: map,
      title: "Hello Istanbul!",
      draggable: true,
    });
    marker.addListener("click", () => {
      infowindow.open(map, marker);
    });
    google.maps.event.addListener(marker, "dragend", onMarkerDragEnd);
    console.log("Map2=51=>", map);
  };

  const onScriptLoad = () => {
    const map = new window.google.maps.Map(
      document.getElementById(id),
      options
    );
    onMapLoad(map);
    google.maps.event.addListener(map, "zoom_changed", () => {
      let zoomLevel = map.getZoom();
      console.log("ZOOM LEVEL", zoomLevel);
      setCurrentZoom(zoomLevel);
    });
  };
  useEffect(() => {
    console.log("I WAS CALLED TO REINITIALIZE MAP");

    if (markerPosition.lat) {
      setMapPosition(markerPosition);
    }
  }, [markerPosition]);

  useEffect(() => {
    console.log("I WAS CALLED TO REINITIALIZE MAP");

    if (mapPosition.lat) {
      initMap();
    }
  }, [mapPosition]);

  const initMap = () => {
    if (!window.google) {
      var s = document.createElement("script");
      s.type = "text/javascript";
      s.src = `https://maps.google.com/maps/api/js?key=AIzaSyAWCm8fLrC0D4l4GBYggEzpFfEf7lDh1Is`;
      var x = document.getElementsByTagName("script")[0];
      x.parentNode.insertBefore(s, x);
      // Below is important.
      //We cannot access google.maps until it's finished loading
      s.addEventListener("load", (e) => {
        onScriptLoad();
      });
    } else {
      onScriptLoad();
    }
  };

  useEffect(() => {
    Geocode.setLocationType("ROOFTOP");
    Geocode.enableDebug();
    initMap();
  }, []);

  return (
    <div
      className="mb-2"
      onLoad={() => {
        google.maps.event.trigger(document.getElementById(id), "resize");
      }}
      style={{ width: "100%", height: "100%" }}
      id={id}
    />
  );
};

export default Map2;
