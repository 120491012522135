import React from 'react';
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { connect } from 'react-redux';

const App = ({data,isLoggedIn}) => {
    return (
        <>
            <HashRouter>
                <Switch>
                    {
                        isLoggedIn? <Route path="/admin" render={(props) => <AdminLayout {...props} />} />:<Route path="/auth" render={(props) => <AuthLayout {...props} />} />
                    }
                    {
                        isLoggedIn?<Redirect from="/" to="/admin/dashboard" /> :<Redirect from="/" to="/auth/" />
                    }
                    
                </Switch>
            </HashRouter>
        </>
    );
};

const mapStateToProps = state =>{
    return{
        data: state.login.data,
        isLoggedIn: state.login.isLoggedIn
    }
}


export default connect(mapStateToProps)(App);